import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { Observable, debounceTime, distinctUntilChanged, filter, fromEvent, map, mergeMap, of, switchMap, takeUntil, tap } from 'rxjs';
import { BusinessAccountModel } from 'src/app/shared/models/business-accounts/business-account.model';
import { BookingActions } from 'src/app/shared/store/booking/booking.actions';
import { BookingState } from 'src/app/shared/store/booking/booking.state';
import { BaseComponent } from '../../base/base.component';
import { RoleConstant } from 'src/app/shared/constants/role.constant';
import { BusinessAccountService } from '../../../providers/services';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { BusinessAccountVoucherModel } from '../../../models/business-accounts/business-account-voucher.model';

@Component({
  selector: 'business-additional-info-dialog',
  templateUrl: './business-additional-info-dialog.component.html',
  styleUrls: ['./business-additional-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BusinessAdditionalInfoDialogComponent extends BaseComponent implements AfterViewInit {
  formGroup!: FormGroup;
  @Select(BookingState.getBusinessAccounts) businessAccounts$!: Observable<BusinessAccountModel[] | null>
  @Select(BookingState.getBusinessCompanyNotes) businessCompanyNotes$!: Observable<string | null>
  @Select(BookingState.isVoucherExist) isVoucherExist$!: Observable<boolean>
  @ViewChild('businessAccountInput') businessAccountInput!: ElementRef<HTMLInputElement>;
  @ViewChild('voucherInput') voucherInput!: ElementRef<HTMLInputElement>;
  @SelectSnapshot(BookingState.getVoucher) voucher!: BusinessAccountVoucherModel | null;
  
  get businessAccountControl(): FormControl {
    return this.formGroup.get('businessAccount') as FormControl;
  }

  get voucherCodeControl(): FormControl {
    return this.formGroup.get('voucherCode') as FormControl;
  }

  constructor(
    private _dialogRef: MatDialogRef<BusinessAdditionalInfoDialogComponent>, 
    private readonly _formBuilder: FormBuilder, 
    protected override readonly _injector: Injector,
    private _businessAccountService: BusinessAccountService) {
    super(_injector);

    this._initForm();

    this.businessCompanyNotes$
      .pipe(
        filter((note: string | null) => !!note),
        tap((note: string | null) => {
          this.formGroup.get('note')?.setValue(note);
        }),
        takeUntil(this._destroySubject)
      )
      .subscribe()
  }
  
  ngAfterViewInit(): void {
    this._initEvents();
  }

  close(shouldBook: boolean): void {
    this._dialogRef.close(shouldBook);
  }

  onContinueClick(): void {
    const businessAccount: BusinessAccountModel = this.formGroup.getRawValue().businessAccount as BusinessAccountModel
    this._store.dispatch(new BookingActions.SetBusinessAccount(businessAccount))
      .pipe(
        tap(() => this.close(true)),
        takeUntil(this._destroySubject)
      )
      .subscribe();

  }

  onCompanySelected(): void {
    const companyId: number = this.formGroup.getRawValue().businessAccount.id;
    this._store.dispatch(new BookingActions.GetBusinessCompanyNotes(companyId));
  }

  private _initForm(): void {
    this.formGroup = this._formBuilder.group({
      businessAccount: this._formBuilder.control<BusinessAccountModel | null>(null, !this.isInRole(RoleConstant.BUSINESS_ACCOUNT_USER) ? [Validators.required]: []),
      voucherCode: this._formBuilder.control(''),
      note: this._formBuilder.control({ value: '', disabled: true })
    });
  }
  
  private _initEvents(): void {
    if(this.businessAccountInput){
      fromEvent(this.businessAccountInput.nativeElement, 'keyup')
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
        switchMap(() => of(this._store.dispatch(new BookingActions.GetBusinessAccounts(this.businessAccountControl.value)))),
        takeUntil(this._destroySubject)
      )
      .subscribe();
    }


    if (this.voucherInput) {
      fromEvent(this.voucherInput.nativeElement, 'keyup')
        .pipe(
          distinctUntilChanged(),
          debounceTime(500),
          map(() => !this.voucherCodeControl.value ? null : this.voucherCodeControl.value),
          switchMap((value) => this._store.dispatch(new BookingActions.GetVoucher(value, this.businessAccountControl.value?.id ?? null, null))),
          filter(() => this.voucher != null && this.voucher!.businessAccountId !== null && this.voucher!.businessAccountName !== null),
          tap(() => {
            const selectedBusinessAccount: BusinessAccountModel = {
                id: this.voucher!.businessAccountId,
                name: this.voucher!.businessAccountName,
                description: "",
                isActive: true
            }
            this.businessAccountControl.setValue(selectedBusinessAccount);
          }),
          takeUntil(this._destroySubject)
      )
      .subscribe();
    }
  }

}
