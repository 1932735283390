import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProtectedEndpointsConstant } from '../../constants/protected-endpoints.constant';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseModel } from '../../models/base/response-model';
import { NotificationBookingConfirmationModel } from '../../models/notifiaction/notification-booking-confirmation.model';
import { NotificationCustomMessageModel } from '../../models/notifiaction/notification-custom-message.model';
import { NotificationCustomMessagesModel } from '../../models/notifiaction/notification-custom-messages.model';
import { FailedNotificationModel } from '../../models/notifiaction/notification-failed.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private readonly _http: HttpClient) { }

  sendBookingConfirmation(model: NotificationBookingConfirmationModel): Observable<ResponseModel<void>> {
    return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.NOTIFICATION}/send/booking-confirmation`, model);
  }
  
  sendCustomMessage(model: NotificationCustomMessageModel): Observable<ResponseModel<void>> {
    return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.NOTIFICATION}/send/custom-message`, model);
  }

  sendCustomMessages(model: NotificationCustomMessagesModel): Observable<ResponseModel<FailedNotificationModel[]>> {
    return this._http.post<ResponseModel<FailedNotificationModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.NOTIFICATION}/send/custom-messages`, model);
  }
}
