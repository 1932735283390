import { Injectable } from '@angular/core';
import { GetClinicScheduleVenueNotes } from '../../models/venue/get-clinic-schedule-venue-notes.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProtectedEndpointsConstant } from '../../constants/protected-endpoints.constant';
import { ResponseModel } from '../../models/base/response-model';
import { AddClinicScheduleVenueNotesModel } from '../../models/venue/add-clinic-schedule-venue-notes.model';
import { EditClinicScheduleVenueNotesModel } from '../../models/venue/edit-clinic-schedule-venue-notes.model';

@Injectable({
  providedIn: 'root'
})
export class VenueNoteService {

  constructor(private readonly _http: HttpClient) { }
  
  getClinicScheduleNotes(clinicScheduleId: number): Observable<ResponseModel<GetClinicScheduleVenueNotes[]>> {
    return this._http.get<ResponseModel<GetClinicScheduleVenueNotes[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.VENUE_NOTE}/${clinicScheduleId}/notes`);
  }

  addClinicScheduleNote(clinicScheduleId: number, request: AddClinicScheduleVenueNotesModel): Observable<ResponseModel<void>> {
    return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.VENUE_NOTE}/${clinicScheduleId}/note`, request);
  }

  editNote(request: EditClinicScheduleVenueNotesModel): Observable<ResponseModel<void>> {
    return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.VENUE_NOTE}/${request.id}/note-edit`, request);
  }

  deleteNote(id: number): Observable<ResponseModel<void>> {
    return this._http.delete<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.VENUE_NOTE}/${id}/note`);
  }
}
