<div class="generic-assertion-info-dialog-container">
    <div class="generic-assertion-info-dialog-header">
        <div>
            <span>{{model.title}}</span>
        </div>

        <div class="generic-assertion-info-dialog-header-close" (click)="close()"> <mat-icon>close</mat-icon></div>
    </div>
    <div class="generic-assertion-info-dialog-body">
       <span> {{model.text}}</span>

       <div class="generic-assertion-info-dialog-button">
        <button mat-raised-button (click)="onButtonClick()">{{model.buttonText}}</button>
      </div>
    </div>

   
</div>