import { AppointmentTypeLocalAuthority } from "../../models/appointment-type/appointment-type-local-authorities.model";
import { AddAppointmentNoteModel } from "../../models/appointment/add-appointment-note.model";
import { ReArrangeAppointmentModel } from "../../models/appointment/re-arrange-appointment.model";
import { UpdateAppointmentNoteModel } from "../../models/appointment/update-appointment-note.model";
import { UpdateNearestAppointmentModel } from "../../models/appointment/update-nearest-appointment.model";
import { AddCustomerNoteModel } from "../../models/customers/add-customer-note.model";
import { ExistingCustomerFilterModel } from "../../models/customers/existing-customers-filter.model";
import { UpdateCustomerNoteModel } from "../../models/customers/update-customer-note.model";
import { NotificationBookingConfirmationModel } from "../../models/notifiaction/notification-booking-confirmation.model";
import { NotificationCustomMessageModel } from "../../models/notifiaction/notification-custom-message.model";
import { NotificationCustomMessagesModel } from "../../models/notifiaction/notification-custom-messages.model";

export namespace CustomerActions {
    export class GetExistingCustomers {
        static readonly type = '[Customer] GetExistingCustomers';
    }

    export class GetCustomerNotes {
        static readonly type = '[Customer] GetCustomerNotes';
    }

    export class GetNearestAppointment {
        static readonly type = '[Customer] GetNearestAppointment';
    }

    export class GetCustomerNoteTypes {
        static readonly type = '[Customer] GetCustomerNoteTypes';
    }

    export class GetAppointments {
        static readonly type = '[Customer] GetAppointments';
    }

    export class GetAppointmentConsultationNotes {
        static readonly type = '[Customer] GetAppointmentConsultationNotes';
    }

    export class GetAppointmentConsultationNotesForId {
        static readonly type = '[Customer] GetAppointmentConsultationNotesForId';
        constructor(public id: number) { }
    }

    export class GetHistory {
        static readonly type = '[Customer] GetHistory';
    }

    export class SetExistingCustomerFilter {
        static readonly type = '[Customer] SetExistingCustomerFilter';
        constructor(public model: ExistingCustomerFilterModel) { }
    }

    export class SetSelectedCustomerId {
        static readonly type = '[Customer] SetSelectedCustomerId';
        constructor(public id: number) { }
    }

    export class SetSelectedBookingId {
        static readonly type = '[Customer] SetSelectedBookingId';
        constructor(public id: number) { }
    }

    export class SetReArrangeAppointment {
        static readonly type = '[Customer] SetReArrangeAppointment';
        constructor(public model: ReArrangeAppointmentModel | null) { }
    }

    export class SetReArrangeAppointmentTypeId {
        static readonly type = '[Customer] SetReArrangeAppointmentTypeId';
        constructor(public id?: number | null) { }
    }

    export class SetReArrangeLocalAuthority {
        static readonly type = '[Customer] SetReArrangeLocalAuthority';
        constructor(public localAuthority?: AppointmentTypeLocalAuthority | null) { }
    }

    export class SendCustomerBookingConfirmation {
        static readonly type = '[Customer] SendCustomerBookingConfirmation';
        constructor(public model: NotificationBookingConfirmationModel) { }
    }

    export class SendCustomerCustomMessage {
        static readonly type = '[Customer] SendCustomerCustomMessage';
        constructor(public model: NotificationCustomMessageModel) { }
    }

    export class SendCustomersCustomMessage {
        static readonly type = '[Customer] SendCustomersCustomMessage';
        constructor(public model: NotificationCustomMessagesModel) { }
    }

    export class AddCustomerNotes {
        static readonly type = '[Customer] AddCustomerNotes';
        constructor(public model: AddCustomerNoteModel) { }
    }
    export class UpdateCustomerNotes {
        static readonly type = '[Customer] UpdateCustomerNotes';
        constructor(public id: number, public model: UpdateCustomerNoteModel) { }
    }
    export class DeleteCustomerNotes {
        static readonly type = '[Customer] DeleteCustomerNotes';
        constructor(public id: number) { }
    }
    export class GetAppointmentNotes {
        static readonly type = '[Customer] GetAppointmentNotes';
    }
    export class GetAppointmentNotesForId {
        static readonly type = '[Customer] GetAppointmentNotesForId';
        constructor(public id: number) { }
    }
    export class AddAppointmentNote {
        static readonly type = '[Customer] AddAppointmentNote';
        constructor(public model: AddAppointmentNoteModel) { }
    }
    export class AddAppointmentNoteForId {
        static readonly type = '[Customer] AddAppointmentNoteForId';
        constructor(public id: number, public model: AddAppointmentNoteModel) { }
    }
    export class UpdateAppointmentNote {
        static readonly type = '[Customer] UpdateAppointmentNote';
        constructor(public model: UpdateAppointmentNoteModel) { }
    }
    export class UpdateAppointmentNoteForId {
        static readonly type = '[Customer] UpdateAppointmentNoteForId';
        constructor(public id: number, public model: UpdateAppointmentNoteModel) { }
    }
    export class DeleteAppointmentNote {
        static readonly type = '[Customer] DeleteAppointmentNote';
        constructor(public id: number) { }
    }
    export class DeleteAppointmentNoteForId {
        static readonly type = '[Customer] DeleteAppointmentNoteForId';
        constructor(public appointmentId: number, public id: number) { }
    }

    export class UpdateAppointmentAttendedStatus {
        static readonly type = '[Customer] UpdateAppointmentAttendedStatus';
        constructor(public id: number) { }
    }
    export class UpdateNearestAppointment {
        static readonly type = '[Customer] UpdateNearestAppointment';
        constructor(public model: UpdateNearestAppointmentModel) { }
    }
    export class ReArrangeCustomerAppointment {
        static readonly type = '[Customer] ReArrangeCustomerAppointment';
    }


    export class CancelCustomerAppointment {
        static readonly type = '[Customer] CancelCustomerAppointment';
        constructor(public refund: boolean) { }
    }
    export class CancelCustomerAppointmentForId {
        static readonly type = '[Customer] CancelCustomerAppointmentForId';
        constructor(public appointmentId: number, public selectedBookingId: number, public refund: boolean) { }
    }

    export class ClearCustomerDetails {
        static readonly type = '[Customer] ClearCustomerDetails';
    }

}