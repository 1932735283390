import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NoteImportance } from '../../models/note/note-importance';
import { CommonState } from '../../store/common/common.state';
import { CommonActions } from '../../store/common/common.actions';

@Component({
  selector: 'notes-with-crud',
  templateUrl: './notes-with-crud.component.html',
  styleUrls: ['./notes-with-crud.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotesWithCrudComponent extends BaseComponent implements OnInit {
  @Input() notes: NoteCrudModel[] | null |  undefined;
  @Input() showLabel!: boolean;
  @Input() isDialogCrud: boolean = false;
  @Input() bodyClass: string = 'note-with-crud-body';
  @Output() onAddNewClicked: EventEmitter<NoteCrudModel> = new EventEmitter();
  @Output() onEditClicked: EventEmitter<NoteCrudModel> = new EventEmitter();
  @Output() onEditNoteSaveClicked: EventEmitter<NoteCrudModel> = new EventEmitter();
  @Output() onDeleteClicked: EventEmitter<number> = new EventEmitter();
  @Output() onEditCancelClicked: EventEmitter<[NoteCrudModel, number]> = new EventEmitter();
  @Select(CommonState.getNoteImportanceList) noteImportanceList$!: Observable<NoteImportance[] | null>;

  ngOnInit(): void {
    this._store.dispatch(new CommonActions.GetNoteImportanceList());
  }

  onAddNoteClick(): void {
    this.onAddNewClicked.emit();
  }

  onDeleteNoteClick(id: number): void {
    this.onDeleteClicked.emit(id);
  }

  onEditNoteClick(model: NoteCrudModel): void {
    this.onEditClicked.emit(model);
  }

  onEditNoteSaveClick(model: NoteCrudModel): void {
    this.onEditNoteSaveClicked.emit(model);
  }

  onEditCancelClick(model: NoteCrudModel, index: number): void {
    this.onEditCancelClicked.emit([model, index]);
  }
}

export interface NoteCrudModel {
  id: number;
  noteText: string;
  createdAt?: string;
  createByUser?: string;
  noteImportanceId: number;
  noteEdit: string;
  isEdit: boolean;
}