import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProtectedEndpointsConstant } from '../../constants/protected-endpoints.constant';
import { DropdownResponseBaseModel } from '../../models/base/dropdown-response.model';
import { ResponseModel } from '../../models/base/response-model';
import { AccountUserModel } from '../../models/account/account-user.model';
import { PaginationModel } from '../../models/base/pagination.model';
import { AccountAddUserModel } from '../../models/account/account-add-user.model';
import { PaginationResponse } from '../../models/base/pagination-response.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private readonly _http: HttpClient) { }
  getRoles(): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
    return this._http.get<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.USER}/roles`);
  }

  get(pagination: PaginationModel, showActiveOnly: boolean | null, searchText: string | null): Observable<ResponseModel<PaginationResponse<AccountUserModel>>> {
    let params = new HttpParams();
    params = params.append("showActiveOnly", showActiveOnly ?? 'null');

    if (searchText) {
      params = params.append("searchText", searchText);
    }
    if (pagination) {
      params = params.append("pagination.pageNumber", pagination.pageNumber.toString());
      params = params.append("pagination.pageSize", pagination.pageSize.toString());
    }

    return this._http.get<ResponseModel<PaginationResponse<AccountUserModel>>>(`${environment.baseUrl}${ProtectedEndpointsConstant.USER}`, { params: params });
  }

  add(model: AccountAddUserModel): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
    return this._http.post<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.USER}`, model);
  }

  edit(model: AccountAddUserModel): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
    return this._http.put<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.USER}`, model);
  }
}
