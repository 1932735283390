import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { ClinicBidService, ClinicScheduleService, VenueNoteService } from "../../providers/services";
import { ShiftManagerStateModel } from "../models/shift-manager-state.model";
import { ShiftManagerActions } from "./shift-manager.actions";
import { DropdownResponseBaseModel } from "../../models/base/dropdown-response.model";
import { RegionService } from "../../providers/services/region.service";
import { Observable, of, tap } from "rxjs";
import { ResponseModel } from "../../models/base/response-model";
import { ClinicsStatisticFilter } from "../../models/clinics/clinic-statistic-filter.model";
import { ClinicsBookingStatusModel } from "../../models/clinics/clinics-booking-status.model";
import { ClinicsPlanningModel } from "../../models/clinics/clinics-planing.model";
import { ClinicsFilter } from "../../models/clinics/clinics-filter.model";
import { ClinicsGetSpecificLocationModel } from "../../models/clinics/clinics-get-specific-location.model";
import { ClinicsSpecificLocationFilterModel } from "../../models/clinics/clinics-specific-location-filter.model";
import { VenueService } from "../../providers/services/venue.service";
import { PractitionerService } from "../../providers/services/practitioner.service";
import { ClinicsSpecifiLocationRequestModel as ClinicsSpecificLocationRequestModel } from "../../models/clinics/clinics-specific-location-request.model";
import { BulkChangeTimesRequestModel } from "../../models/clinic-schedule/bulk-change-times-request.model";
import { BulkAssignVenueRequestModel } from "../../models/clinic-schedule/bulk-assign-venue-request.model";
import { GetClinicScheduleDetailsModel } from "../../models/clinic-schedule/get-clinics-schedule-details.model";
import { GetClinicSchedulePractitionersBidsModel } from "../../models/clinic-bid/get-clinic-schedule-practitioners-bids.model";
import { ClinicBidConflictModel } from "../../models/clinic-bid/clinic-bid-conflict.model";
import { GetClinicSchedulePractitionerBidsDetailsModel } from "../../models/clinic-bid/get-clinic-schedule-practitioner-bids-details";
import { GetClinicScheduleVenueNotes } from "../../models/venue/get-clinic-schedule-venue-notes.model";

@State<ShiftManagerStateModel>({
    name: 'shiftManager',
    defaults: {
        locationPlanningSelected: null,
        regions: null,
        clinicsPlannings: null,
        clinicsBookedStatuses: null,
        clinicsBookedStatusFilter: null,
        clinicsPlanningFilter: null,
        selectedLocation: null,
        selectedClinicScheduleId: null,
        selectedClinicScheduleDate: null,
        selectedClinicScheduleDoctorId: null,
        clinicScheduleBidAwardConflict: null,
        practitionerBidsDetails: null,

        locations: null,
        doctorStatuses: null,
        doctors: null,
        venues: null,
        venueStatuses: null,
        specificLocationFilter: null,
        specificLocations: null,
        clinicScheduleDetails: null,
        clinicScheduleEditMode: null,
        clinicScheduleDetailsFormGroupValid: null,
        clinicScheduleDetailsFormSubmitted: null,
        practitionersBids: null,
        clinicScheduleVenueNotes: null
    }
})

@Injectable()
export class ShiftManagerState {
    constructor(private readonly _regionsService: RegionService,
        private readonly _venueService: VenueService,
        private readonly _venueNoteService: VenueNoteService,
        private readonly _practitionerService: PractitionerService,
        private readonly _clinicBidService: ClinicBidService,
        private readonly _clinicScheduleService: ClinicScheduleService) {
    }

    @Selector()
    static getLocationPlanningValue(state: ShiftManagerStateModel): boolean | null {
        return state.locationPlanningSelected;
    }

    @Selector()
    static getRegions(state: ShiftManagerStateModel): DropdownResponseBaseModel[] | null {
        return state.regions;
    }

    @Selector()
    static getDoctorStatuses(state: ShiftManagerStateModel): DropdownResponseBaseModel[] | null {
        return state.doctorStatuses;
    }

    @Selector()
    static getClinicsBookedStatusFilter(state: ShiftManagerStateModel): ClinicsFilter | null {
        return state.clinicsBookedStatusFilter;
    }


    @Selector()
    static getClinicsPlanningFilter(state: ShiftManagerStateModel): ClinicsFilter | null {
        return state.clinicsPlanningFilter
    }

    @Selector()
    static getClinicsBookedStatuses(state: ShiftManagerStateModel): ClinicsBookingStatusModel[] | null {
        return state.clinicsBookedStatuses;
    }

    @Selector()
    static getClinicsPlannings(state: ShiftManagerStateModel): ClinicsPlanningModel[] | null {
        return state.clinicsPlannings;
    }

    @Selector()
    static getSelectedLocation(state: ShiftManagerStateModel): DropdownResponseBaseModel| null {
        return state.selectedLocation;
    }

    @Selector()
    static getLocations(state: ShiftManagerStateModel): DropdownResponseBaseModel[] | null {
        return state.locations;
    }

    @Selector()
    static getDoctors(state: ShiftManagerStateModel): DropdownResponseBaseModel[] | null {
        return state.doctors;
    }

    @Selector()
    static getVenues(state: ShiftManagerStateModel): DropdownResponseBaseModel[] | null {
        return state.venues;
    }

    @Selector()
    static getVenueStatuses(state: ShiftManagerStateModel): DropdownResponseBaseModel[] | null {
        return state.venueStatuses;
    }

    @Selector()
    static getSpecificLocations(state: ShiftManagerStateModel): ClinicsGetSpecificLocationModel[] | null {
        return state.specificLocations;
    }

    @Selector()
    static getSpecificLocationsFilter(state: ShiftManagerStateModel): ClinicsSpecificLocationFilterModel | null {
        return state.specificLocationFilter;
    }

    @Selector()
    static getClinicScheduleEditMode(state: ShiftManagerStateModel): boolean| null {
        return state.clinicScheduleEditMode;
    }

    @Selector()
    static getClinicScheduleDetails(state: ShiftManagerStateModel): GetClinicScheduleDetailsModel | null {
        return state.clinicScheduleDetails;
    }

    @Selector()
    static getClinicScheduleDetailsFormGroupValid(state: ShiftManagerStateModel): boolean | null {
        return state.clinicScheduleDetailsFormGroupValid;
    }

    @Selector()
    static getClinicScheduleDetailsFormSubmitted(state: ShiftManagerStateModel): boolean | null {
        return state.clinicScheduleDetailsFormSubmitted;
    }

    @Selector()
    static getSelectedClinicScheduleId(state: ShiftManagerStateModel): number | null {
        return state.selectedClinicScheduleId;
    }
    
    @Selector()
    static getSelectedClinicScheduleDate(state: ShiftManagerStateModel): string | null {
        return state.selectedClinicScheduleDate;
    }

    @Selector()
    static getPractitionersBids(state: ShiftManagerStateModel): GetClinicSchedulePractitionersBidsModel[] | null {
        return state.practitionersBids;
    }
    
    @Selector()
    static getSelectedClinicScheduleDoctorId(state: ShiftManagerStateModel): number | null {
        return state.selectedClinicScheduleDoctorId;
    }
    
    @Selector()
    static getClinicScheduleBidAwardConflict(state: ShiftManagerStateModel): ClinicBidConflictModel | null {
        return state.clinicScheduleBidAwardConflict;
    }
    
    @Selector()
    static getPractitionerBidsDetails(state: ShiftManagerStateModel): GetClinicSchedulePractitionerBidsDetailsModel | null {
        return state.practitionerBidsDetails;
    }

    @Selector()
    static getClinicScheduleVenueNotes(state: ShiftManagerStateModel): GetClinicScheduleVenueNotes[] | null {
        return state.clinicScheduleVenueNotes;
    }

    @Action(ShiftManagerActions.SetClinincFilterPlanning)
    setClinincFilterPlanning(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.SetClinincFilterPlanning): void {
        ctx.patchState({
            clinicsPlanningFilter: action.value
        })
    }

    @Action(ShiftManagerActions.SetClinincFilterBooking)
    setClinincFilterBooking(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.SetClinincFilterBooking): void {
        ctx.patchState({
            clinicsBookedStatusFilter: action.value
        })
    }


    @Action(ShiftManagerActions.SetLocationTogglerValue)
    setLocationTogglerValue(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.SetLocationTogglerValue): void {
        ctx.patchState({
            locationPlanningSelected: action.value
        })
    }

    @Action(ShiftManagerActions.SetSelectedLocation)
    setSelectedLocationId(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.SetSelectedLocation): void {
        ctx.patchState({
            selectedLocation: action.value
        })
    }

    @Action(ShiftManagerActions.SetSpecificLocationFilter)
    setGetSpecificLocationFilter(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.SetSpecificLocationFilter): void {
        ctx.patchState({
            specificLocationFilter: action.model
        })
    }

    @Action(ShiftManagerActions.SetClinicScheduleEditMode)
    setClinicScheduleEditMode(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.SetClinicScheduleEditMode): void {
        ctx.patchState({
            clinicScheduleEditMode: action.value
        })
    }

    @Action(ShiftManagerActions.SetClinicScheduleDetailsFormGroupValid)
    setClinicScheduleDetailsFormGroupValid(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.SetClinicScheduleDetailsFormGroupValid): void {
        ctx.patchState({
            clinicScheduleDetailsFormGroupValid: action.value
        })
    }

    @Action(ShiftManagerActions.SetClinicScheduleDetailsFormSubmited)
    setClinicScheduleDetailsFormSubmited(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.SetClinicScheduleDetailsFormSubmited): void {
        ctx.patchState({
            clinicScheduleDetailsFormSubmitted: action.value
        })
    }
    
    @Action(ShiftManagerActions.SetSelectedClinicScheduleId)
    setSelectedClinicScheduleId(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.SetSelectedClinicScheduleId): void {
        ctx.patchState({
            selectedClinicScheduleId: action.id
        })
    }

    @Action(ShiftManagerActions.SetSelectedClinicScheduleDate)
    setSelectedClinicScheduleDate(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.SetSelectedClinicScheduleDate): void {
        ctx.patchState({
            selectedClinicScheduleDate: action.date
        })
    }

    @Action(ShiftManagerActions.SetSelectedClinicScheduleDoctorId)
    setSelectedClinicScheduleDoctorId(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.SetSelectedClinicScheduleDoctorId): void {
        ctx.patchState({
            selectedClinicScheduleDoctorId: action.id
        })
    }
    
    @Action(ShiftManagerActions.GetDoctors)
    getDoctors(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.GetDoctors): Observable<DropdownResponseBaseModel[]> {
        return this._practitionerService.getPractitioners(action.searchText)
            .pipe(
                tap((response: DropdownResponseBaseModel[]) => {
                    response.unshift({id: 0, name: 'None'}); 
                    ctx.patchState({
                        doctors: response
                    })
                })
            );
    }

    @Action(ShiftManagerActions.GetDoctorStatuses)
    getDoctorStatuses(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.GetDoctorStatuses): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._clinicScheduleService.getClinicSchedulePractitionerStatuses()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        doctorStatuses: response.content
                    })
                })
            );
    }

    @Action(ShiftManagerActions.GetVenues)
    getVenues(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.GetVenues): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._venueService.getVenues(action.searchText, action.locationId)
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    response.content.unshift({id: 0, name: 'None'});
                    ctx.patchState({
                        venues: response.content
                    })
                })
            );
    }

    @Action(ShiftManagerActions.GetVenueStatuses)
    getVenueStatuses(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.GetVenueStatuses): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._clinicScheduleService.getClinicScheduleVenueStatuses()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        venueStatuses: response.content
                    })
                })
            );
    }

    @Action(ShiftManagerActions.GetRegions)
    getRegions(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.GetRegions): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._regionsService.getRegions()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        regions: response.content
                    })
                })
            );
    }


    @Action(ShiftManagerActions.GetLocations)
    getLocations(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.GetLocations): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._regionsService.getLocations()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        locations: response.content
                    })
                })
            );
    }

    @Action(ShiftManagerActions.GetClinicsBookedStatuses)
    getClinicsBookedStatuses(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.GetClinicsBookedStatuses): Observable<ResponseModel<ClinicsBookingStatusModel[]>> {
        const state = ctx.getState();
        const request: ClinicsStatisticFilter = {
            locationName: state.clinicsBookedStatusFilter?.locationName!,
            regionId: state.clinicsBookedStatusFilter?.region?.id,
            startDate: state.clinicsBookedStatusFilter ? state.clinicsBookedStatusFilter.startMonth!.format('YYYY-MM-DD') : null,
            endDate: state.clinicsBookedStatusFilter ? state.clinicsBookedStatusFilter.endMonth!.format('YYYY-MM-DD') : null,
        };

        return this._clinicScheduleService.bookingStatuses(request)
            .pipe(
                tap((response: ResponseModel<ClinicsBookingStatusModel[]>) => {
                    ctx.patchState({
                        clinicsBookedStatuses: response.content
                    })
                })
            );
    }


    @Action(ShiftManagerActions.GetClinicPlannings)
    getClinicPlannings(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.GetClinicPlannings): Observable<ResponseModel<ClinicsPlanningModel[]>> {
        const state = ctx.getState();
        const request: ClinicsStatisticFilter = {
            locationName: state.clinicsPlanningFilter?.locationName!,
            regionId: state.clinicsPlanningFilter?.region?.id,
            startDate: state.clinicsPlanningFilter ? state.clinicsPlanningFilter.startMonth!.format('YYYY-MM-DD') : null,
            endDate: state.clinicsPlanningFilter ? state.clinicsPlanningFilter.endMonth!.format('YYYY-MM-DD') : null,
        };
        return this._clinicScheduleService.planning(request)
            .pipe(
                tap((response: ResponseModel<ClinicsPlanningModel[]>) => {
                    ctx.patchState({
                        clinicsPlannings: response.content
                    })
                })
            );
    }

    @Action(ShiftManagerActions.GetClinicsSpecificLocations)
    getClinicsSpecificLocations(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.GetClinicsSpecificLocations): Observable<ResponseModel<ClinicsGetSpecificLocationModel[]>> {
        const state = ctx.getState();
        const request: ClinicsSpecificLocationRequestModel = {
            startDate: state.specificLocationFilter!.startDate.format('YYYY-MM-DDTHH:mm'),
            endDate: state.specificLocationFilter!.endDate.format('YYYY-MM-DDTHH:mm'),
            clinicSchedulePractitionerStatusIds: state.specificLocationFilter?.doctorStatuses && state.specificLocationFilter?.doctorStatuses.length > 0
                ? state.specificLocationFilter.doctorStatuses?.map(item => item.id)
                : null,
            clinicScheduleVenueStatusIds: state.specificLocationFilter?.venueStatuses && state.specificLocationFilter?.venueStatuses.length > 0
                ? state.specificLocationFilter?.venueStatuses?.map(item => item.id)
                : null,
            practitionerIds: state.specificLocationFilter?.doctors ? state.specificLocationFilter?.doctors?.map(item => item.id) : null,
            venueIds: state.specificLocationFilter?.venues ? state.specificLocationFilter?.venues?.map(item => item.id) : null
        }

        return this._clinicScheduleService.specificLocationClinicSchedule(state.selectedLocation?.id!, request)
            .pipe(
                tap((response: ResponseModel<ClinicsGetSpecificLocationModel[]>) => {
                    ctx.patchState({
                        specificLocations: response.content
                    })
                })
            );
    }

    @Action(ShiftManagerActions.GetClinicSchedulePractitionersBids)
    getClinicSchedulePractitionersBids(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.GetClinicSchedulePractitionersBids): Observable<ResponseModel<GetClinicSchedulePractitionersBidsModel[]>> {
        const state = ctx.getState();
        if(!state.selectedClinicScheduleId || !state.selectedClinicScheduleDate){
            return of();
        }

        return this._clinicBidService.getClinicSchedulePractitionersBids(state.selectedClinicScheduleId, state.selectedClinicScheduleDate)
            .pipe(
                tap((response: ResponseModel<GetClinicSchedulePractitionersBidsModel[]>) => {
                    ctx.patchState({
                        practitionersBids: response.content
                    })
                })
            );
    }

    @Action(ShiftManagerActions.GetClinicSchedulePractitionerBidsDetails)
    getClinicSchedulePractitionerBidsDetails(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.GetClinicSchedulePractitionerBidsDetails): Observable<ResponseModel<GetClinicSchedulePractitionerBidsDetailsModel>> {
        const state = ctx.getState();
        if(!state.selectedClinicScheduleDoctorId){
            return of();
        }

        return this._clinicBidService.getClinicSchedulePractitionerBidsDetails(action.id, state.selectedClinicScheduleDoctorId)
            .pipe(
                tap((response: ResponseModel<GetClinicSchedulePractitionerBidsDetailsModel>) => {
                    ctx.patchState({
                        practitionerBidsDetails: response.content
                    })
                })
            );
    }

    @Action(ShiftManagerActions.GetClinicScheduleDetails)
    getClinicScheduleDetails(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.GetClinicScheduleDetails): Observable<ResponseModel<GetClinicScheduleDetailsModel>> {
        const state = ctx.getState();
        if(!state.selectedClinicScheduleId){
            return of();
        }

        return this._clinicScheduleService.getClinicScheduleDetails(state.selectedClinicScheduleId)
            .pipe(
                tap((response: ResponseModel<GetClinicScheduleDetailsModel>) => {
                    ctx.patchState({
                        clinicScheduleDetails: response.content
                    })
                })
            );
    }
    
    @Action(ShiftManagerActions.GetClinicScheduleVenueNotes)
    getClinicScheduleVenueNotes(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.GetClinicScheduleVenueNotes): Observable<ResponseModel<GetClinicScheduleVenueNotes[]>> {
        const state = ctx.getState();
        if(!state.selectedClinicScheduleId){
            return of();
        }

        return this._venueNoteService.getClinicScheduleNotes(state.selectedClinicScheduleId)
            .pipe(
                tap((response: ResponseModel<GetClinicScheduleVenueNotes[]>) => {
                    ctx.patchState({
                        clinicScheduleVenueNotes: response.content
                    })
                })
            );
    }

    @Action(ShiftManagerActions.ClinicScheduleCreateSingleDate)
    clinicScheduleCreateSingleDate(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.ClinicScheduleCreateSingleDate): Observable<ResponseModel<void>> {
        return this._clinicScheduleService.createSingleDate(action.model);
    }

    @Action(ShiftManagerActions.ClinicScheduleCreateRangeDates)
    clinicScheduleCreateRangeDates(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.ClinicScheduleCreateRangeDates): Observable<ResponseModel<void>> {
        return this._clinicScheduleService.createRangeDates(action.model);
    }

    @Action(ShiftManagerActions.ClinicScheduleVenueAddNote)
    clinicScheduleVenueAddNote(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.ClinicScheduleVenueAddNote): Observable<ResponseModel<void>> {
        const state = ctx.getState();
        if(!state.selectedClinicScheduleId){
            return of();
        }
        return this._venueNoteService.addClinicScheduleNote(state.selectedClinicScheduleId, action.request);
    }


    @Action(ShiftManagerActions.ChangeTimeSpecificLocationBulk)
    changeTimesSpecificLocationBulk(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.ChangeTimeSpecificLocationBulk): Observable<ResponseModel<void>> {
        const request: BulkChangeTimesRequestModel = {
            startTime: action.startTime,
            endTime: action.endTime,
            duration: action.duration,
            ids: action.ids
        };
        
        return this._clinicScheduleService.bulkChangeTimes(request);
    }

    @Action(ShiftManagerActions.AssignVenueSpecificLocationBulk)
    assignVenueSpecificLocationBulk(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.AssignVenueSpecificLocationBulk): Observable<ResponseModel<void>> {
        const request: BulkAssignVenueRequestModel = {
            venueId: action.venueId,
            ids: action.ids
        };
        
        return this._clinicScheduleService.bulkAssignVenue(request);
    }

    @Action(ShiftManagerActions.UpdateClinicScheduleDetails)
    updateClinicScheduleDetails(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.UpdateClinicScheduleDetails): Observable<ResponseModel<void>> {
        return this._clinicScheduleService.updateClinicScheduleDetails(action.model)
        .pipe(
            tap(() => {
                ctx.patchState({
                    clinicScheduleDetails: null,
                    clinicScheduleEditMode: null,
                    clinicScheduleDetailsFormGroupValid: null,
                    clinicScheduleDetailsFormSubmitted: null
                })
            })
        );
    }

    @Action(ShiftManagerActions.ReAdvertiseClinicSchedule)
    reAdvertiseClinicSchedule(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.ReAdvertiseClinicSchedule): Observable<ResponseModel<void>> {
        const state = ctx.getState();
        if(!state.clinicScheduleDetails){
            return of();
        }
        return this._clinicScheduleService.reAdvertiseClinicSchedule(state.clinicScheduleDetails.id)
    }
    
    @Action(ShiftManagerActions.AwardClinicScheduleBid)
    awardClinicScheduleBid(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.AwardClinicScheduleBid): Observable<ResponseModel<void>> {
        const state = ctx.getState();
        if(!state.selectedClinicScheduleId){
            return of();
        }
        return this._clinicBidService.awardClinicScheduleBid(state.selectedClinicScheduleId, action.bidId);
    }

    @Action(ShiftManagerActions.AwardClinicScheduleResolveConflict)
    awardClinicScheduleResolveConflict(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.AwardClinicScheduleResolveConflict): Observable<ResponseModel<void>> {
        const state = ctx.getState();
        if(!state.clinicScheduleBidAwardConflict){
            return of();
        }
        return this._clinicBidService.awardClinicScheduleResolveConflict(state.clinicScheduleBidAwardConflict.currentBidId, action.request);
    }

    @Action(ShiftManagerActions.AwardClinicScheduleBidConflict)
    awardClinicScheduleBidConflict(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.AwardClinicScheduleBidConflict): Observable<ResponseModel<ClinicBidConflictModel | null>> {
        return this._clinicBidService.awardConfilct(action.bidId)
        .pipe(
            tap((response: ResponseModel<ClinicBidConflictModel | null>) => {
                ctx.patchState({
                    clinicScheduleBidAwardConflict: response.content,
                })
            })
        );
    }

    @Action(ShiftManagerActions.ClinicScheduleVenueEditNote)
    clinicScheduleVenueEditNote(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.ClinicScheduleVenueEditNote): Observable<ResponseModel<void>> {
        return this._venueNoteService.editNote(action.request);
    }

    @Action(ShiftManagerActions.DeleteSpecificLocation)
    deleteSpecificLocation(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.DeleteSpecificLocation): Observable<ResponseModel<void>> {
        return this._clinicScheduleService.delete(action.id);
    }

    @Action(ShiftManagerActions.DeleteSpecificLocationBulk)
    deleteSpecificLocationBulk(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.DeleteSpecificLocationBulk): Observable<ResponseModel<void>> {
        return this._clinicScheduleService.bulkDelete(action.ids);
    }

    @Action(ShiftManagerActions.DeleteClinicScheduleVenueNote)
    deleteClinicScheduleVenueNote(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.DeleteClinicScheduleVenueNote): Observable<ResponseModel<void>> {
        return this._venueNoteService.deleteNote(action.id);
    }

    @Action(ShiftManagerActions.ClearClinicScheduleDetails)
    clearClinicScheduleDetails(ctx: StateContext<ShiftManagerStateModel>, action: ShiftManagerActions.ClearClinicScheduleDetails): void {
        ctx.patchState({
            clinicScheduleDetails: null,
            clinicScheduleEditMode: null,
            clinicScheduleDetailsFormGroupValid: null,
            clinicScheduleDetailsFormSubmitted: null,
            practitionersBids: null
        })
    }
}