import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Params, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

export interface Breadcrumb {
  label: string;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  breadcrumbs: Breadcrumb[] = [];

  constructor(private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.buildBreadcrumbs();
    });

    this.buildBreadcrumbs();
  }

  public buildBreadcrumbs(): void {
    this.breadcrumbs = [];
    const root = this.router.routerState.snapshot.root;
    const currentParams = this.collectParams(root);
    this.breadcrumbs = this.createBreadcrumbs(root, currentParams);
  }

  private collectParams(route: ActivatedRouteSnapshot): Params {
    let params = {};
    if (route.firstChild) {
      params = { ...params, ...this.collectParams(route.firstChild) };
    }
    params = { ...params, ...route.params };
    return params;
  }

  private createBreadcrumbs(route: ActivatedRouteSnapshot, params: Params, breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const data = route.routeConfig && route.routeConfig.data;
    if (data && data['breadcrumbs']) {
      data['breadcrumbs'].forEach((breadcrumb: Breadcrumb) => {
        const url = this.interpolateUrl(breadcrumb.url, params);
        if (!breadcrumbs.some(bc => bc.url === url)) {
          breadcrumbs.push({ label: breadcrumb.label, url });
        }
      });
    }

    if (route.firstChild) {
      return this.createBreadcrumbs(route.firstChild, params, breadcrumbs);
    }

    return breadcrumbs;
  }

  setBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
    this.breadcrumbs = breadcrumbs;
  }

  updateBreadcrumb(index: number, label: string, link?: string): void {
    if (index >= 0 && index < this.breadcrumbs.length) {
      const url = link ?? this.breadcrumbs[index].url;
      this.breadcrumbs[index] = { ...this.breadcrumbs[index], label, url };
    }
  }

  private interpolateUrl(url: string, params: Params): string {
    return url.replace(/:([a-zA-Z]+)/g, (match, key) => params[key] || match);
  }
}
