import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { CustomerStateModel } from "../models/customer-state.model";
import { ExistingCustomerFilterModel } from "../../models/customers/existing-customers-filter.model";
import { CustomerActions } from "./customer.actions";
import { AppointmentService, CustomerService, NotificationService, ToastrMessagesService } from "../../providers/services";
import { Observable, of, tap } from "rxjs";
import { ResponseModel } from "../../models/base/response-model";
import { ExistingCustomerModel } from "../../models/customers/existing-customers.model";
import * as moment from "moment";
import { CustomerNoteModel } from "../../models/customers/customer-note.model";
import { CustomerAppointmentModel } from "../../models/customers/customer-appointment.model";
import { DropdownResponseBaseModel } from "../../models/base/dropdown-response.model";
import { CustomerAllAppointmentModel } from "../../models/customers/customer-all-appointment.model";
import { CustomerHistoryModel } from "../../models/customers/customer-history.model";
import { FailedNotificationModel } from "../../models/notifiaction/notification-failed.model";
import { AppointmentNoteModel } from "../../models/appointment/appointment-note.model";
import { ReArrangeAppointmentModel } from "../../models/appointment/re-arrange-appointment.model";
import { AppointmentConsultationQuestionsModel } from "../../models/appointment/appointment-consultation-questions.model";
import { AppointmentTypeLocalAuthority } from "../../models/appointment-type/appointment-type-local-authorities.model";


@State<CustomerStateModel>({
    name: 'customer',
    defaults: {
        existingCustomerFilter: null,
        existingCustomers: null,
        selectedCustomerId: null,
        selectedBookingId: null,
        customerNotes: null,
        nearestAppointment: null,
        customerNoteTypes: null,
        appointments: null,
        history: null,
        reArrangeAppointment: null,
        reArrangeAppointmentTypeId: null,
        reArrangeLocalAuthority: null,
        appointmentNotes: null,
        appointmentConsultationQuestions: null
    }
})

@Injectable()
export class CustomerState {
    constructor(private readonly _customerService: CustomerService, private readonly _notificationService: NotificationService, 
        private readonly _appointmentService: AppointmentService,
        private readonly _toastrMessages: ToastrMessagesService) {
    }

    @Selector()
    static getExistingCustomerFilter(state: CustomerStateModel): ExistingCustomerFilterModel | null {
        return state.existingCustomerFilter
    }

    @Selector()
    static getExistingCustomers(state: CustomerStateModel): ExistingCustomerModel[] | null {
        return state.existingCustomers
    }

    @Selector()
    static getSelectedCustomerId(state: CustomerStateModel): number | null {
        return state.selectedCustomerId
    }

    @Selector()
    static getNearestAppointment(state: CustomerStateModel): CustomerAppointmentModel | null {
        return state.nearestAppointment;
    }

    @Selector()
    static getAppointments(state: CustomerStateModel): CustomerAllAppointmentModel[] | null {
        return state.appointments;
    }

    @Selector()
    static getCustomerNotes(state: CustomerStateModel): CustomerNoteModel[] | null {
        return state.customerNotes;
    }

    @Selector()
    static getCustomerNoteTypes(state: CustomerStateModel): DropdownResponseBaseModel[] | null {
        return state.customerNoteTypes;
    }
    @Selector()
    static getSelectedBookingId(state: CustomerStateModel): number | null {
        return state.selectedBookingId;
    }
    @Selector()
    static getHistory(state: CustomerStateModel): CustomerHistoryModel[] | null {
        return state.history;
    }

    @Selector()
    static getReArrangeAppointmentTypeId(state: CustomerStateModel): number | null {
        return state.reArrangeAppointmentTypeId;
    }

    @Selector()
    static getReArrangeAppointment(state: CustomerStateModel): ReArrangeAppointmentModel | null {
        return state.reArrangeAppointment;
    }

    @Selector()
    static getReArrangeLocalAuthority(state: CustomerStateModel): AppointmentTypeLocalAuthority | null {
        return state.reArrangeLocalAuthority;
    }

    @Selector()
    static getAppointmentNotes(state: CustomerStateModel): AppointmentNoteModel[] | null {
        return state.appointmentNotes;
    }
    @Selector()
    static getAppointmentConsultationQuestions(state: CustomerStateModel): AppointmentConsultationQuestionsModel  | null {
        return state.appointmentConsultationQuestions;
    }


    @Action(CustomerActions.SetExistingCustomerFilter)
    setExistingCustomerFilter(ctx: StateContext<CustomerStateModel>, action: CustomerActions.SetExistingCustomerFilter): void {
        ctx.patchState({
            existingCustomerFilter: action.model
        })
    }

    @Action(CustomerActions.SetSelectedCustomerId)
    setSelectedCustomerId(ctx: StateContext<CustomerStateModel>, action: CustomerActions.SetSelectedCustomerId): void {
        ctx.patchState({
            selectedCustomerId: action.id
        })
    }

    @Action(CustomerActions.SetSelectedBookingId)
    setSelectedBookingId(ctx: StateContext<CustomerStateModel>, action: CustomerActions.SetSelectedBookingId): void {
        ctx.patchState({
            selectedBookingId: action.id
        })
    }

    @Action(CustomerActions.SetReArrangeAppointment)
    setReArrangeAppointment(ctx: StateContext<CustomerStateModel>, action: CustomerActions.SetReArrangeAppointment): void {
        ctx.patchState({
            reArrangeAppointment: action.model
        })
    }

    @Action(CustomerActions.SetReArrangeAppointmentTypeId)
    setReArrangeAppointmentTypeId(ctx: StateContext<CustomerStateModel>, action: CustomerActions.SetReArrangeAppointmentTypeId): void {
        ctx.patchState({
            reArrangeAppointmentTypeId: action.id
        })
    }
    @Action(CustomerActions.SetReArrangeLocalAuthority)
    setReArrangeLocalAuthority(ctx: StateContext<CustomerStateModel>, action: CustomerActions.SetReArrangeLocalAuthority) : void {
        ctx.patchState({
            reArrangeLocalAuthority: action.localAuthority
        })
    }

    @Action(CustomerActions.GetExistingCustomers)
    getExistingCustomers(ctx: StateContext<CustomerStateModel>, action: CustomerActions.GetExistingCustomers): Observable<ResponseModel<ExistingCustomerModel[]>> {
        const state = ctx.getState();

        if (!state.existingCustomerFilter) {
            return of();
        }
        const request = state.existingCustomerFilter;
        request.clinicDate = state.existingCustomerFilter.clinicDate ? moment(state.existingCustomerFilter.clinicDate).format('YYYY-MM-DD') : null;
        request.dateOfBirth = state.existingCustomerFilter.dateOfBirth ? moment(state.existingCustomerFilter.dateOfBirth).format('YYYY-MM-DD') : null;
        request.count = request.clinicDate ? null : 30;
        
        return this._customerService.getCustomersBySearchFilter(state.existingCustomerFilter)
            .pipe(
                tap((response: ResponseModel<ExistingCustomerModel[]>) => {
                    ctx.patchState({
                        existingCustomers: response.content
                    })
                })
            );
    }

    @Action(CustomerActions.GetCustomerNotes)
    getCustomerNotes(ctx: StateContext<CustomerStateModel>, action: CustomerActions.GetCustomerNotes): Observable<ResponseModel<CustomerNoteModel[]>> {
        const state = ctx.getState();

        if (!state.selectedCustomerId) {
            return of();
        }

        return this._customerService.getCustomerNotes(state.selectedCustomerId)
            .pipe(
                tap((response: ResponseModel<CustomerNoteModel[]>) => {
                    ctx.patchState({
                        customerNotes: response.content
                    })
                })
            );
    }

    @Action(CustomerActions.GetNearestAppointment)
    getNearestAppointment(ctx: StateContext<CustomerStateModel>, action: CustomerActions.GetNearestAppointment): Observable<ResponseModel<CustomerAppointmentModel>> {
        const state = ctx.getState();

        if (!state.selectedCustomerId) {
            return of();
        }

        return this._customerService.getNearestAppointment(state.selectedCustomerId)
            .pipe(
                tap((response: ResponseModel<CustomerAppointmentModel>) => {
                    ctx.patchState({
                        nearestAppointment: response.content
                    })
                })
            );
    }


    @Action(CustomerActions.GetAppointments)
    getAppointments(ctx: StateContext<CustomerStateModel>, action: CustomerActions.GetAppointments): Observable<ResponseModel<CustomerAllAppointmentModel[]>> {
        const state = ctx.getState();

        if (!state.selectedCustomerId) {
            return of();
        }

        return this._customerService.getAppointments(state.selectedCustomerId, null)
            .pipe(
                tap((response: ResponseModel<CustomerAllAppointmentModel[]>) => {
                    ctx.patchState({
                        appointments: response.content
                    })
                })
            );
    }

    @Action(CustomerActions.GetHistory)
    getHistory(ctx: StateContext<CustomerStateModel>, action: CustomerActions.GetHistory): Observable<ResponseModel<CustomerHistoryModel[]>> {
        const state = ctx.getState();

        if (!state.selectedCustomerId) {
            return of();
        }

        return this._customerService.getHistory(state.selectedCustomerId, null)
            .pipe(
                tap((response: ResponseModel<CustomerHistoryModel[]>) => {
                    ctx.patchState({
                        history: response.content
                    })
                })
            );
    }

    @Action(CustomerActions.GetCustomerNoteTypes)
    getCustomerNoteTypes(ctx: StateContext<CustomerStateModel>, action: CustomerActions.GetCustomerNoteTypes): Observable<ResponseModel<DropdownResponseBaseModel[]>> {

        return this._customerService.getCustomerNoteTypes()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        customerNoteTypes: response.content
                    })
                })
            );
    }

    @Action(CustomerActions.GetAppointmentConsultationNotesForId)
    getAppointmentConsultationNotesForId(ctx: StateContext<CustomerStateModel>, action: CustomerActions.GetAppointmentConsultationNotesForId): Observable<ResponseModel<AppointmentConsultationQuestionsModel>> {
        return this._appointmentService.getAppointmentConsultationQuestions(action.id)
            .pipe(
                tap((response: ResponseModel<AppointmentConsultationQuestionsModel>) => {
                    ctx.patchState({
                        appointmentConsultationQuestions: response.content
                    })
                })
            );
    }

    @Action(CustomerActions.GetAppointmentConsultationNotes)
    getAppointmentConsultationNotes(ctx: StateContext<CustomerStateModel>, action: CustomerActions.GetAppointmentConsultationNotes): Observable<ResponseModel<AppointmentConsultationQuestionsModel>> {
        const state = ctx.getState();

        if (!state.nearestAppointment) {
            return of();
        }

        return this._appointmentService.getAppointmentConsultationQuestions(state.nearestAppointment.appointmentId)
            .pipe(
                tap((response: ResponseModel<AppointmentConsultationQuestionsModel>) => {
                    ctx.patchState({
                        appointmentConsultationQuestions: response.content
                    })
                })
            );
    }

    @Action(CustomerActions.SendCustomerBookingConfirmation)
    sendCustomerBookingConfirmation(ctx: StateContext<CustomerStateModel>, action: CustomerActions.SendCustomerBookingConfirmation): Observable<ResponseModel<void>> {
        return this._notificationService.sendBookingConfirmation(action.model)
    }

    @Action(CustomerActions.SendCustomerCustomMessage)
    sendCustomerCustomMessage(ctx: StateContext<CustomerStateModel>, action: CustomerActions.SendCustomerCustomMessage): Observable<ResponseModel<void>> {
        return this._notificationService.sendCustomMessage(action.model)
    }

    @Action(CustomerActions.SendCustomersCustomMessage)
    sendCustomersCustomMessage(ctx: StateContext<CustomerStateModel>, action: CustomerActions.SendCustomersCustomMessage): Observable<ResponseModel<FailedNotificationModel[]>> {
        return this._notificationService.sendCustomMessages(action.model)
        .pipe(
            tap((response: ResponseModel<FailedNotificationModel[]>) => {
                if(response.content.length > 0){
                    response.content.forEach(item => {
                        this._toastrMessages.warning(`Send message failed for Booking: ${item.bookingId}, notification type: ${item.notificationType}, message: ${item.exceptionMessage} `);
                    });
                }
            })
        )
    }
    @Action(CustomerActions.AddCustomerNotes)
    addCustomerNotes(ctx: StateContext<CustomerStateModel>, action: CustomerActions.AddCustomerNotes): Observable<ResponseModel<void>> {
        const state = ctx.getState();
        if (!state.selectedCustomerId) {
            return of();
        }
        return this._customerService.addCustomerNotes(state.selectedCustomerId, action.model);
    }



    @Action(CustomerActions.UpdateCustomerNotes)
    updateCustomerNotes(ctx: StateContext<CustomerStateModel>, action: CustomerActions.UpdateCustomerNotes): Observable<ResponseModel<void>> {
        return this._customerService.updateCustomerNotes(action.id, action.model);
    }
    @Action(CustomerActions.GetAppointmentNotes)
    getAppointmentNotes(ctx: StateContext<CustomerStateModel>, action: CustomerActions.GetAppointmentNotes): Observable<ResponseModel<AppointmentNoteModel[]>> {
        const state = ctx.getState();

        if (!state.nearestAppointment) {
            return of();
        }

        return this._appointmentService.getAppointmentNotes(state.nearestAppointment.appointmentId)
            .pipe(
                tap((response: ResponseModel<AppointmentNoteModel[]>) => {
                    ctx.patchState({
                        appointmentNotes: response.content
                    })
                })
            );
    }

    @Action(CustomerActions.GetAppointmentNotesForId)
    getAppointmentNotesForId(ctx: StateContext<CustomerStateModel>, action: CustomerActions.GetAppointmentNotesForId): Observable<ResponseModel<AppointmentNoteModel[]>> {
        
        return this._appointmentService.getAppointmentNotes(action.id)
            .pipe(
                tap((response: ResponseModel<AppointmentNoteModel[]>) => {
                    ctx.patchState({
                        appointmentNotes: response.content
                    })
                })
            );
    }

    @Action(CustomerActions.AddAppointmentNote)
    addAppointmentNote(ctx: StateContext<CustomerStateModel>, action: CustomerActions.AddAppointmentNote): Observable<ResponseModel<void>> {
        const state = ctx.getState();
        if (!state.nearestAppointment) {
            return of();
        }
        return this._appointmentService.createAppointmentNote(state.nearestAppointment.appointmentId, action.model);
    }
    @Action(CustomerActions.AddAppointmentNoteForId)
    addAppointmentNoteForId(ctx: StateContext<CustomerStateModel>, action: CustomerActions.AddAppointmentNoteForId): Observable<ResponseModel<void>> {
        
        return this._appointmentService.createAppointmentNote(action.id, action.model);
    }
    @Action(CustomerActions.UpdateAppointmentNote)
    updateAppointmentNote(ctx: StateContext<CustomerStateModel>, action: CustomerActions.UpdateAppointmentNote): Observable<ResponseModel<void>> {
        const state = ctx.getState();
        if (!state.nearestAppointment) {
            return of();
        }
        return this._appointmentService.updateAppointmentNote(state.nearestAppointment.appointmentId, action.model);
    }
    @Action(CustomerActions.UpdateAppointmentNoteForId)
    updateAppointmentNoteForId(ctx: StateContext<CustomerStateModel>, action: CustomerActions.UpdateAppointmentNoteForId): Observable<ResponseModel<void>> {
        
        return this._appointmentService.updateAppointmentNote(action.id, action.model);
    }
    @Action(CustomerActions.DeleteAppointmentNote)
    deleteAppointmentNote(ctx: StateContext<CustomerStateModel>, action: CustomerActions.DeleteAppointmentNote): Observable<ResponseModel<void>> {
        const state = ctx.getState();
        if (!state.nearestAppointment) {
            return of();
        }
        return this._appointmentService.deleteAppointmentNote(state.nearestAppointment.appointmentId, action.id);
    }
    @Action(CustomerActions.DeleteAppointmentNoteForId)
    deleteAppointmentNoteForId(ctx: StateContext<CustomerStateModel>, action: CustomerActions.DeleteAppointmentNoteForId): Observable<ResponseModel<void>> {        
        return this._appointmentService.deleteAppointmentNote(action.appointmentId, action.id);
    }

    @Action(CustomerActions.UpdateAppointmentAttendedStatus)
    updateAppointmentAttendedStatus(ctx: StateContext<CustomerStateModel>, action: CustomerActions.UpdateAppointmentAttendedStatus): Observable<ResponseModel<void>> {
        const state = ctx.getState();
        if (!state.nearestAppointment) {
            return of();
        }
        return this._appointmentService.updateAppointmentAttendedStatus(state.nearestAppointment.appointmentId, action.id);
    }

    @Action(CustomerActions.UpdateNearestAppointment)
    updateNearestAppointment(ctx: StateContext<CustomerStateModel>, action: CustomerActions.UpdateNearestAppointment): Observable<ResponseModel<void>> {
        return this._appointmentService.updateNearestAppointment(action.model);
    }

    @Action(CustomerActions.CancelCustomerAppointment)
    cancelCustomerAppointment(ctx: StateContext<CustomerStateModel>, action: CustomerActions.CancelCustomerAppointment): Observable<ResponseModel<void>> {
        const state = ctx.getState();
        if (!state.selectedBookingId || !state.nearestAppointment) {
            return of();
        }
        return this._appointmentService.cancelAppointment(state.nearestAppointment.appointmentId, state.selectedBookingId, action.refund);
    }

    @Action(CustomerActions.CancelCustomerAppointmentForId)
    cancelCustomerAppointmentForId(ctx: StateContext<CustomerStateModel>, action: CustomerActions.CancelCustomerAppointmentForId): Observable<ResponseModel<void>> {
        
        return this._appointmentService.cancelAppointment(action.appointmentId, action.selectedBookingId, action.refund);
    }

    @Action(CustomerActions.ReArrangeCustomerAppointment)
    reArrangeCustomerAppointment(ctx: StateContext<CustomerStateModel>, action: CustomerActions.ReArrangeCustomerAppointment): Observable<ResponseModel<void>> {
        const state = ctx.getState();
        if (!state.reArrangeAppointment || !state.selectedBookingId) {
            return of();
        }
        state.reArrangeAppointment.appointmentId = state.nearestAppointment?.appointmentId;
        state.reArrangeAppointment.bookingId = state.selectedBookingId;
        
        return this._appointmentService.reArrangeAppointment(state.reArrangeAppointment);
    }


    @Action(CustomerActions.DeleteCustomerNotes)
    deleteCustomerNotes(ctx: StateContext<CustomerStateModel>, action: CustomerActions.DeleteCustomerNotes): Observable<ResponseModel<void>> {
        return this._customerService.deleteCustomerNotes(action.id);
    }

    @Action(CustomerActions.ClearCustomerDetails)
    clearCustomerDetails(ctx: StateContext<CustomerStateModel>, action: CustomerActions.ClearCustomerDetails): void {
        ctx.patchState({
            selectedCustomerId: null,
            selectedBookingId: null,
            customerNotes: null,
            nearestAppointment: null,
            customerNoteTypes: null,
            appointments: null,
            history: null,
            reArrangeAppointment: null,
            reArrangeAppointmentTypeId: null,
            reArrangeLocalAuthority: null,
            appointmentNotes: null,
            appointmentConsultationQuestions: null
        })
    }

}