import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProtectedEndpointsConstant } from '../../constants/protected-endpoints.constant';
import { ResponseModel } from '../../models/base/response-model';
import { Observable } from 'rxjs';
import { BookingIdModel } from '../../models/booking/booking-id.model';
import { AbandonBookingModel } from '../../models/booking/abandon-booking.model';
import { AbandonBookingReasonModel } from '../../models/booking/abandon-booking-reason.model';
import { AddNoteAbandonBookingModel } from '../../models/booking/add-note-abandon-booking.model';
import { VehicleTypeModel } from '../../models/booking/vehicle-type.model';
import { AddBookingDetailsModel } from '../../models/booking/add-booking-details.model';
import { AddAbandonBookingModel } from '../../models/booking/add-abandon-booking.model';
import { EditAbandonBookingModel } from '../../models/booking/edit-abandon-booking.model';
import { EditVehicleTypeModel } from '../../models/booking/edit-vehicle-type.model';
import { AddVehicleTypeModel } from '../../models/booking/add-vehicle-type.model';
import { BookingStatusType } from '../../enums/booking-status.type';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private readonly _http: HttpClient) { }

  create(voucherId: number | null): Observable<ResponseModel<BookingIdModel>> {
    return this._http.post<ResponseModel<BookingIdModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BOOKING}`, { voucherId });
  }
  addDetails(model: AddBookingDetailsModel): Observable<ResponseModel<BookingIdModel>> {
    return this._http.post<ResponseModel<BookingIdModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BOOKING}/details`, model);
  }

  getAbandonReasons(isFullNeeded: boolean): Observable<ResponseModel<AbandonBookingReasonModel[]>> {
    return this._http.get<ResponseModel<AbandonBookingReasonModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BOOKING}/abandon-reason?isFullNeeded=${isFullNeeded}`);
  }

  abandonBooking(model: AbandonBookingModel): Observable<ResponseModel<BookingIdModel>> {
    return this._http.post<ResponseModel<BookingIdModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BOOKING}/abandon`, model);
  }

  addNoteAbandonBooking(model: AddNoteAbandonBookingModel): Observable<ResponseModel<BookingIdModel>> {
    return this._http.post<ResponseModel<BookingIdModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BOOKING}/abandon/taxi-medical`, model);
  }

  getVehicleTypes(isFullNeeded: boolean): Observable<ResponseModel<VehicleTypeModel[]>> {
    return this._http.get<ResponseModel<VehicleTypeModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BOOKING}/vehicle-types?isFullNeeded=${isFullNeeded}`);
  }
  
  addAbandonBookingReason(model: AddAbandonBookingModel): Observable<ResponseModel<BookingIdModel>> {
    return this._http.post<ResponseModel<BookingIdModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BOOKING}/abandon-reason`, model);
  }

  editAbandonBookingReason(id: number, model: EditAbandonBookingModel): Observable<ResponseModel<BookingIdModel>> {
    return this._http.put<ResponseModel<BookingIdModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BOOKING}/abandon-reason/${id}`, model);
  }

  addVehicleType(model: AddVehicleTypeModel): Observable<ResponseModel<BookingIdModel>> {
    return this._http.post<ResponseModel<BookingIdModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BOOKING}/vehicle-types`, model);
  }

  editVehicleType(id: number, model: EditVehicleTypeModel): Observable<ResponseModel<BookingIdModel>> {
    return this._http.put<ResponseModel<BookingIdModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BOOKING}/vehicle-types/${id}`, model);
  }

  updateBookingStatus(id: number, bookingStatusType: BookingStatusType): Observable<ResponseModel<void>> {
    return this._http.patch<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BOOKING}/${id}/update/booking-status?bookingStatusType=${bookingStatusType}`, null);
  }

  confirm(bookingId: number | null): Observable<ResponseModel<void>> {
    return this._http.put<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BOOKING}/confirm`, { bookingId });
  }
}
