import { BusinessAccountAddVoucherCodeModel } from "../../models/business-accounts/business-account-add-voucher-code.model";
import { BusinessAccountAddAppointmentTypeModel } from "../../models/business-accounts/business-account-appointment-type-upsert.model";
import { BusinessAccountEditVoucherCodeModel } from "../../models/business-accounts/business-account-edit-voucher-code.model";
import { BusinessAccountVoidReferralCodesModel } from "../../models/business-accounts/business-account-void-referal-codes.model";

export namespace CommonActions {
    export class GetNoteImportanceList {
        static readonly type = '[Common] GetNoteImportanceList';
    }

    export class GetAttendedStatuses {
        static readonly type = '[Common] GetAttendedStatuses';
    }

    export class GetRegions {
        static readonly type = '[Common] GetRegions';
    }

    export class GetBusinessTypes {
        static readonly type = '[Common] GetBusinessTypes';
    }

    export class GetBookingMethods {
        static readonly type = '[Common] GetBookingMethods';
    }

    export class GetBusinessAccountPackageTypes {
        static readonly type = '[Common] GetBusinessAccountPackageTypes';
    }

    export class GetBusinessAccountClosureReasons {
        static readonly type = '[Common] GetBusinessAccountClosureReasons';
    }

    export class GetCompanyIntroSources {
        static readonly type = '[Common] GetCompanyIntroSources';
    }

    export class GetCreditLimits {
        static readonly type = '[Common] GetCreditLimits';
    }

    export class GetNumberOfDriverTypes {
        static readonly type = '[Common] GetNumberOfDriverTypes';
    }
    
    export class GetPaymentTerms {
        static readonly type = '[Common] GetPaymentTerms';
    }

    export class GetVoucherTypes {
        static readonly type = '[Common] GetVoucherTypes';
    }

    export class GetBusinessAccounts {
        static readonly type = '[Common] GetBusinessAccounts';
        constructor(public filter: string) { }
    }

    export class GetAppointmentTypesByFilter {
        static readonly type = '[Common] GetAppointmentTypesByFilter';
        constructor(public filter: string) { }
    }

    export class GetBusinessAccountAppointmentTypes {
        static readonly type = '[Common] GetBusinessAccountAppointmentTypes';
        constructor(public id: number) { }
    }

    export class GetBusinessAccountAccountUsers {
        static readonly type = '[Common] GetBusinessAccountAccountUsers';
        constructor(public name: string) { }
    }

    export class GetBusinessAccountReferralCodes {
        static readonly type = '[Common] GetBusinessAccountReferralCodes';
        constructor(public id: number) { }
    }

    export class GetBusinessAccountVouchers {
        static readonly type = '[Common] GetBusinessAccountVouchers';
        constructor(public id: number) { }
    }

    export class GetUserRoles {
        static readonly type = '[Common] GetUserRoles';
    }

    export class GetPractitionerLevels {
        static readonly type = '[Common] GetPractitionerLevels';
    }

    export class AddBusinessAccountAppointmentTypes {
        static readonly type = '[Common] AddBusinessAccountAppointmentTypes';
        constructor(public model: BusinessAccountAddAppointmentTypeModel) { }
    }

    export class AddBusinessAccountUser {
        static readonly type = '[Common] AddBusinessAccountUser';
        constructor(public email: string, public firstName: string, public lastName: string, public id: number) { }
    }
    export class AddBusinessAccountVoucher {
        static readonly type = '[Common] AddBusinessAccountVoucher';
        constructor(public model: BusinessAccountAddVoucherCodeModel) { }
    }

    export class GenerateBusinessAccountReferralCodes {
        static readonly type = '[Common] GenerateBusinessAccountReferralCodes';
        constructor(public id: number, public count: number, public expireDate: string | null, public validFromDate: string) { }
    }

    export class EditBusinessAccountAppointmentTypes {
        static readonly type = '[Common] EditBusinessAccountAppointmentTypes';
        constructor(public id: number, public cost: number) { }
    }

    export class UpdateBusinessAccountUser {
        static readonly type = '[Common] UpdateBusinessAccountUser';
        constructor(public id: number, public firstName: string, public lastName: string) { }
    }

    export class UpdateBusinessAccountVoucher {
        static readonly type = '[Common] UpdateBusinessAccountVoucher';
        constructor(public voucher: BusinessAccountEditVoucherCodeModel) { }
    }

    export class BlockBusinessAccountUser {
        static readonly type = '[Common] BlockBusinessAccountUser';
        constructor(public id: number) { }
    }

    export class RevertBusinessAccountAppointmentTypes {
        static readonly type = '[Common] RevertBusinessAccountAppointmentTypes';
        constructor(public id: number) { }
    }

    export class VoidBusinessAccountReferralCodes {
        static readonly type = '[Common] VoidBusinessAccountReferralCodes';
        constructor(public id: number, public model: BusinessAccountVoidReferralCodesModel) { }
    }
    
   export class VoidBusinessAccountVoucher {
        static readonly type = '[Common] VoidBusinessAccountVoucher';
        constructor(public id: number) { }
    }

    export class DeleteBusinessAccountAppointmentTypes {
        static readonly type = '[Common] DeleteBusinessAccountAppointmentTypes';
        constructor(public id: number) { }
    }

    export class ClearBusinessAccounts {
        static readonly type = '[Common] ClearBusinessAccounts';
    }

    export class ClearReferralsUsersAndVouchers {
        static readonly type = '[Common] ClearReferralsUsersAndVouchers';
    }

    export class GetPriceLists {
        static readonly type = '[Common] GetPriceLists';
    }
}