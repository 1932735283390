import { MatDateFormats } from "@angular/material/core";

export const CUSTOM_DATE_MONTH_FORMATS: MatDateFormats = {
    parse: {
      dateInput: 'MM/YYYY',
    },
    display: {
      dateInput: 'MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };

  export const CUSTOM_DATE_LINE_FORMATS: MatDateFormats = {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };
  
  export const CUSTOM_DATE_FULL_MONTH_FORMATS: MatDateFormats = {
    parse: {
      dateInput: 'DD MMMM YYYY',
    },
    display: {
      dateInput: 'DD MMMM YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };
  