<div class="insufficient-permissions-container">

    <div class="insufficient-permissions-body">
        <div class="insufficient-permissions-message">
            <div class="insufficient-permissions-message-header">
                You are currently logged in, but your account does not have permissions for this portal, please log in with another account.
            </div>
            <div class="insufficient-permissions-message-sub-header">
                Please log in with another account.
            </div>
            <button mat-raised-button (click)="onLogOutClick()"  class="insufficient-permissions-message-button">
                Log out
            </button>
        </div>
    </div>
</div>