import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppointmentSummaryModel } from "../../models/appointment/appointment-summary.model";
import { ProtectedEndpointsConstant } from "../../constants/protected-endpoints.constant";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { ResponseModel } from "../../models/base/response-model";
import { AddAppointmentModel } from "../../models/appointment/add-appointment.model";
import { AppointmentStatusType } from "../../enums/appointment-status.type";
import { IdResponseModel } from "../../models/base/id-response-model";
import { ReArrangeAppointmentModel } from "../../models/appointment/re-arrange-appointment.model";
import { AppointmentNoteModel } from "../../models/appointment/appointment-note.model";
import { AddAppointmentNoteModel } from "../../models/appointment/add-appointment-note.model";
import { UpdateAppointmentNoteModel } from "../../models/appointment/update-appointment-note.model";
import { AppointmentConsultationQuestionsModel } from "../../models/appointment/appointment-consultation-questions.model";
import { UpdateNearestAppointmentModel } from "../../models/appointment/update-nearest-appointment.model";

@Injectable({
    providedIn: 'root'
})
export class AppointmentService {
    constructor(private readonly _http: HttpClient) { }

    getAppointmentSummary(id: number): Observable<ResponseModel<AppointmentSummaryModel>> {
        return this._http.get<ResponseModel<AppointmentSummaryModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT}/${id}/summary`);
    }

    getAppointmentNotes(id: number): Observable<ResponseModel<AppointmentNoteModel[]>> {
        return this._http.get<ResponseModel<AppointmentNoteModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT}/${id}/notes`);
    }

    getAppointmentConsultationQuestions(id: number): Observable<ResponseModel<AppointmentConsultationQuestionsModel>> {
        return this._http.get<ResponseModel<AppointmentConsultationQuestionsModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT}/${id}/get-consultation-questions`);
    }

    applyVoucherForAppointment(id: number, businessAccountId: number | null, code: string, bookingId: number): Observable<ResponseModel<void>> {
        return this._http.get<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT}/${id}/apply-voucher?businessAccountId=${businessAccountId}&code=${code}&bookingId=${bookingId}`);
    }

    createAppointment(model: AddAppointmentModel): Observable<ResponseModel<IdResponseModel>> {
        return this._http.post<ResponseModel<IdResponseModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT}`, model);
    }

    createAppointmentNote(id:number, model: AddAppointmentNoteModel): Observable<ResponseModel<void>> {
        return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT}/${id}/note`, model);
    }
    
    updateAppointmentStatus(id: number, appointmentStatusType: AppointmentStatusType): Observable<ResponseModel<void>> {
        return this._http.patch<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT}/update/appointment-status?appointmentId=${id}&appointmentStatusType=${appointmentStatusType}`, null);
    }

    updateAppointmentNote(id: number, model: UpdateAppointmentNoteModel): Observable<ResponseModel<void>> {
        return this._http.put<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT}/${id}/note`, model);
    }

    updateAppointmentAttendedStatus(id: number, attendedStatusTypeId: number): Observable<ResponseModel<void>> {
        return this._http.put<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT}/${id}/update/attended-status?attendedStatusTypeId=${attendedStatusTypeId}`, null);
    }

    updateNearestAppointment(model: UpdateNearestAppointmentModel): Observable<ResponseModel<void>> {
        return this._http.put<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT}`, model);
    }
    
    reArrangeAppointment(model: ReArrangeAppointmentModel): Observable<ResponseModel<void>> {
        return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT}/${model.appointmentId}/re-arrange`, model);
    }

    cancelAppointment(appointmentId: number, bookingId: number, refund: boolean): Observable<ResponseModel<void>> {
        return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT}/${appointmentId}/cancel`, { bookingId: bookingId, isRefundRequested: refund });
    }

    deleteAppointmentNote(appointmentId: number, noteId: number): Observable<ResponseModel<void>> {
        return this._http.delete<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT}/${appointmentId}/note?appointmentNoteId=${noteId}`);
    }
}