import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'generic-question-boolean-response-dialog',
  templateUrl: './generic-question-boolean-response-dialog.component.html',
  styleUrls: ['./generic-question-boolean-response-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericQuestionBooleanResponseDialogComponent {

  model!: GenericQuestionBooleanInputDataModel;
  constructor(@Inject(MAT_DIALOG_DATA) private readonly _dialogData: GenericQuestionBooleanInputDataModel, 
  private _dialogRef: MatDialogRef<GenericQuestionBooleanInputDataModel>) {
    this.model = _dialogData;
  }

  close(): void {
    this._dialogRef.close();
  }
  onButtonConfirmClick(): void {
    this._dialogRef.close(true);
  }

  onButtonCancelClick(): void {
    this._dialogRef.close(false);
  }
}

export interface GenericQuestionBooleanInputDataModel {
  title: string;
  text: string;
  buttonCancelText: string;
  buttonConfirmText: string;
}