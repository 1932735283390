import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProtectedEndpointsConstant } from '../../constants/protected-endpoints.constant';
import { ResponseModel } from '../../models/base/response-model';
import { WorldPayHostedUrlModel } from '../../models/world-pay/world-pay-hosted-url.model';
import { Observable } from 'rxjs';
import { DefaultConstant } from '../../constants/default.constant';

@Injectable({
  providedIn: 'root'
})
export class WorldPayService {

  constructor(private readonly _http: HttpClient) { }
  hosted(bookingId: number): Observable<ResponseModel<WorldPayHostedUrlModel>> {
    const baseReturnUrl: string = `${environment.appUrl}${DefaultConstant.BOOKING_SUMMARY_PAGE}`;
    return this._http.get<ResponseModel<WorldPayHostedUrlModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.WORLD_PAY}/moto?bookingId=${bookingId}&baseReturnUrl=${baseReturnUrl}`);
  }
}
