import { Action, Selector, State, StateContext } from "@ngxs/store";
import { BookingStateModel } from "../models/booking-state.model";
import { Injectable } from "@angular/core";
import { BookingActions } from "./booking.actions";
import { BusinessAccountModel } from "../../models/business-accounts/business-account.model";
import { BusinessAccountService } from "../../providers/services/business-account.service";
import { Observable, of, tap } from "rxjs";
import { ResponseModel } from "../../models/base/response-model";
import { BusinessAccountVoucherModel } from "../../models/business-accounts/business-account-voucher.model";
import { BookingService } from "../../providers/services/booking.service";
import { AppointmentService, AppointmentTypeService, ClinicScheduleService, MarketingSurveyOptionService } from "../../providers/services";
import { AppointmentCategoryWithTypes, AppointmentTypeSummary } from "../../models/appointment-type/appointment-types.model";
import { BookingIdModel } from "../../models/booking/booking-id.model";
import { AbandonBookingReasonModel } from "../../models/booking/abandon-booking-reason.model";
import { AppointmentTypeLocalAuthority } from "../../models/appointment-type/appointment-type-local-authorities.model";
import { AppointmentSlotModel, ClinicScheduleModel } from "../../models/clinic-schedule/clinic-schedule.model";
import { AddAppointmentModel } from "../../models/appointment/add-appointment.model";
import { AppointmentStatusType } from "../../enums/appointment-status.type";
import { MarketingSurveyOptionModel } from "../../models/marketing-survey-option/marketing-survey-option.model";
import { AppointmentTypeTCModel } from "../../models/appointment-type/appointment-type-tc.model";
import { BusinessAccountNoteModel } from "../../models/business-accounts/business-account-notes.model";
import * as moment from "moment";
import { AppointmentStateModel } from "src/app/home/pages/booking/pages/appointment/appointment.component";
import { BusinessAccountAdditionalDetailsModel } from "../../models/business-accounts/business-account-additional-details.model";
import { BookingStatusType } from "../../enums/booking-status.type";
import { AddressInfo } from "../../directives/google-address.directive";
import { AppointmentSummaryModel } from "../../models/appointment/appointment-summary.model";

@State<BookingStateModel>({
    name: 'booking',
    defaults: {
        bookingStepUrl: null,
        appointmentType: null,
        businessAccount: null,
        voucher: null,
        bookingId: null,
        bookingStatus: null,
        localAuthority: null,
        appointmentId: null,
        appointmentSummary: null,
        hideClinicSchedulesShowMoreButton: null,
        appointmentCustomerId: null,
        appointmentSearchDate: null,
        appointmentPageState: null,
        businessAccountAdditionalDetails: null,
        
        appointments: null,
        localAuthorities: null,
        businessAccounts: null,
        businessCompanyNotes: null,
        favouriteAppointments: null,
        abandonBookingReasons: null,
        clinicSchedules: null,
        marketingSurveyOptions: null,
        tcAgreements: null,

        addressInfo: null,
        bookAppointmentFormValues: null,
        similarCustomerID: null,
        reArrangedBookingID: null
    }
})

@Injectable()
export class BookingState {
    constructor(
        private readonly _businessAccountService: BusinessAccountService,
        private readonly _bookingService: BookingService,
        private readonly _appointmentTypeService: AppointmentTypeService,
        private readonly _clinicScheduleService: ClinicScheduleService,
        private readonly _appointmentService: AppointmentService,
        private readonly _marketingSurveyOptionService: MarketingSurveyOptionService
    ) { }

    @Selector()
    static getBusinessAccounts(state: BookingStateModel): BusinessAccountModel[] | null {
        return state.businessAccounts;
    }

    @Selector()
    static getBusinessAccount(state: BookingStateModel): BusinessAccountModel | null {
        return state.businessAccount;
    }

    @Selector()
    static getBusinessCompanyNotes(state: BookingStateModel): string | null {
        return state.businessCompanyNotes?.map(note => note.noteText).join() ?? '';
    }

    @Selector()
    static isVoucherExist(state: BookingStateModel): boolean {
        return !!state.voucher?.code;
    }

    @Selector()
    static getVoucher(state: BookingStateModel): BusinessAccountVoucherModel | null {
        return state.voucher ? state.voucher : null;
    }

    @Selector()
    static getBookingId(state: BookingStateModel): number | null {
        return state.bookingId;
    }

    @Selector()
    static getAppointmentType(state: BookingStateModel): AppointmentTypeSummary | null {
        return state.appointmentType;
    }

    @Selector()
    static getAppointmentId(state: BookingStateModel): number | null {
        return state.appointmentId;
    }

    @Selector()
    static getAppointments(state: BookingStateModel): AppointmentTypeSummary[] | null {
        return state.appointments;
    }

    @Selector()
    static getAbandonBookingReasons(state: BookingStateModel): AbandonBookingReasonModel[] | null {
        return state.abandonBookingReasons;
    }

    @Selector()
    static getFavouriteAppointments(state: BookingStateModel): AppointmentCategoryWithTypes[] | null {
        return state.favouriteAppointments;
    }

    @Selector()
    static getLocalAuthorities(state: BookingStateModel): AppointmentTypeLocalAuthority[] | null {
        return state.localAuthorities;
    }

    @Selector()
    static getLocalAuthority(state: BookingStateModel): AppointmentTypeLocalAuthority | null {
        return state.localAuthority;
    }

    @Selector()
    static getClinicSchedules(state: BookingStateModel): ClinicScheduleModel[] | null {
        return state.clinicSchedules;
    }

    @Selector()
    static getClinicSchedulesToSkip(state: BookingStateModel): number | null {
        return state.clinicSchedules ? state.clinicSchedules.length : null;
    }

    @Selector()
    static getBookingStepUrl(state: BookingStateModel): string | null {
        return state.bookingStepUrl;
    }
    @Selector()
    static getHideClinicSchedulesShowMoreButton(state: BookingStateModel): boolean | null {
        return state.hideClinicSchedulesShowMoreButton;
    }

    @Selector()
    static getMarketingSurveyOptions(state: BookingStateModel): MarketingSurveyOptionModel[] | null {
        return state.marketingSurveyOptions;
    }

    @Selector()
    static getTCAgreements(state: BookingStateModel): AppointmentTypeTCModel[] | null {
        return state.tcAgreements;
    }

    @Selector()
    static isPaymentMadeStep(state: BookingStateModel): boolean | null {
        return state.bookingStepUrl === 'home/booking/book-summary';
    }

    @Selector()
    static getAppointmentCustomerId(state: BookingStateModel): number | null {
        return state.appointmentCustomerId;
    }

    @Selector()
    static getAppointmentSearchDate(state: BookingStateModel): string | null {
        return state.appointmentSearchDate;
    }

    @Selector()
    static getAppointmentPageState(state: BookingStateModel): AppointmentStateModel | null {
        return state.appointmentPageState;
    }

    @Selector()
    static getBusinessAccountAdditionalDetails(state: BookingStateModel): BusinessAccountAdditionalDetailsModel  | null {
        return state.businessAccountAdditionalDetails;
    }

    @Selector()
    static getAddressInfo(state: BookingStateModel): AddressInfo | null {
        return state.addressInfo;
    }

    @Selector()
    static getSimilarCustomerID(state: BookingStateModel): number | null {
        return state.similarCustomerID;
    }

    @Selector()
    static getAppointmentSummary(state: BookingStateModel): AppointmentSummaryModel | null {
        return state.appointmentSummary
    }

    @Action(BookingActions.SetAppointmentSummary)
    setAppointmentSummary(ctx: StateContext<BookingStateModel>, action: BookingActions.SetAppointmentSummary): void {
        ctx.patchState({
            appointmentSummary: action.summary
        })
    }

    @Action(BookingActions.SetSimiliarCustomerID)
    setSimiliarCustomerID(ctx: StateContext<BookingStateModel>, action: BookingActions.SetSimiliarCustomerID): void {
        ctx.patchState({
            similarCustomerID: action.id
        })
    }

    @Action(BookingActions.SetAddressInfo)
    setAddressInfo(ctx: StateContext<BookingStateModel>, action: BookingActions.SetAddressInfo): void {
        ctx.patchState({
            addressInfo: action.addressInfo
        })
    }

    @Action(BookingActions.SetBookingStepUrl)
    setBookingStepUrl(ctx: StateContext<BookingStateModel>, action: BookingActions.SetBookingStepUrl): void {
        ctx.patchState({
            bookingStepUrl: action.url
        })
    }

    @Action(BookingActions.SetBusinessAccount)
    setBusinessAccount(ctx: StateContext<BookingStateModel>, action: BookingActions.SetBusinessAccount): void {
        ctx.patchState({
            businessAccount: action.model
        })
    }

    @Action(BookingActions.SetAppointmentType)
    setAppointmentTypeId(ctx: StateContext<BookingStateModel>, action: BookingActions.SetAppointmentType): void {
        ctx.patchState({
            appointmentType: action.model
        })
    }

    @Action(BookingActions.SetAppointmentTypeLocalAuthority)
    setAppointmentTypeLocalAuthority(ctx: StateContext<BookingStateModel>, action: BookingActions.SetAppointmentTypeLocalAuthority): void {
        ctx.patchState({
            localAuthority: action.model
        })
    }

    @Action(BookingActions.SetAppointmentSlotIsSelected)
    setAppointmentSlotIsSelected(ctx: StateContext<BookingStateModel>, action: BookingActions.SetAppointmentSlotIsSelected): ClinicScheduleModel[] | null {
        const state = ctx.getState();

        if(action.selectFirst) {
            state.clinicSchedules!.forEach(item => item.isSelected = null);
            state.clinicSchedules![0].isSelected = true;
            return state.clinicSchedules;
        }

        const slotIndex = state.clinicSchedules!.findIndex(item => item.venue.id === action.venueId);
        if (slotIndex !== -1) {
            if (state.clinicSchedules![slotIndex].isSelected) {
                state.clinicSchedules![slotIndex].isSelected = !state.clinicSchedules![slotIndex].isSelected;
                return state.clinicSchedules;
            }
            state.clinicSchedules!.forEach(item => item.isSelected = null);
            state.clinicSchedules![slotIndex].isSelected = action.isSelected;
        }
        return state.clinicSchedules;
    }

    @Action(BookingActions.SetAllAppointmentSlotUnselected)
    setAllAppointmentSlotUnselected(ctx: StateContext<BookingStateModel>, action: BookingActions.SetAllAppointmentSlotUnselected): void {
        const state = ctx.getState();
        state.clinicSchedules!.forEach(item => item.isSelected = null);

        ctx.patchState({
            clinicSchedules: state.clinicSchedules
        });
    }

    @Action(BookingActions.SetAppointmentSlotIsLoading)
    setAppointmentSlotIsLoading(ctx: StateContext<BookingStateModel>, action: BookingActions.SetAppointmentSlotIsLoading): void {
        const state = ctx.getState();
        const slotIndex = state.clinicSchedules!.findIndex(item => item.venue.id === action.venueId);
        if (slotIndex !== -1) {
            state.clinicSchedules![slotIndex].isLoading = action.isLoading;
        }
    }

    @Action(BookingActions.SetClinicSchedules)
    setClinicSchedules(ctx: StateContext<BookingStateModel>, action: BookingActions.SetClinicSchedules): void {
        const state = ctx.getState();
        state.hideClinicSchedulesShowMoreButton = action.schedules.length === 0
        if (!state.clinicSchedules) {
            const schedules = [...action.schedules].sort((a: ClinicScheduleModel, b: ClinicScheduleModel) => {
                if (a.distanceMatrix && b.distanceMatrix) {
                    return a.distanceMatrix.distance.value - b.distanceMatrix.distance.value;
                }
                return 0;
            });
            schedules.forEach((item, index) => {
                item.index = index;
            });

            ctx.patchState({
                clinicSchedules: schedules
            });
            return;
        }

        const schedules = [...state.clinicSchedules, ...action.schedules].sort((a: ClinicScheduleModel, b: ClinicScheduleModel) => {
            if (a.distanceMatrix && b.distanceMatrix) {
                return a.distanceMatrix.distance.value - b.distanceMatrix.distance.value;
            }
            return 0;
        });
        schedules.forEach((item, index) => {
            item.index = index;
        });

        ctx.patchState({
            clinicSchedules: schedules
        });
    }

    @Action(BookingActions.SetAppointmentCustomerId)
    setAppointmentCustomerId(ctx: StateContext<BookingStateModel>, action: BookingActions.SetAppointmentCustomerId): void {
        ctx.patchState({
            businessAccounts: null,
            appointmentCustomerId: action.id
        });
    }

    @Action(BookingActions.SetAppointmentSearchDate)
    setAppointmentSearchDate(ctx: StateContext<BookingStateModel>, action: BookingActions.SetAppointmentSearchDate): void {
      ctx.patchState({
            appointmentSearchDate: action.date
        });
    }

    @Action(BookingActions.SetAppointmentPageState)
    setAppointmentPageState(ctx: StateContext<BookingStateModel>, action: BookingActions.SetAppointmentPageState): void {
      ctx.patchState({
            appointmentPageState: action.state
        });
    }


    @Action(BookingActions.GetVoucher)
    getVoucher(ctx: StateContext<BookingStateModel>, action: BookingActions.GetVoucher): Observable<ResponseModel<BusinessAccountVoucherModel>> {
        ctx.patchState({
            voucher: null
        });
        const state = ctx.getState();

        return this._businessAccountService.getVoucherByCode(action.code, action.businessAccountId, state.appointmentType?.id ?? null)
            .pipe(
                tap((response: ResponseModel<BusinessAccountVoucherModel>) => {
                    ctx.patchState({
                        voucher: response.content
                    })
                })
            )
    }

    @Action(BookingActions.GetBusinessAccounts)
    getBusinessAccounts(ctx: StateContext<BookingStateModel>, action: BookingActions.GetBusinessAccounts): Observable<ResponseModel<BusinessAccountModel[]>> {
        return this._businessAccountService.getBusinessAccounts(action.filter)
            .pipe(
                tap((response: ResponseModel<BusinessAccountModel[]>) => {
                    ctx.patchState({
                        businessAccounts: response.content
                    })
                })
            )
    }

    @Action(BookingActions.GetBusinessCompanyNotes)
    getBusinessCompanyNotes(ctx: StateContext<BookingStateModel>, action: BookingActions.GetBusinessCompanyNotes): Observable<ResponseModel<BusinessAccountNoteModel[]>> {
        return this._businessAccountService.getBusinessAccountNotes(action.id)
            .pipe(
                tap((response: ResponseModel<BusinessAccountNoteModel[]>) => {
                    ctx.patchState({
                        businessCompanyNotes: response.content
                    })
                })
            )
    }

    @Action(BookingActions.CreateBooking)
    crateBooking(ctx: StateContext<BookingStateModel>, action: BookingActions.CreateBooking): Observable<ResponseModel<BookingIdModel>> {
        return this._bookingService.create(action.voucherId)
            .pipe(
                tap((response: ResponseModel<BookingIdModel>) => {
                    ctx.patchState({
                        bookingId: response.content.id
                    })
                })
            )
    }

    @Action(BookingActions.GetAppointmentTypesByFilter)
    getAppointmentTypesByFilter(ctx: StateContext<BookingStateModel>, action: BookingActions.GetAppointmentTypesByFilter): Observable<ResponseModel<AppointmentTypeSummary[]>> {
        const state = ctx.getState();

        return this._appointmentTypeService.getAppointmentTypesByFilter(action.filter, state.voucher?.code ?? null, state.businessAccount?.id ?? null)
            .pipe(
                tap((response: ResponseModel<AppointmentTypeSummary[]>) => {
                    ctx.patchState({
                        appointments: response.content
                    })
                })
            )
    }

    @Action(BookingActions.GetFavouriteAppointmentTypes)
    getFavouriteAppointmentTypes(ctx: StateContext<BookingStateModel>, action: BookingActions.GetFavouriteAppointmentTypes): Observable<ResponseModel<AppointmentCategoryWithTypes[]>> {
        const state = ctx.getState();
        return this._appointmentTypeService.getFavouriteAppointmentTypes(state.voucher?.code ?? null, state.businessAccount?.id ?? null)
            .pipe(
                tap((response: ResponseModel<AppointmentCategoryWithTypes[]>) => {
                    ctx.patchState({
                        favouriteAppointments: response.content
                    })
                })
            )
    }

    @Action(BookingActions.GetAbandonBookingReasons)
    getAbandonBookingReasons(ctx: StateContext<BookingStateModel>, action: BookingActions.GetAbandonBookingReasons): Observable<ResponseModel<AbandonBookingReasonModel[]>> {
        return this._bookingService.getAbandonReasons(action.isFullNeeded)
            .pipe(
                tap((response: ResponseModel<AbandonBookingReasonModel[]>) => {
                    ctx.patchState({
                        abandonBookingReasons: response.content
                    })
                })
            )
    }

    @Action(BookingActions.GetAppointmentTypeLocalAuthority)
    getAppointmentTypeLocalAuthority(ctx: StateContext<BookingStateModel>, action: BookingActions.GetAppointmentTypeLocalAuthority): Observable<ResponseModel<AppointmentTypeLocalAuthority[]>> {
        return this._appointmentTypeService.getLocalAuthority()
            .pipe(
                tap((response: ResponseModel<AppointmentTypeLocalAuthority[]>) => {
                    ctx.patchState({
                        localAuthorities: response.content
                    })
                })
            )
    }

    @Action(BookingActions.GetBusinessAccountAdditionalDetails)
    getBusinessAccountAdditionalDetails(ctx: StateContext<BookingStateModel>, action: BookingActions.GetBusinessAccountAdditionalDetails): Observable<ResponseModel<BusinessAccountAdditionalDetailsModel>> {
        const state = ctx.getState();
        return this._businessAccountService.getAdditionalDetails(state.businessAccount?.id ?? null)
            .pipe(
                tap((response: ResponseModel<BusinessAccountAdditionalDetailsModel>) => {
                    ctx.patchState({
                        businessAccountAdditionalDetails: response.content
                    })
                })
            )
    }

    @Action(BookingActions.GetMarketingSurveyOptions)
    getMarketingSurveyOptions(ctx: StateContext<BookingStateModel>, action: BookingActions.GetMarketingSurveyOptions): Observable<ResponseModel<MarketingSurveyOptionModel[]>> {
        return this._marketingSurveyOptionService.getMarketingSurveyOptions(action.isFullNeeded)
            .pipe(
                tap((response: ResponseModel<MarketingSurveyOptionModel[]>) => {
                    ctx.patchState({
                        marketingSurveyOptions: response.content
                    })
                })
            )
    }

    @Action(BookingActions.GetAppointmentSlots)
    getAppointmentSlots(ctx: StateContext<BookingStateModel>, action: BookingActions.GetAppointmentSlots): Observable<ResponseModel<AppointmentSlotModel[]>> {
        const state = ctx.getState();
        const scheduleIndex = state.clinicSchedules!.findIndex(item => item.venue.id === action.venueId);
        if (scheduleIndex === -1) {
            return of();
        }
        const total: number = state.clinicSchedules![scheduleIndex]!.appointmentSlotsTotal!;
        const itemsPerRequest: number = 3;

        state.clinicSchedules![scheduleIndex].hasPreviousAppointmentSlots = false;
        state.clinicSchedules![scheduleIndex].hasNextAppointmentSlots = false;
        state.clinicSchedules![scheduleIndex].isLoading = true;
        const skip = (action.isBack ? total - itemsPerRequest : total);

         return this._clinicScheduleService.getClinicScheduleAppointmentSlots(action.appointmentTypeId, action.venueId, Math.max(0, skip), 
         state.appointmentSearchDate ? moment(state.appointmentSearchDate).format('YYYY-MM-DD') : null,
         state.localAuthority?.id ?? null
         )
            .pipe(
                tap((response: ResponseModel<AppointmentSlotModel[]>) => {
                    state.clinicSchedules![scheduleIndex].isLoading = false;

                    if (action.isForward) {
                        state.clinicSchedules![scheduleIndex]!.appointmentSlotsTotal = state.clinicSchedules![scheduleIndex]!.appointmentSlotsTotal! + response.content.length;
                        state.clinicSchedules![scheduleIndex].hasNextAppointmentSlots = !response.content.length;
                    }

                    if (action.isBack) {
                        const total: number = state.clinicSchedules![scheduleIndex]!.appointmentSlotsTotal! - state.clinicSchedules![scheduleIndex].appointmentSlots.length;
                        state.clinicSchedules![scheduleIndex]!.appointmentSlotsTotal = total === 0 ? response.content.length : total;
                        state.clinicSchedules![scheduleIndex].hasPreviousAppointmentSlots = total === 0;
                    }

                    if (response.content.length) {
                        state.clinicSchedules![scheduleIndex].appointmentSlots = response.content;
                    }
                })
            )
    }


    @Action(BookingActions.AbandonBooking)
    abandonBooking(ctx: StateContext<BookingStateModel>, action: BookingActions.AbandonBooking): Observable<ResponseModel<BookingIdModel>> {
        return this._bookingService.abandonBooking(action.model)
            .pipe(
                tap((response: ResponseModel<BookingIdModel>) => {
                    if (response.content.id) {
                        this._clearAllStore(ctx);
                    }
                })
            )
    }

    @Action(BookingActions.AddNoteAbandonBooking)
    addNoteAbandonBooking(ctx: StateContext<BookingStateModel>, action: BookingActions.AddNoteAbandonBooking): Observable<ResponseModel<BookingIdModel>> {
        return this._bookingService.addNoteAbandonBooking(action.model)
            .pipe(
                tap((response: ResponseModel<BookingIdModel>) => {
                    if (response.content.id) {
                        this._clearAllStore(ctx);
                    }
                })
            )
    }

    @Action(BookingActions.CreateAppointment)
    createAppointment(ctx: StateContext<BookingStateModel>, action: BookingActions.CreateAppointment): Observable<ResponseModel<BookingIdModel>> {
        const state = ctx.getState();
        const request: AddAppointmentModel = {
            appointmentTypeId: state.appointmentType?.id!,
            clinicScheduleId: action.clinicScheduleId,
            startTime: action.fullTime,
            businessAccountId: state.businessAccount ? state.businessAccount.id : null,
            voucherCode: state.voucher ? state.voucher.code : null,
            bookingId: state.bookingId,
            localAuthorityId: state.localAuthority?.id ?? null,
            reArrangedBookingId: state.reArrangedBookingID ?? null
        }

        return this._appointmentService.createAppointment(request)
            .pipe(
                tap((response: ResponseModel<BookingIdModel>) => {
                    if (response.content.id) {
                        state.appointmentId = response.content.id;
                    }
                })
            )
    }

    @Action(BookingActions.UpdateAppointmentStatus)
    updateAppointmentStatus(ctx: StateContext<BookingStateModel>, action: BookingActions.UpdateAppointmentStatus): Observable<ResponseModel<void>> {
        const state = ctx.getState();
        return this._appointmentService.updateAppointmentStatus(state.appointmentId!, action.status).pipe(
            tap(() => {
                if (action.status === AppointmentStatusType.Cancelled) {
                    state.clinicSchedules?.forEach(schedule => {
                        schedule.isSelected = false;
                    });
                }
            })
        );
    }

    @Action(BookingActions.ConfirmBooking)
    confirmBooking(ctx: StateContext<BookingStateModel>, action: BookingActions.ConfirmBooking): Observable<ResponseModel<void>> {
        const state = ctx.getState();
        return this._bookingService.confirm(state.bookingId!);
    }

    @Action(BookingActions.UpdateBookingStatus)
    updateBookingStatus(ctx: StateContext<BookingStateModel>, action: BookingActions.UpdateBookingStatus): Observable<ResponseModel<void>> {
        const state = ctx.getState();
        ctx.patchState({
            bookingStatus: action.status
        });
        return this._bookingService.updateBookingStatus(state.bookingId!, action.status);
    }

    @Selector()
    static getBookingStatus(state: BookingStateModel): BookingStatusType | null {
        return state.bookingStatus;
    }
@Action(BookingActions.SetBookingStatus)
setBookingStatus(ctx: StateContext<BookingStateModel>, action: BookingActions.SetBookingStatus): void {
    ctx.patchState({
        bookingStatus: action.status
    });
}

    @Action(BookingActions.GetAppointmentTypeTCs)
    getAppointmentTypeTCs(ctx: StateContext<BookingStateModel>, action: BookingActions.GetAppointmentTypeTCs): Observable<ResponseModel<AppointmentTypeTCModel[]>> {
        const state = ctx.getState();
        return this._appointmentTypeService.getTermsAndConditions(state.appointmentType?.id!, state.localAuthority?.id ?? null)
            .pipe(
                tap((response: ResponseModel<AppointmentTypeTCModel[]>) => {
                    ctx.patchState({
                        tcAgreements: response.content
                    })
                })
            );
    }

    @Action(BookingActions.SetAgreeTerms)
    setAgreeTerms(ctx: StateContext<BookingStateModel>, action: BookingActions.SetAgreeTerms): Observable<ResponseModel<void>> {
        const state = ctx.getState();
        return this._appointmentTypeService.setTermsAndConditions(state.bookingId!, action.hasAgreedTermsAndConditions);
    }

    @Action(BookingActions.ClearClinicSchedules)
    clearClinicSchedules(ctx: StateContext<BookingStateModel>, action: BookingActions.ClearClinicSchedules): void {
        ctx.patchState({
            hideClinicSchedulesShowMoreButton: null,
            clinicSchedules: null
        });
    }

    @Action(BookingActions.ClearAll)
    clearAll(ctx: StateContext<BookingStateModel>, action: BookingActions.ClearAll): void {
        this._clearAllStore(ctx);
    }

    @Action(BookingActions.UpdateBookingAppointmentFormDetails)
    updateFormValues(ctx: StateContext<BookingStateModel>, action: BookingActions.UpdateBookingAppointmentFormDetails) {
        ctx.patchState({
            bookAppointmentFormValues: action.formValues
        });
    }
    
    @Action(BookingActions.SetReArrangedBookingId)
    setReArrangedBookingId(ctx: StateContext<BookingStateModel>, action: BookingActions.SetReArrangedBookingId) {
        ctx.patchState({
            reArrangedBookingID: action.id
        })
    }

    @Selector()
    static getBookAppointmentFormValues(state: BookingStateModel): any | null {
        return state.bookAppointmentFormValues;
    }

    private _clearAllStore(ctx: StateContext<BookingStateModel>): void {
        ctx.setState({
            bookingStepUrl: null,
            businessAccounts: null,
            businessCompanyNotes: null,
            businessAccount: null,
            voucher: null,
            bookingId: null,
            bookingStatus: null,
            appointments: null,
            appointmentSummary: null,
            favouriteAppointments: null,
            appointmentType: null,
            abandonBookingReasons: null,
            localAuthorities: null,
            localAuthority: null,
            appointmentId: null,
            clinicSchedules: null,
            hideClinicSchedulesShowMoreButton: null,
            marketingSurveyOptions: null,
            tcAgreements: null,
            appointmentCustomerId: null,
            appointmentSearchDate: null,
            appointmentPageState: null,
            businessAccountAdditionalDetails: null,
            addressInfo: null,
            bookAppointmentFormValues: null,
            similarCustomerID: null,
            reArrangedBookingID: null
        })
    }
}

