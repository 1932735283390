

export class DefaultConstant {
    static readonly EMPTY_URL: string = '/';
    static readonly APP_CONFIG = 'APP_CONFIG';

    static readonly BOOKING_SUMMARY_PAGE: string = 'home/booking/book-summary';
    static readonly BOOKING_APPOINTMENT_PAGE: string = 'home/booking/appointment';
    static readonly BOOKING_BOOK_APPOINTMENT_PAGE: string = 'home/booking/book-appointment';
    static readonly BOOKING_MEDICALS_PAGE: string = 'home/booking/medicals';
    static readonly ALLOWED_BOOKING_PAGES: string[] = 
    [ 
        this.BOOKING_SUMMARY_PAGE, 
        this.BOOKING_APPOINTMENT_PAGE, 
        this.BOOKING_BOOK_APPOINTMENT_PAGE, 
        this.BOOKING_MEDICALS_PAGE
    ];

    static readonly CLINICS_BOOKING_STATUS_PAGE: string = 'clinics/clinics-booking-status';
    static readonly CLINICS_PLANNING_PAGE: string = 'clinics/clinics-planning';
    static readonly CLINICS_SPECIFIC_LOCATION_PAGE: string = 'clinics/specific-location/clinic-schedule';

    static readonly APPOINTMENT_CATEGORY_TAXI_NAME = 'Taxi/Private Hire';
    static readonly APPOINTMENT_CATEGORY_D4_NAME = 'D4';
    static readonly LOGIN_FAILED_URL: string = '/login-failed';
    static readonly CLAIMS_EMAIL_NAME = 'extension_Email';
    static readonly CLAIMS_USER_ID_NAME = 'extension_Id';
    static readonly CLAIMS_ROLES_NAME = 'extension_Roles';
    static readonly CLAIMS_PERMISSIONS_NAME = 'extension_Permissions';

    static readonly MSAL_CONFIG_GMAP_KEY = 'GoogleApiKey';
    static readonly MSAL_CONFIG_AUTHORITY_DOMAIN_KEY = 'MsalAuthorityDomain';
    static readonly MSAL_CONFIG_B2C_POLICY_SIGNIN_KEY = 'MsalB2CPoliciesSignUpSignIn';
    static readonly MSAL_CONFIG_B2C_POLICY_SIGNIN_AUTHORITY_KEY = 'MsalB2CPoliciesSignUpSignInAuthority';
    static readonly MSAL_CONFIG_AUTH_CLIENT_ID_KEY = 'MsalConfigClientId';
    static readonly MSAL_CONFIG_PERMISSION_SCOPES_KEY = 'MsalPermissionScopes'

    static readonly REGEX_EMAIL = `^\\S+@\\S+\\.\\S+$`;
    static readonly REGEX_POST_CODE = `^([A-Z]{1,2}\\d[A-Z\\d]? ?\\d[A-Z]{2}|GIR ?0A{2})$`;
    static readonly REGEX_PHONE = `^(((\\+44\\s?\\d{4}|\\(?0\\d{4}\\)?)\\s?\\d{3}\\s?\\d{3})|((\\+44\\s?\\d{3}|\\(?0\\d{3}\\)?)\\s?\\d{3}\\s?\\d{4})|((\\+44\\s?\\d{2}|\\(?0\\d{2}\\)?)\\s?\\d{4}\\s?\\d{4}))(\\s?\\#(\\d{4}|\\d{3}))?$`;
    static readonly REGEX_TIME = /^([0-1]?\d|2[0-3]):[0-5]\d$/;
}