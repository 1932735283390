import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { AccountActions } from "./account.actions";
import { AccountStateModel } from "../models/account-state.model";

@State<AccountStateModel>({
  name: 'account',
  defaults: {
    id: null,
    role: null,
    previousUserId: null,
    email: null,
    isNavBarExpanded: true
  }
})

@Injectable()
export class AccountState {
  @Selector()
  static role(state: AccountStateModel): string | null {
    return state.role;
  }

  @Selector()
  static isAuthenticated(state: AccountStateModel): boolean {
    return !!state.role;
  }

  @Selector()
  static isNavBarExpanded(state: AccountStateModel): boolean {
    return state.isNavBarExpanded;
  }

  @Selector()
  static isUserChanged(state: AccountStateModel): boolean {
    return !!state.previousUserId && state.id !== state.previousUserId;
  }

  @Action(AccountActions.Logout)
  logout(ctx: StateContext<AccountStateModel>): void {
    const state = ctx.getState();

    ctx.patchState({
      role: null,
      email: null,
      previousUserId: state.id
    });
  }

  @Action(AccountActions.SignIn)
  signIn(ctx: StateContext<AccountStateModel>, action: AccountActions.SignIn): void {
    ctx.patchState({
      id: action.payload.id,
      role: action.payload.role,
      email: action.payload.email
    });
  }

  @Action(AccountActions.ChangeNavBarExpandState)
  changeNavBarExpandState(ctx: StateContext<AccountStateModel>, action: AccountActions.ChangeNavBarExpandState) {
    const state = ctx.getState();
    ctx.patchState({
      isNavBarExpanded: !state.isNavBarExpanded
    });
  }

  @Action(AccountActions.ClearPreviousId)
  clearPreviousId(ctx: StateContext<AccountStateModel>, action: AccountActions.ClearPreviousId) {
    ctx.patchState({
      previousUserId: null
    });
  }
}