import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AccountService, GoogleMapService, UrlTrackingService } from './shared/providers/services';
import { Select, Store } from '@ngxs/store';
import { AccountActions } from './shared/store/account/account.actions';
import { AccountState } from './shared/store/account/account.state';
import { Observable } from 'rxjs';
import { DefaultConstant } from './shared/constants/default.constant';
import { AzureConfigModel } from './shared/models/config/azure-config.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  @Select(AccountState.isNavBarExpanded) isNavBarExpanded$!: Observable<boolean>;
  constructor(
    @Inject(DefaultConstant.APP_CONFIG) private readonly _appConfig: AzureConfigModel,
    private readonly _accountService: AccountService,
    private readonly _googlMapService: GoogleMapService,
    private readonly _store: Store,
    private urlTrackingService: UrlTrackingService) {
      if(_appConfig.isBusinessPortal) {
        const element = document.getElementById('#title');
        if(element){
          element.textContent = 'D4Drivers Business Portal'
        }
      }
		this.urlTrackingService.nextUrl$.subscribe();
  }

  ngOnInit(): void {
    this._init();
  }

  ngOnDestroy(): void {
    this._accountService.onDestroy();
  }
  expandNavBar(): void {
    this._store.dispatch(AccountActions.ChangeNavBarExpandState);
  }
  private _init(): void {
    this._accountService.ping();
    this._accountService.init();
    this._googlMapService.init();
  }
}
