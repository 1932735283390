import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetClinicSchedulePractitionersBidsModel } from '../../models/clinic-bid/get-clinic-schedule-practitioners-bids.model';
import { ResponseModel } from '../../models/base/response-model';
import { environment } from 'src/environments/environment';
import { ProtectedEndpointsConstant } from '../../constants/protected-endpoints.constant';
import { Observable } from 'rxjs';
import { ClinicBidConflictModel } from '../../models/clinic-bid/clinic-bid-conflict.model';
import { ClinicBidAwardResolveConflictModel } from '../../models/clinic-bid/clinic-bid-award-resolve-conflict.model';
import { GetClinicSchedulePractitionerBidsDetailsModel } from '../../models/clinic-bid/get-clinic-schedule-practitioner-bids-details';

@Injectable({
  providedIn: 'root'
})
export class ClinicBidService {

  constructor(private readonly _http: HttpClient) {
  }

  getClinicSchedulePractitionersBids(clinicScheduleId: number, startDate: string): Observable<ResponseModel<GetClinicSchedulePractitionersBidsModel[]>> {
    return this._http.get<ResponseModel<GetClinicSchedulePractitionersBidsModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_BID}/${clinicScheduleId}/practitioners-bids?startDate=${startDate}`);
  }
  
  getClinicSchedulePractitionerBidsDetails(id: number, practitionerId: number): Observable<ResponseModel<GetClinicSchedulePractitionerBidsDetailsModel>> {
    return this._http.get<ResponseModel<GetClinicSchedulePractitionerBidsDetailsModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_BID}/${id}/practitioner-bids-details?practitionerId=${practitionerId}`);
  }

  awardClinicScheduleBid(clinicScheduleId: number, id: number): Observable<ResponseModel<void>> {
    return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_BID}/${id}/award?clinicScheduleId=${clinicScheduleId}`, null);
  }
  
  awardClinicScheduleResolveConflict(id: number, request: ClinicBidAwardResolveConflictModel): Observable<ResponseModel<void>> {
    return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_BID}/${id}/award-resolve-conflict`, request);
  }

  awardConfilct(id: number): Observable<ResponseModel<ClinicBidConflictModel | null>> {
    return this._http.get<ResponseModel<ClinicBidConflictModel | null>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_BID}/${id}/award-conflict`);
  }
}
