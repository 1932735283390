import { Inject, Injectable } from "@angular/core";
import { DefaultConstant } from "../../constants/default.constant";
import { AzureConfigModel } from "../../models/config/azure-config.model";
import { HttpClient } from "@angular/common/http";
import { take, tap } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class GoogleMapService {

    constructor(@Inject(DefaultConstant.APP_CONFIG) private readonly _appConfig: AzureConfigModel, private readonly _http: HttpClient) { }
    init(): void {
        this._http.get(`https://maps.googleapis.com/maps/api/js?key=${this._appConfig.googleApiKey}&region=GB&language=EN&libraries=places&v=weekly`, { responseType: 'text' })
            .pipe(
                take(1),
                tap((response: string) => {
                    const script = document.createElement('script');
                    script.innerHTML = response;
                    script.type = 'text/javascript'
                    script.defer = true;
                    const body = document.getElementById('#head');
                    body?.appendChild(script);
                })
            )
            .subscribe();
    }
}