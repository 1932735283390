import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseModel } from '../../models/base/response-model';
import { environment } from 'src/environments/environment';
import { ProtectedEndpointsConstant } from '../../constants/protected-endpoints.constant';
import { Observable } from 'rxjs';
import { PaymentSummaryModel } from '../../models/payment/payment-summary.model';
import { DropdownResponseBaseModel } from '../../models/base/dropdown-response.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private readonly _http: HttpClient) { }

  create(bookingId: number): Observable<ResponseModel<void>> {
    return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.PAYMENT}?bookingid=${bookingId}`, null);
  }

  getPaymentSummary(bookingId: number): Observable<ResponseModel<PaymentSummaryModel>> {
    return this._http.get<ResponseModel<PaymentSummaryModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.PAYMENT}/${bookingId}/summary`);
  }

  getPaymentTerms(): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
    return this._http.get<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.PAYMENT}/terms`);
}

}
