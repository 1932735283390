import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './modules/material.module';
import { MsalModule } from '@azure/msal-angular';
import { FailedComponent } from './components/failed/failed.component';
import { DesktopNavBarComponent } from './components/nav-bars/desktop-nav-bar/desktop-nav-bar.component';
import { BusinessAdditionalInfoDialogComponent } from './components/dialogs/business-additional-info-dialog/business-additional-info-dialog.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseComponent } from './components/base/base.component';
import { GoogleAddressDirective } from './directives/google-address.directive';
import { UppercaseDirective } from './directives/uppercase.directives';
import { ToastrModule } from 'ngx-toastr';
import { GenericSurveySelectDialogComponent } from './components/dialogs/generic-survey-select-dialog/generic-survey-select-dialog.component';
import { GenericEditMatTableComponent } from './components/generic-edit-mat-table/generic-edit-mat-table.component';
import { GenericAssertionInfoDialogComponent } from './components/dialogs/generic-assertion-info-dialog/generic-assertion-info-dialog.component';
import { GenericQuestionBooleanResponseDialogComponent } from './components/dialogs/generic-question-boolean-response-dialog/generic-question-boolean-response-dialog.component';
import { NotesWithCrudComponent } from './components/notes-with-crud/notes-with-crud.component';
import { InsufficientPermissionsComponent } from './components/insufficient-permissions/insufficient-permissions.component';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';

const UK_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const internalComponets = [FailedComponent, BusinessAdditionalInfoDialogComponent, GenericSurveySelectDialogComponent,     GenericAssertionInfoDialogComponent,
  GenericQuestionBooleanResponseDialogComponent, InsufficientPermissionsComponent, ConfirmDialogComponent];
const exportComponets = [DesktopNavBarComponent, BaseComponent, GoogleAddressDirective, GenericEditMatTableComponent, NotesWithCrudComponent];
@NgModule({
  declarations: [
    internalComponets,
    exportComponets,
    UppercaseDirective
  ],
  imports: [
    CommonModule,
    MaterialModule,
    MsalModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [MaterialModule, MsalModule, exportComponets, FormsModule, ReactiveFormsModule, UppercaseDirective],
  providers: [GoogleAddressDirective,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: UK_DATE_FORMATS },]
})
export class SharedModule { }
