import { Inject, Injectable } from "@angular/core";
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { EventMessage, InteractionStatus, AuthenticationResult, InteractionType, EventType } from "@azure/msal-browser";
import { Subject, filter, takeUntil, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { IdTokenClaims } from '@azure/msal-common'
import { DefaultConstant } from "../../constants/default.constant";
import { Store } from "@ngxs/store";
import { AccountActions } from "../../store/account/account.actions";
import { AccountSignInModel } from "../../models/account/account-sign-in.model";
import { HttpClient } from "@angular/common/http";
import { ProtectedEndpointsConstant } from "../../constants/protected-endpoints.constant";
import { AzureConfigModel } from "../../models/config/azure-config.model";

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
    acr?: string,
    tfp?: string,
};

@Injectable({
    providedIn: 'root'
})
export class AccountService {
    private readonly _destroying$ = new Subject<void>();
    isIframe = false
    claims: any[] | null = [];
    constructor(@Inject(MSAL_GUARD_CONFIG) private readonly _msalGuardConfig: MsalGuardConfiguration,
        @Inject(DefaultConstant.APP_CONFIG) private readonly _appConfig: AzureConfigModel,
        private readonly _authService: MsalService,
        private readonly _msalBroadcastService: MsalBroadcastService,
        private readonly _http: HttpClient,
        private readonly _store: Store) {}

    init(): void {
        this.isIframe = window !== window.parent && !window.opener; 

        this._authService.instance.enableAccountStorageEvents();
        this._msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
                tap(() => {
                    if (this._authService.instance.getAllAccounts().length === 0) {
                        window.location.pathname = DefaultConstant.EMPTY_URL;
                        this._store.dispatch(AccountActions.Logout);
                    }
                }),
                takeUntil(this._destroying$)
            )
            .subscribe();

        this._msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                tap(() => {
                    this.checkAndSetActiveAccount();
                    this.claims = this._getClaims(this._authService.instance.getActiveAccount()?.idTokenClaims as Record<string, any>);
                    if (this.claims) {
                        const role = this.claims.find(item => item.claim === DefaultConstant.CLAIMS_ROLES_NAME);
                        const id = this.claims.find(item => item.claim === DefaultConstant.CLAIMS_USER_ID_NAME);
                        const email = this.claims.find(item => item.claim === DefaultConstant.CLAIMS_EMAIL_NAME);
                        
                        const payload: AccountSignInModel = { id: id?.value, email: email?.value, role: role?.value }
                        this._store.dispatch(new AccountActions.SignIn(payload));
                        this.authPing();
                    } else {
                        this._store.dispatch(AccountActions.Logout);
                    }

                }),
                takeUntil(this._destroying$)
            )
            .subscribe()

        this._msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
                    || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                    || msg.eventType === EventType.SSO_SILENT_SUCCESS),
                tap((result: EventMessage) => {

                    let payload = result.payload as AuthenticationResult;
                    let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;
                    if (idtoken.acr === this._appConfig.msalB2CPoliciesSignUpSignIn || idtoken.tfp === this._appConfig.msalB2CPoliciesSignUpSignIn) {
                        this._authService.instance.setActiveAccount(payload.account);
                    }

                    return result;
                }
                ),
                takeUntil(this._destroying$)
            )
            .subscribe();

        this._msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
                tap((result: EventMessage) => {
                   
                    if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
                        this._store.dispatch(AccountActions.Logout);
                    };
                }),
                takeUntil(this._destroying$)
            )
            .subscribe();
    }

    checkAndSetActiveAccount(): void {
        let activeAccount = this._authService.instance.getActiveAccount();

        if (!activeAccount && this._authService.instance.getAllAccounts().length > 0) {
            let accounts = this._authService.instance.getAllAccounts();
            this._authService.instance.setActiveAccount(accounts[0]);
        }
    }

    logout(): void {
        if (this._msalGuardConfig.interactionType === InteractionType.Popup) {
            this._authService.logoutPopup({
                mainWindowRedirectUri: DefaultConstant.EMPTY_URL
            });
        } else {
            this._authService.logout();
        }
        this._store.dispatch(AccountActions.Logout);
    }
    
    authPing(): void {
        this._http.get(`${environment.baseUrl}${ProtectedEndpointsConstant.HOME}/auth-ping`)
            .pipe(
                tap(res => {
                    
                }),
                takeUntil(this._destroying$)
            )
            .subscribe();
    }

    ping(): void {
        this._http.get(`${environment.baseUrl}${ProtectedEndpointsConstant.HOME}`)
            .pipe(
                tap(res => {
                    
                }),
                takeUntil(this._destroying$)
            )
            .subscribe();
    }

    onDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    private _getClaims(claims: Record<string, any>): {
        id: number;
        claim: string;
        value: unknown;
    }[] | null {
        if (claims) {
            return Object.entries(claims).map((claim: [string, unknown], index: number) => ({ id: index, claim: claim[0], value: claim[1] }));
        };
        return null;
    }
}