import { DropdownResponseBaseModel } from "../../models/base/dropdown-response.model";
import { ClinicBidAwardResolveConflictModel } from "../../models/clinic-bid/clinic-bid-award-resolve-conflict.model";
import { ClinicScheduleCreateRangeDatesModel } from "../../models/clinic-schedule/clinic-schedule-create-range-dates.model";
import { ClinicScheduleCreateSingleDateModel } from "../../models/clinic-schedule/clinic-schedule-create-single-date.model";
import { UpdateClinicScheduleDetailsModel } from "../../models/clinic-schedule/update-clinics-schedule-details.model";
import { ClinicsFilter } from "../../models/clinics/clinics-filter.model";
import { ClinicsSpecificLocationFilterModel } from "../../models/clinics/clinics-specific-location-filter.model";
import { AddClinicScheduleVenueNotesModel } from "../../models/venue/add-clinic-schedule-venue-notes.model";
import { EditClinicScheduleVenueNotesModel } from "../../models/venue/edit-clinic-schedule-venue-notes.model";

export namespace ShiftManagerActions {

    export class GetRegions {
        static readonly type = '[ShiftManager] GetRegions';
    }

    export class GetLocations {
        static readonly type = '[ShiftManager] GetRegions';
    }
    export class GetClinicsBookedStatuses {
        static readonly type = '[ShiftManager] GetClinicsBookedStatuses';
    }

    export class GetClinicPlannings {
        static readonly type = '[ShiftManager] GetClinicPlannings';
    }

    export class GetClinicsSpecificLocations {
        static readonly type = '[ShiftManager] GetClinicsSpecificLocations';
    }

    export class GetDoctors {
        static readonly type = '[ShiftManager] GetDoctors';

        constructor(public searchText: string) { }
    }
    export class GetDoctorStatuses {
        static readonly type = '[ShiftManager] GetDoctorStatuses';
    }

    export class GetVenues {
        static readonly type = '[ShiftManager] GetVenues';

        constructor(public searchText: string, public locationId: number | undefined) { }
    }

    export class GetVenueStatuses {
        static readonly type = '[ShiftManager] GetVenueStatuses';
    }

    export class GetClinicScheduleDetails {
        static readonly type = '[ShiftManager] GetClinicScheduleDetails';
    }

    export class GetClinicSchedulePractitionersBids {
        static readonly type = '[ShiftManager] GetClinicSchedulePractitionersBids';
    }

    export class GetClinicSchedulePractitionerBidsDetails {
        static readonly type = '[ShiftManager] GetClinicSchedulePractitionerBidsDetails';
        constructor(public id: number) { }
    }

    export class GetClinicScheduleVenueNotes {
        static readonly type = '[ShiftManager] GetClinicScheduleVenueNotes';
    }

    export class SetLocationTogglerValue {
        static readonly type = '[ShiftManager] SetLocationTogglerValue';
        constructor(public value: boolean) { }
    }

    export class SetClinincFilterBooking {
        static readonly type = '[ShiftManager] SetClinincFilterBooking';
        constructor(public value: ClinicsFilter) { }
    }

    export class SetClinincFilterPlanning {
        static readonly type = '[ShiftManager] SetClinincFilterPlanning';
        constructor(public value: ClinicsFilter) { }
    }

    export class SetSelectedLocation {
        static readonly type = '[ShiftManager] SetSelectedLocation';
        constructor(public value: DropdownResponseBaseModel) { }
    }
    
    export class SetClinicScheduleEditMode {
        static readonly type = '[ShiftManager] SetClinicScheduleEditMode';
        constructor(public value: boolean) { }
    }

    export class SetSelectedClinicScheduleId {
        static readonly type = '[ShiftManager] SetSelectedClinicScheduleId';
        constructor(public id: number | null) { }
    }

    export class SetSelectedClinicScheduleDate {
        static readonly type = '[ShiftManager] SetSelectedClinicScheduleDate';
        constructor(public date: string | null) { }
    }
    export class SetSelectedClinicScheduleDoctorId {
        static readonly type = '[ShiftManager] SetSelectedClinicScheduleDoctorId';
        constructor(public id: number) { }
    }

    export class ClinicScheduleCreateSingleDate {
        static readonly type = '[ShiftManager] ClinicScheduleCreateSingleDate';
        constructor(public model: ClinicScheduleCreateSingleDateModel) { }
    }

    export class ClinicScheduleCreateRangeDates {
        static readonly type = '[ShiftManager] ClinicScheduleCreateRangeDates';
        constructor(public model: ClinicScheduleCreateRangeDatesModel) { }
    }

    export class SetPreviousUrl {
        static readonly type = '[ShiftManager] SetPreviousUrl';
        constructor(public url: string) { }
    }

    export class SetSpecificLocationFilter {
        static readonly type = '[ShiftManager] SetGetSpecificLocationFilter';
        constructor(public model: ClinicsSpecificLocationFilterModel | null) { }
    }

    export class SetClinicScheduleDetailsFormGroupValid {
        static readonly type = '[ShiftManager] SetClinicScheduleDetailsFormGroupValid';
        constructor(public value: boolean | null) { }
    }

    export class SetClinicScheduleDetailsFormSubmited {
        static readonly type = '[ShiftManager] SetClinicScheduleDetailsFormSubmited';
        constructor(public value: boolean | null) { }
    }

    export class AssignVenueSpecificLocationBulk {
        static readonly type = '[ShiftManager] AssignVenueSpecificLocationBulk';
        constructor(public ids: number[], public venueId: number) { }
    }

    export class ChangeTimeSpecificLocationBulk {
        static readonly type = '[ShiftManager] ChangeTimeSpecificLocationBulk';
        constructor(public ids: number[], public startTime: string, public endTime: string, public duration: number) { }
    }

    export class UpdateClinicScheduleDetails {
        static readonly type = '[ShiftManager] UpdateClinicScheduleDetails';
        constructor(public model: UpdateClinicScheduleDetailsModel) { }
    }

    export class ReAdvertiseClinicSchedule {
        static readonly type = '[ShiftManager] ReAdvertiseClinicSchedule';
    }

    export class AwardClinicScheduleBid {
        static readonly type = '[ShiftManager] AwardClinicScheduleBid';

        constructor(public bidId: number) { }
    }

    export class AwardClinicScheduleResolveConflict {
        static readonly type = '[ShiftManager] AwardClinicScheduleResolveConflict';

        constructor(public request: ClinicBidAwardResolveConflictModel) { }
    }

    export class AwardClinicScheduleBidConflict {
        static readonly type = '[ShiftManager] AwardClinicScheduleBidConflict';

        constructor(public bidId: number) { }
    }

    export class ClinicScheduleVenueAddNote {
        static readonly type = '[ShiftManager] ClinicScheduleVenueAddNote';

        constructor(public request : AddClinicScheduleVenueNotesModel) { }
    }

    export class ClinicScheduleVenueEditNote {
        static readonly type = '[ShiftManager] ClinicScheduleVenueEditNote';

        constructor(public request: EditClinicScheduleVenueNotesModel) { }
    }

    export class DeleteClinicScheduleVenueNote {
        static readonly type = '[ShiftManager] DeleteClinicScheduleVenueNote';

        constructor(public id: number) { }
    }

    export class DeleteSpecificLocation {
        static readonly type = '[ShiftManager] DeleteSpecificLocation';
        constructor(public id: number) { }
    }

    export class DeleteSpecificLocationBulk {
        static readonly type = '[ShiftManager] DeleteSpecificLocationBulk';
        constructor(public ids: number[]) { }
    }

    export class ClearClinicScheduleDetails {
        static readonly type = '[ShiftManager] ClearClinicScheduleDetails';
    }
}