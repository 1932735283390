<div class="generic-question-boolean-dialog-container">
    <div class="generic-question-boolean-dialog-header">
        <div>
            <span>{{model.title}}</span>
        </div>
    </div>
    <div class="generic-question-boolean-dialog-body">
        <span> {{model.text}}</span>
        <mat-divider></mat-divider>
        <div class="generic-question-boolean-dialog-buttons">
            <button class="generic-question-boolean-dialog-button-cancel" mat-button (click)="onButtonCancelClick()">{{model.buttonCancelText}}</button>
            <button class="generic-question-boolean-dialog-button-confirm" mat-raised-button (click)="onButtonConfirmClick()">{{model.buttonConfirmText}}</button>
        </div>
    </div>


</div>