import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'failed',
  templateUrl: './failed.component.html',
  styleUrls: ['./failed.component.scss']
})
export class FailedComponent {
  constructor(private readonly _router: Router) {
    
  }
  onLoginClick(): void{
    this._router.navigate(['home']);
  }
}
