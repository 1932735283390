/// <reference types="@types/google.maps" />
import { Directive, ElementRef, EventEmitter, NgZone, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[appGoogleAddress]'
})
export class GoogleAddressDirective implements OnInit {

  @Output() addressChosen: EventEmitter<AddressInfo> = new EventEmitter<AddressInfo>();

  private autocomplete!: google.maps.places.Autocomplete;

  constructor(
    private elementRef: ElementRef,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
    this.initializeGooglePlaces();
  }

  initializeGooglePlaces(): void {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.elementRef.nativeElement,
      {
        types: ['geocode'],
        componentRestrictions: { country: [ 'gb' ] }
      }
    );
    this.autocomplete.setFields(['address_component', 'geometry']);
    this.autocomplete.addListener('place_changed', this.onAddressChosen.bind(this));
  }

  onAddressChosen(): void {
    this.ngZone.run(() => {
      const place = this.autocomplete.getPlace();

      let streetNumber: string;
      let streetRoute: string;
      let city: string;
      let postTown: string;
      let state: string;
      let zip: string;
      let countryCode: string;

      for (const addressComponent of place.address_components!) {
        const addressType = addressComponent.types[0];
        if (addressType === 'street_number') {
          streetNumber = addressComponent.short_name;
        }
        else if (addressType === 'route') {
          streetRoute = addressComponent.long_name;
        }
        else if (addressType === 'locality' || addressType === 'sublocality_level_1') {
          city = addressComponent.long_name;
        }
        else if (!place.address_components!.map((component:any) => component.types[0]).includes('locality') && addressType === 'administrative_area_level_2') {
          city = addressComponent.long_name;
        }
        else if(addressType === 'postal_town') {
          postTown = addressComponent.long_name;
        }
        else if (addressType === 'administrative_area_level_1') {
          state = addressComponent.short_name;
        }
        else if (addressType === 'postal_code') {
          zip = addressComponent.short_name;
        }
      }

      const addressInfo: AddressInfo = {
        address: streetRoute! && streetNumber! ? `${streetNumber} ${streetRoute}` : streetNumber! ?? '',
        city: city!,
        postTown: postTown!,
        state: state!,
        zip: zip!,
        countryCode: countryCode!,
        coordinates: place.geometry?.location?.toJSON()!
      };

      this.addressChosen.emit(addressInfo);
    });
  }

}

export interface AddressInfo {
  address: string;
  city: string;
  postTown?: string;
  state: string;
  zip: string;
  country?: string;
  countryCode?: string;
  coordinates: google.maps.LatLngLiteral
}
