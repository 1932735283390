import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory, appConfig, initAppCofig } from './app/shared/providers/factories/msal.factory';
import { filter, take, tap } from 'rxjs';
import { DefaultConstant } from './app/shared/constants/default.constant';
import Decimal from 'decimal.js';


initAppCofig()
  .pipe(
    filter(() => appConfig.isAllFieldsSetted),
    take(1),
    tap(() => {
      Decimal.set({
        rounding: Decimal.ROUND_UP
      });
      const providers: any = [
        {
          provide: DefaultConstant.APP_CONFIG,
          useValue: appConfig
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: () => MSALInstanceFactory(appConfig),
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: () => MSALGuardConfigFactory(appConfig),
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: () => MSALInterceptorConfigFactory(appConfig),
        }
      ];
      platformBrowserDynamic(providers).bootstrapModule(AppModule)
        .catch(err => console.error(err));
    })
  )
  .subscribe();
