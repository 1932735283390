import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProtectedEndpointsConstant } from '../../constants/protected-endpoints.constant';
import { IdResponseModel } from '../../models/base/id-response-model';
import { PaginationResponse } from '../../models/base/pagination-response.model';
import { PaginationModel } from '../../models/base/pagination.model';
import { ResponseModel } from '../../models/base/response-model';
import { VoucherModel } from '../../models/voucher/voucher.model';
import { VoucherEditModel } from '../../models/voucher/voucher-edit.model';
import { DropdownResponseBaseModel } from '../../models/base/dropdown-response.model';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  constructor(private readonly _http: HttpClient) { }

  getVouchers(pagination: PaginationModel, isValid: boolean | null, code: string | null): Observable<ResponseModel<PaginationResponse<VoucherModel>>> {
    let params = new HttpParams();
    
    params = params.append("isValid", isValid ?? 'null');

    if (code) {
      params = params.append("code", code);
    }

    if (pagination) {
      params = params.append("pagination.pageNumber", pagination.pageNumber.toString());
      params = params.append("pagination.pageSize", pagination.pageSize.toString());
    }

    return this._http.get<ResponseModel<PaginationResponse<VoucherModel>>>(`${environment.baseUrl}${ProtectedEndpointsConstant.VOUCHER}`, { params: params });
  }

  getVoucherTypes(): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
    return this._http.get<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.VOUCHER}/types`);
  }

  addVoucher(model: VoucherEditModel): Observable<ResponseModel<IdResponseModel>> {
    return this._http.post<ResponseModel<IdResponseModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.VOUCHER}`, model);
  }

  editVoucher(id: number, model: VoucherEditModel): Observable<ResponseModel<IdResponseModel>> {
    return this._http.put<ResponseModel<IdResponseModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.VOUCHER}/${id}`, model);
  }

  deleteVoucher(id: number): Observable<ResponseModel<void>> {
    return this._http.delete<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.VOUCHER}/${id}`);
  }
}
