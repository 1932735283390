<div class="business-additional-info-dialog-container">
    <div class="business-additional-info-dialog-header">
        <div>
            <span>Business</span>
        </div>

        <div class="business-additional-info-dialog-header-close" (click)="close(false)"> <mat-icon>close</mat-icon></div>
    </div>
    <div class="business-additional-info-dialog-body">
        <div [formGroup]="formGroup" class="business-additional-info-dialog-form">
            <div class="business-additional-info-dialog-input-wrapper">
                <mat-label>Company</mat-label>
                <mat-form-field appearance="outline" class="material-form-field-input-outline">
                    <input #businessAccountInput type="text" matInput formControlName="businessAccount"
                        [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayNameFn"
                        (optionSelected)="onCompanySelected()">
                        <mat-option *ngFor="let businessAccount of businessAccounts$ | async" [value]="businessAccount">
                            {{businessAccount.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="business-additional-info-dialog-input-wrapper">
                <mat-label>Referral/ Voucher Code</mat-label>
                <mat-form-field appearance="outline" class="material-form-field-input-outline">
                    <input #voucherInput matInput formControlName="voucherCode">
                    <mat-icon *ngIf="hasErrors('voucherCode', formGroup) && (isVoucherExist$ | async)" matSuffix
                        class="voucher-success"> check_circle </mat-icon>
                    <mat-icon *ngIf="hasErrors('voucherCode', formGroup) && !(isVoucherExist$ | async)" matSuffix
                        class="voucher-error"> cancel </mat-icon>
                </mat-form-field>
                <div *ngIf="voucherCodeControl.value && (isVoucherExist$ | async)"
                    class="form-below-input-message validation-success">
                    Referral/ Voucher code valid
                </div>
                <div *ngIf="voucherCodeControl.value && !(isVoucherExist$ | async)"
                    class="form-below-input-message validation-error">
                    Referral/Voucher code is not valid
                </div>
            </div>
            <div class="business-additional-info-dialog-input-wrapper">
                <mat-label>Note</mat-label>
                <mat-form-field>
                    <textarea matInput [rows]="2" formControlName="note"> </textarea>
                </mat-form-field>
            </div>

            <button mat-raised-button [disabled]="voucherCodeControl.value && !(isVoucherExist$ | async) || !formGroup.valid " (click)="onContinueClick()">Continue</button>
        </div>
    </div>

</div>