<div class="failed-container">

    <div class="failed-body">
        <div class="failed-message">
            <div class="failed-message-header">
                You are not currently logged in.
            </div>
            <div class="failed-message-sub-header">
                Please log in to continue
            </div>
            <button mat-raised-button (click)="onLoginClick()"  class="failed-message-button">
                Log in
            </button>
        </div>
    </div>
</div>