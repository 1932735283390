import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ProtectedEndpointsConstant } from "../../constants/protected-endpoints.constant";
import { environment } from "src/environments/environment";
import { Observable, map } from "rxjs";
import { ResponseModel } from "../../models/base/response-model";
import { AppointmentSlotModel, ClinicScheduleModel } from "../../models/clinic-schedule/clinic-schedule.model";
import { ClinicsStatisticFilter } from "../../models/clinics/clinic-statistic-filter.model";
import { ClinicsBookingStatusModel } from "../../models/clinics/clinics-booking-status.model";
import { ClinicsPlanningModel } from "../../models/clinics/clinics-planing.model";
import { ClinicScheduleCreateSingleDateModel } from "../../models/clinic-schedule/clinic-schedule-create-single-date.model";
import { ClinicScheduleCreateRangeDatesModel } from "../../models/clinic-schedule/clinic-schedule-create-range-dates.model";
import { DropdownResponseBaseModel } from "../../models/base/dropdown-response.model";
import { ClinicsSpecifiLocationRequestModel } from "../../models/clinics/clinics-specific-location-request.model";
import { ClinicsGetSpecificLocationModel } from "../../models/clinics/clinics-get-specific-location.model";
import { BulkChangeTimesRequestModel } from "../../models/clinic-schedule/bulk-change-times-request.model";
import { BulkAssignVenueRequestModel } from "../../models/clinic-schedule/bulk-assign-venue-request.model";
import { GetClinicScheduleDetailsModel } from "../../models/clinic-schedule/get-clinics-schedule-details.model";
import { UpdateClinicScheduleDetailsModel } from "../../models/clinic-schedule/update-clinics-schedule-details.model";

@Injectable({
    providedIn: 'root'
})
export class ClinicScheduleService {
    constructor(private readonly _http: HttpClient) { }


    getClinicSchedules(appointmentTypeId: number, longitude: number, latitude: number, skip: number | null, date: string | null, localAuthorityId: number | null): Observable<ClinicScheduleModel[]> {
        return this._http.get<ResponseModel<ClinicScheduleModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_SCHEDULES}?appointmentTypeId=${appointmentTypeId}&longitude=${longitude}&latitude=${latitude}&skip=${skip}&date=${date}&localAuthorityId=${localAuthorityId}`)
            .pipe(
                map((response: ResponseModel<ClinicScheduleModel[]>) => response.content)
            );
    }

    getClinicScheduleAppointmentSlots(appointmentTypeId: number, venueId: number, skip: number | null, date: string | null, localAuthorityId: number | null): Observable<ResponseModel<AppointmentSlotModel[]>> {
        return this._http.get<ResponseModel<AppointmentSlotModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_SCHEDULES}/venue?venueId=${venueId}&appointmentTypeId=${appointmentTypeId}&skip=${skip}&date=${date}&localAuthorityId=${localAuthorityId}`);
    }
    getClinicScheduleVenueStatuses(): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._http.get<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_SCHEDULES}/venue-statuses`);
    }
    getClinicSchedulePractitionerStatuses(): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._http.get<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_SCHEDULES}/practitioner-statuses`);
    }

    getClinicScheduleDetails(id: number): Observable<ResponseModel<GetClinicScheduleDetailsModel>> {
        return this._http.get<ResponseModel<GetClinicScheduleDetailsModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_SCHEDULES}/${id}/details`);
    }

    bookingStatuses(request: ClinicsStatisticFilter): Observable<ResponseModel<ClinicsBookingStatusModel[]>> {
        return this._http.post<ResponseModel<ClinicsBookingStatusModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_SCHEDULES}/booking-statistics`, request);
    }

    planning(request: ClinicsStatisticFilter): Observable<ResponseModel<ClinicsPlanningModel[]>> {
        return this._http.post<ResponseModel<ClinicsPlanningModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_SCHEDULES}/planning-statistics`, request);
    }

    createSingleDate(request: ClinicScheduleCreateSingleDateModel): Observable<ResponseModel<void>> {
        return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_SCHEDULES}/create-single-date`, request);
    }

    createRangeDates(request: ClinicScheduleCreateRangeDatesModel): Observable<ResponseModel<void>> {
        return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_SCHEDULES}/create-range-date`, request);
    }

    specificLocationClinicSchedule(locationId: number, request: ClinicsSpecifiLocationRequestModel): Observable<ResponseModel<ClinicsGetSpecificLocationModel[]>> {
        return this._http.post<ResponseModel<ClinicsGetSpecificLocationModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_SCHEDULES}/${locationId}`, request);
    }

    bulkChangeTimes(request: BulkChangeTimesRequestModel): Observable<ResponseModel<void>> {
        return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_SCHEDULES}/bulk-change-time`, request);
    }

    bulkAssignVenue(request: BulkAssignVenueRequestModel): Observable<ResponseModel<void>> {
        return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_SCHEDULES}/bulk-assign-venue/${request.venueId}`, request);
    }
    
    updateClinicScheduleDetails(request: UpdateClinicScheduleDetailsModel): Observable<ResponseModel<void>> {
        return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_SCHEDULES}/${request.id}/details-update`, request);
    }

    reAdvertiseClinicSchedule(id: number): Observable<ResponseModel<void>> {
        return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_SCHEDULES}/${id}/re-advertise`, null);
    }
    
    delete(id: number): Observable<ResponseModel<void>> {
        return this._http.delete<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_SCHEDULES}/${id}`);
    }

    bulkDelete(ids: number[]): Observable<ResponseModel<void>> {
        return this._http.delete<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CLINIC_SCHEDULES}/bulk-delete`, { body: { ids } });
    }
}