import { MsalInterceptorConfiguration, MsalGuardConfiguration } from "@azure/msal-angular";
import { BrowserCacheLocation, InteractionType, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { IPublicClientApplication } from "@azure/msal-browser/dist/app/IPublicClientApplication";
import { environment } from "src/environments/environment";
import { DefaultConstant } from "../../constants/default.constant";
import { ProtectedEndpointsConstant } from "../../constants/protected-endpoints.constant";
import { AzureConfigModel } from "../../models/config/azure-config.model";
import { AppConfigurationClient } from "@azure/app-configuration";
import { tap, from } from "rxjs";

export const appConfig: AzureConfigModel = {
  googleApiKey: null,
  msalAuthorityDomain: null,
  msalB2CPoliciesSignUpSignIn: null,
  msalB2CPoliciesSignUpSignInAuthority: null,
  msalConfigClientId: null,
  msalPermissionScopes: null,
  isBusinessPortal: false,
  isAllFieldsSetted: false
};

let _client: AppConfigurationClient = new AppConfigurationClient(environment.azureConfiguration);

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
export function loggerCallback(logLevel: LogLevel, message: string) {  
}

export function MSALInstanceFactory(config: AzureConfigModel): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: config.msalConfigClientId!,
      authority: config.msalB2CPoliciesSignUpSignInAuthority!,
      redirectUri: DefaultConstant.EMPTY_URL,
      postLogoutRedirectUri: DefaultConstant.EMPTY_URL,
      knownAuthorities: [config.msalAuthorityDomain!],
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: environment.production ? LogLevel.Error : LogLevel.Verbose,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(config: AzureConfigModel): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  [
    ProtectedEndpointsConstant.HOME,
    ProtectedEndpointsConstant.VOUCHER,
    ProtectedEndpointsConstant.BUSINESS_ACCOUNT,
    ProtectedEndpointsConstant.BOOKING,
    ProtectedEndpointsConstant.NOTES,
    ProtectedEndpointsConstant.APPOINTMENT,
    ProtectedEndpointsConstant.APPOINTMENT_TYPE,
    ProtectedEndpointsConstant.CLINIC_SCHEDULES,
    ProtectedEndpointsConstant.MARKETING_SURVEY_OPTIONS,
    ProtectedEndpointsConstant.CUSTOMERS,
    ProtectedEndpointsConstant.WORLD_PAY,
    ProtectedEndpointsConstant.PAYMENT,
    ProtectedEndpointsConstant.REGIONS,
    ProtectedEndpointsConstant.VENUES,
    ProtectedEndpointsConstant.PRACTITIONER,
    ProtectedEndpointsConstant.CLINIC_BID,
    ProtectedEndpointsConstant.VENUE_NOTE,
    ProtectedEndpointsConstant.NOTIFICATION,
    ProtectedEndpointsConstant.ATTENDED_STATUS,
    ProtectedEndpointsConstant.USER,
    ProtectedEndpointsConstant.PRICE_LISTS_ADMIN,
    ProtectedEndpointsConstant.PRICE_LISTS
  ]
    .forEach((item: string) => protectedResourceMap.set(`${environment.baseUrl}${item}`, config.msalPermissionScopes!));
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
    authRequest: {
      prompt: 'select_account'
    }
  };
}

export function MSALGuardConfigFactory(config: AzureConfigModel): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: config.msalPermissionScopes!,
      prompt: 'select_account',
      redirectUri: DefaultConstant.EMPTY_URL
    },
    loginFailedRoute: DefaultConstant.LOGIN_FAILED_URL
  };
}

const checkIsAllFieldsSetted = (config: AzureConfigModel): boolean => {
  return !!config.googleApiKey && !!config.msalAuthorityDomain && !!config.msalB2CPoliciesSignUpSignIn && !!config.msalB2CPoliciesSignUpSignInAuthority && !!config.msalConfigClientId && !!config.msalPermissionScopes;
}

export const initAppCofig = () => {

  return from(
    _client.listConfigurationSettings({
      fields: ['value', 'key'],
      labelFilter: getConfigLabel()
    })
  )
    .pipe(
      tap((response) => {
        if (response.key === DefaultConstant.MSAL_CONFIG_GMAP_KEY) {
          appConfig.googleApiKey = response.value!;
        }
        if (response.key === DefaultConstant.MSAL_CONFIG_AUTHORITY_DOMAIN_KEY) {
          appConfig.msalAuthorityDomain = response.value!;
        }
        if (response.key === DefaultConstant.MSAL_CONFIG_B2C_POLICY_SIGNIN_KEY) {
          appConfig.msalB2CPoliciesSignUpSignIn = response.value!; // REDACTABLE => B2C_1_D4DriversWithMSA + DEV name
        }
        if (response.key === DefaultConstant.MSAL_CONFIG_B2C_POLICY_SIGNIN_AUTHORITY_KEY) {  // REDACTABLE => ...../B2C_1_D4DriversWithMSA + DEV name
          appConfig.msalB2CPoliciesSignUpSignInAuthority = response.value!;
        }
        if (response.key === DefaultConstant.MSAL_CONFIG_AUTH_CLIENT_ID_KEY) {
          appConfig.msalConfigClientId = response.value!;
        }
        if (response.key === DefaultConstant.MSAL_CONFIG_PERMISSION_SCOPES_KEY) {
          appConfig.msalPermissionScopes = response.value!.split(',');
        }
        appConfig.isAllFieldsSetted = checkIsAllFieldsSetted(appConfig);
      })
    )
}

export const getConfigLabel = () => {
  let configLabel = environment.appConfigLabel;

  if(`${window.location.origin}/` === environment.businessAppUrl) {
    configLabel = environment.businessAppConfigLabel
    appConfig.isBusinessPortal = true;
  }
   
  return configLabel;
}