export class ProtectedEndpointsConstant {
    static readonly BUSINESS_ACCOUNT: string = 'business-account';
    static readonly BOOKING: string = 'booking';
    static readonly VOUCHER: string = 'voucher';
    static readonly HOME: string = 'home';
    static readonly APPOINTMENT_TYPE: string = 'appointment-type';
    static readonly APPOINTMENT: string = 'appointment';
    static readonly CLINIC_SCHEDULES: string = 'clinic-schedules';
    static readonly NOTES: string = 'notes';
    static readonly MARKETING_SURVEY_OPTIONS: string = 'marketing-survey-options/admin';
    static readonly CUSTOMERS: string = 'customers';
    static readonly PAYMENT: string = 'payment';
    static readonly WORLD_PAY: string = 'world-pay';
    static readonly REGIONS: string = 'regions';
    static readonly VENUES: string = 'venues';
    static readonly PRACTITIONER: string = 'practitioner';
    static readonly CLINIC_BID: string = 'clinic-bid';
    static readonly VENUE_NOTE: string = 'venue-note';
    static readonly NOTIFICATION: string = 'notification';
    static readonly ATTENDED_STATUS: string = 'attended-status';
    static readonly USER: string = 'users';
    static readonly PRICE_LISTS_ADMIN: string = 'price-list/admin';
    static readonly PRICE_LISTS: string = 'price-list';
}