import { AccountSignInModel } from "../../models/account/account-sign-in.model";

export namespace AccountActions {

  export class Logout {
    static readonly type = '[Account] Logout';
  }
  export class SignIn {
    static readonly type = '[Account] SignIn';
    constructor(public payload: AccountSignInModel) { }
  }
  export class ChangeNavBarExpandState {
    static readonly type = '[Account] ChangeNavBarExpandState';
  }

  export class ClearPreviousId {
    static readonly type = '[Account] ClearPreviousId';
  }
}