import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ProtectedEndpointsConstant } from "../../constants/protected-endpoints.constant";
import { BusinessAccountModel } from "../../models/business-accounts/business-account.model";
import { ResponseModel } from "../../models/base/response-model";
import { Observable } from "rxjs";
import { BusinessAccountVoucherModel } from "../../models/business-accounts/business-account-voucher.model";
import { BusinessAccountNoteModel } from "../../models/business-accounts/business-account-notes.model";
import { BusinessAccountAdminModel } from "../../models/business-accounts/business-account-admin.model";
import { BookingIdModel } from "../../models/booking/booking-id.model";
import { IdResponseModel } from "../../models/base/id-response-model";
import { PaginationModel } from "../../models/base/pagination.model";
import { PaginationResponse } from "../../models/base/pagination-response.model";
import { DropdownResponseBaseModel } from "../../models/base/dropdown-response.model";
import { BusinessAccountEditAdminModel } from "../../models/business-accounts/business-account-edit-admin.model";
import { BusinessAccountAdditionalDetailsModel } from "../../models/business-accounts/business-account-additional-details.model";
import { BusinessAccountAdminTableModel } from "../../models/business-accounts/business-account-admin-table.model";
import { BusinessAccountAppointmentTypeModel } from "../../models/business-accounts/business-account-appointment-type.model";
import { BusinessAccountAddAppointmentTypeModel } from "../../models/business-accounts/business-account-appointment-type-upsert.model";
import { BusinessAccountUserModel } from "../../models/business-accounts/business-account-user.model";
import { BusinessAccountReferralCodesModel } from "../../models/business-accounts/business-account-referral-codes.model";
import { BusinessAccountVoidReferralCodesModel } from "../../models/business-accounts/business-account-void-referal-codes.model";
import { BusinessAccountAddVoucherCodeModel } from "../../models/business-accounts/business-account-add-voucher-code.model";
import { BusinessAccountVoucherCodeModel } from "../../models/business-accounts/business-account-voucher-code.model";
import { BusinessAccountEditVoucherCodeModel } from "../../models/business-accounts/business-account-edit-voucher-code.model";

@Injectable({
    providedIn: 'root'
})
export class BusinessAccountService {
    constructor(private readonly _http: HttpClient) {
    }

    getBusinessAccounts(filter: string): Observable<ResponseModel<BusinessAccountModel[]>> {
        return this._http.get<ResponseModel<BusinessAccountModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}?filter=${filter}`);
    }

    getBusinessAccountNotes(id: number): Observable<ResponseModel<BusinessAccountNoteModel[]>> {
        return this._http.get<ResponseModel<BusinessAccountNoteModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/${id}/notes`);
    }

    getVoucherByCode(code: string | null, businessAccountId: number | null, appointmentTypeId: number | null): Observable<ResponseModel<BusinessAccountVoucherModel>> {
        return this._http.get<ResponseModel<BusinessAccountVoucherModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/voucher/${code}?businessAccountId=${businessAccountId}&appointmentTypeId=${appointmentTypeId}`);
    }
    
    getAdditionalDetails(businessAccountId: number | null): Observable<ResponseModel<BusinessAccountAdditionalDetailsModel>> {
        return this._http.get<ResponseModel<BusinessAccountAdditionalDetailsModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/additional-details?id=${businessAccountId}`);
    }

    getBusinessAccountAppointmentTypes(businessAccountId: number | null): Observable<ResponseModel<BusinessAccountAppointmentTypeModel[]>> {
        return this._http.get<ResponseModel<BusinessAccountAppointmentTypeModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/${businessAccountId}/appointment-types`);
    }

    getBusinessAccount(businessAccountId: number | null): Observable<ResponseModel<BusinessAccountAdminModel>> {
        return this._http.get<ResponseModel<BusinessAccountAdminModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/${businessAccountId}/admin`);
    }
    
    getBusinessAccountsAdmin(pagination: PaginationModel, isActive: boolean | null, searchText: string | null): Observable<ResponseModel<PaginationResponse<BusinessAccountAdminTableModel>>> {
        let params = new HttpParams();
        params = params.append("isActive", isActive ?? 'null');

        if(searchText){
            params = params.append("searchText", searchText);
        }
        if (pagination) {
			params = params.append("pagination.pageNumber", pagination.pageNumber.toString());
			params = params.append("pagination.pageSize", pagination.pageSize.toString());
		}

        return this._http.get<ResponseModel<PaginationResponse<BusinessAccountAdminTableModel>>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/admin`, { params: params });
    }

    getBusinessTypes(): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._http.get<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/business-types`);
    }

    getClosureReasons(): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._http.get<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/closure-reasons`);
    }

    getAccountPackageTypes(): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._http.get<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/package-types`);
    }

    getCompanyIntroSources(): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._http.get<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/company-intro-sources`);
    }

    getCreditLimits(): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._http.get<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/credit-limits`);
    }
    
    getNumberOfDriverTypes(): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._http.get<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/number-of-driver-types`);
    }

    getBookingMethods(): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._http.get<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/booking-methods`);
    }

    getBusinessAccountUsers(name: string): Observable<ResponseModel<BusinessAccountUserModel[]>> {
        const encodedName = encodeURIComponent(name);
        return this._http.get<ResponseModel<BusinessAccountUserModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/users?businessAccountName=${encodedName}`);
    }

    getBusinessAccountReferralCodes(id: number): Observable<ResponseModel<BusinessAccountReferralCodesModel>> {
        return this._http.get<ResponseModel<BusinessAccountReferralCodesModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/${id}/referral-codes`);
    }

    getBusinessAccountVouchers(id: number): Observable<ResponseModel<BusinessAccountVoucherCodeModel[]>> {
        return this._http.get<ResponseModel<BusinessAccountVoucherCodeModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/${id}/voucher-codes`)
    }
    
    businessAccountUserMatched(email: string | null): Observable<ResponseModel<void>> {
        return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/user/match-email?userEmail=${email}`, null);
    }

    addBusinessAccount(model: BusinessAccountEditAdminModel): Observable<ResponseModel<IdResponseModel>> {
        return this._http.post<ResponseModel<IdResponseModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}`, model);
    }

    addBusinessAccountAppointmentType(model: BusinessAccountAddAppointmentTypeModel): Observable<ResponseModel<void>> {
        return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/${model.businessAccountId}/appointment-type`, model);
    }

    addBusinessAccountUser(id: number, email: string, firstName: string, lastName: string): Observable<ResponseModel<void>> {
        return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/${id}/user?userEmail=${email}&firstName=${firstName}&lastName=${lastName}`, null);
    }

    addBusinessAccountVoucher(model: BusinessAccountAddVoucherCodeModel): Observable<ResponseModel<void>> {
        return this._http.put<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/voucher-code`, model);
    }

    generateBusinessAccountReferralCodes(id: number, count: number, expire: string | null, validFrom: string): Observable<ResponseModel<void>> {
        return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/${id}/referral-codes?codeCount=${count}&validFrom=${validFrom}&expire=${expire}`, null);
    }

    revertBusinessAccountAppointmentType(id: number): Observable<ResponseModel<void>> {
        return this._http.put<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/appointment-type/${id}/revert`, null);
    }

    editBusinessAccountAppointmentType(id: number, cost: number): Observable<ResponseModel<void>> {
        return this._http.put<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/appointment-type/${id}?cost=${cost}`, null);
    }
    
    editBusinessAccount(id: number, model: BusinessAccountEditAdminModel): Observable<ResponseModel<BookingIdModel>> {
        return this._http.put<ResponseModel<BookingIdModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/${id}`, model);
    }

    editBusinessAccountVoucher(model: BusinessAccountEditVoucherCodeModel): Observable<ResponseModel<void>> {
        return this._http.put<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/voucher-code/${model.id}`, model);
    }
    
    updateBusinessAccountUser(id: number, firstName: string, lastName: string): Observable<ResponseModel<void>> {
        return this._http.put<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/user/${id}?firstName=${firstName}&lastName=${lastName}`, null);
    }

    blockBusinessAccountUser(id: number): Observable<ResponseModel<void>> {
        return this._http.put<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/user/${id}/change-block-status`, null);
    }

    voidBusinessAccountReferralCodes(id: number, model: BusinessAccountVoidReferralCodesModel): Observable<ResponseModel<void>> {
        return this._http.put<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/${id}/referral-codes`, model);
    }

    voidBusinessAccountVoucher(id: number): Observable<ResponseModel<void>> {
        return this._http.put<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/voucher-code/${id}/void`, null);
    }

    deleteBusinessAccount(id: number): Observable<ResponseModel<void>> {
        return this._http.delete<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/${id}`);
    }

    deleteBusinessAccountAppointmentType(id: number): Observable<ResponseModel<void>> {
        return this._http.delete<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.BUSINESS_ACCOUNT}/appointment-type/${id}`);
    }
}