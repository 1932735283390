<mat-drawer-container class="main-body-container" autosize>
    <mat-drawer #drawerNavBar mode="side" [opened]="(isNavBarExpanded$ | async)">
        <desktop-nav-bar></desktop-nav-bar>
    </mat-drawer>
    <mat-drawer-content>
        <div class="router-container">
            <router-outlet></router-outlet>
            <button mat-icon-button *ngIf="!(isNavBarExpanded$ | async)" (click)="expandNavBar()"><mat-icon>arrow_forward_ios</mat-icon></button>
        </div>
    </mat-drawer-content>
</mat-drawer-container>