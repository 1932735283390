<div class="generic-survey-select-dialog-container">
    <div class="generic-survey-select-dialog-header">
        <div>
            <span>{{model.title}}</span>
        </div>

        <div class="generic-survey-select-dialog-header-close" (click)="close()"> <mat-icon>close</mat-icon></div>
    </div>
    <div class="generic-survey-select-dialog-body">
        <mat-form-field appearance="outline" class="material-form-field-input-outline">
            <mat-label>{{model.selectName}}</mat-label>
            <mat-select (selectionChange)="onTypeSelected($event.value)">
                <mat-option *ngFor="let item of model.array" [value]="item.id">
                    {{item.viewValue}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>