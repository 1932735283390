import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'generic-survey-select-dialog',
  templateUrl: './generic-survey-select-dialog.component.html',
  styleUrls: ['./generic-survey-select-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericSurveySelectDialogComponent {
  model!: GenericSurveySelectDialogDataModel;
  constructor(@Inject(MAT_DIALOG_DATA) private readonly _dialogData: GenericSurveySelectDialogDataModel, private _dialogRef: MatDialogRef<GenericSurveySelectDialogComponent>) {
    this.model = _dialogData;
  }

  close(): void {
    this._dialogRef.close();
  }

  onTypeSelected(id: number): void {
    this._dialogRef.close(id);
  }
}

export interface GenericSurveySelectDialogDataModel {
  title: string;
  selectName: string;
  array: GenericSurveySelectDialogDataModelItem[] | undefined;
}

export interface GenericSurveySelectDialogDataModelItem {
  id: number;
  viewValue: string | undefined;
}
