import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, lastValueFrom, map, of, switchMap, takeUntil, tap} from 'rxjs';
import { AbandonBookingModel } from '../../models/booking/abandon-booking.model';
import { BookingActions } from '../../store/booking/booking.actions';
import { GenericSurveySelectDialogComponent, GenericSurveySelectDialogDataModel } from '../dialogs/generic-survey-select-dialog/generic-survey-select-dialog.component';
import { AbandonBookingReasonModel } from '../../models/booking/abandon-booking-reason.model';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { BookingState } from '../../store/booking/booking.state';
import { ToastrMessagesService } from '../../providers/services';
import { AccountActions } from '../../store/account/account.actions';
import * as moment from 'moment';
import { RoleConstant } from '../../constants/role.constant';
import { AccountState } from '../../store/account/account.state';
import { GenericQuestionBooleanInputDataModel, GenericQuestionBooleanResponseDialogComponent } from '../dialogs/generic-question-boolean-response-dialog/generic-question-boolean-response-dialog.component';
import { DefaultConstant } from '../../constants/default.constant';
import { BreadcrumbService } from '../../providers/services/breadcrumb.service';

@Component({
  selector: 'base',
  templateUrl: './base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseComponent implements OnDestroy {
  protected readonly _destroySubject: Subject<void> = new Subject<void>();
  protected matSelectStateMatcher: MatSelectStateMatcher = new MatSelectStateMatcher(null);
  protected readonly _dialog!: MatDialog;
  protected readonly _store!: Store;
  protected readonly _router!: Router;
  protected readonly _toastrMessagesService: ToastrMessagesService;
  protected readonly _cdr: ChangeDetectorRef;
  protected readonly _route: ActivatedRoute;
  protected readonly _breadcrumbService: BreadcrumbService;

  @SelectSnapshot(BookingState.getAbandonBookingReasons) abandonBookingReasons!: AbandonBookingReasonModel[] | null;
  @SelectSnapshot(BookingState.getBookingId) bookingId!: number | null;
  @SelectSnapshot(BookingState.getBookingStepUrl) bookingStepUrl!: number | null;
  @Select(AccountState.role) role$!: Observable<string | null>;
  @SelectSnapshot(AccountState.role) roles!: string | null;
  
  get getRoleValues(): typeof RoleConstant {
    return RoleConstant;
  }

  constructor(protected readonly _injector: Injector) {
    this._dialog = _injector.get<MatDialog>(MatDialog);
    this._store = _injector.get<Store>(Store);
    this._router = _injector.get<Router>(Router);
    this._toastrMessagesService = _injector.get(ToastrMessagesService);
    this._route = _injector.get(ActivatedRoute);
    this._cdr = _injector.get(ChangeDetectorRef);
    this._breadcrumbService = _injector.get(BreadcrumbService);
  }
  
  ngOnDestroy() {
    this._destroySubject.next();
    this._destroySubject.complete();
  }

  isInRole(checkRole: string): boolean {
    if (this.roles) {
      const roleList = this.roles.split(',');
      return roleList.findIndex(item => item === checkRole) !== -1;
    }
    return false;
  }

  isInRoles(checkRoles: string[]): boolean {
    let result = false;
    if (this.roles) {
      const roleList = this.roles.split(',');
      const isBusinessAccount = roleList.findIndex(item => item === RoleConstant.BUSINESS_ACCOUNT_USER) !== -1;
      if(isBusinessAccount) {
        return false;
      }
      for (const role of roleList) {
        result = checkRoles.findIndex(item => item === role.trim()) !== -1;
        if (result) {
          break;
        }
      }
    }
    return result;
  }

  hasErrors(name: string, formGroup: FormGroup): boolean {
    return formGroup && formGroup.get(name)!.invalid && (formGroup.get(name)!.dirty || formGroup.get(name)!.touched);
  }

  hasPatternErrors(name: string, formGroup: FormGroup): boolean {
    return formGroup && formGroup.get(name)!.errors?.['pattern'] && (formGroup.get(name)!.dirty || formGroup.get(name)!.touched);
  }

  hasTouched(name: string, formGroup: FormGroup): boolean {
    return formGroup && (formGroup.get(name)!.dirty || formGroup.get(name)!.touched);
  }
  
  compareById<T extends { id?: number }>(source: T, previous: T): boolean {
    return source && previous && source.id === previous.id;
  }

  displayNameFn<T extends { name?: string }>(model: T): string {
    return model?.name ? model.name : '';
  }

  displayNames<T extends { name: string }>(array: T[]): string {
    return array ? array.reduce((p, c, index) => index === array.length - 1 ? p + `${c.name}`: p + `${c.name}, `, '') : '';
  }

  protected getFormattedUkDate(date: string | undefined, shouldShowTime: boolean): string {
    if(date){
      return moment(date).format(shouldShowTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY');
    }
    return '';
  }

  protected async onAbandonClick() {
    const result = await lastValueFrom(this.onAbandonClickObservable());
    if (result) {
      this._router.navigate(['/home']);
    }
  }

  protected onAbandonClickObservable(): Observable<boolean> {
    if (this.isInRole(this.getRoleValues.BUSINESS_ACCOUNT_USER)) {
      return this.openBusinessAbandonBooking();
    } else {
      return this.openNormalAbandonBooking();
    }
  }

  private openBusinessAbandonBooking() : Observable<boolean> {
    return this._dialog.open<GenericQuestionBooleanResponseDialogComponent, GenericQuestionBooleanInputDataModel, boolean>(GenericQuestionBooleanResponseDialogComponent, {
      width: 'calc(100vw /3)',
      panelClass: 'mat-dialog-border-lg-radius',
      autoFocus: false,
      position: {
        top: '100px'
      },
      data: {
        title: 'Abandon Booking',
        text: 'Are you sure you want to abandon the booking?',
        buttonCancelText: 'No',
        buttonConfirmText: 'Yes'
      }
    }).afterClosed()
      .pipe(
        switchMap((response: boolean | undefined): Observable<boolean> => {
          if(response){
            this._silentAbandonBooking();
            return of(true);
          } else {
            return of(false);
          }
        }),
        takeUntil(this._destroySubject)
      );
  }
  
  
  private openNormalAbandonBooking(): Observable<boolean> {
    return this._store.dispatch(new BookingActions.GetAbandonBookingReasons(false))
    .pipe(
      switchMap(() => {
        return this._dialog.open<GenericSurveySelectDialogComponent, GenericSurveySelectDialogDataModel, number>(GenericSurveySelectDialogComponent, {
          width: '492px',
          panelClass:'mat-dialog-border-normal-radius',
          autoFocus: false,
          position: {
            top: '100px'
          },
          data: {
            title: 'Abandon Booking',
            selectName: 'Select the reason for abandoning the booking',
            array: this.abandonBookingReasons?.map(item => ({
              id: item.id,
              viewValue: item.name
            }))
          }
        })
        .afterClosed()
        .pipe(
          switchMap((response: number | undefined): Observable<boolean> => {
            if (response !== undefined && this.bookingId) {
              const model: AbandonBookingModel = {
                id: this.bookingId,
                abandonReasonId: response
              };
              return this._store.dispatch(new BookingActions.AbandonBooking(model))
                .pipe(
                  map(() => {
                    return true;
                  })
                );
            }
            if (response === undefined && this.bookingId) {
              return of(false);
            }
            return of(false);
          })
        );
      }),
      takeUntil(this._destroySubject)
    );
  }
  
  protected _silentAbandonBooking(): void {
    if(this.bookingId) {
      this._store.dispatch(new BookingActions.AbandonBooking({id: this.bookingId, abandonReasonId: null}))
      .pipe(
        switchMap(() => this._store.dispatch(new AccountActions.ClearPreviousId())),
        takeUntil(this._destroySubject)
      )
      .subscribe();
    } else {
      this._store.dispatch(BookingActions.ClearAll);
    }
  }

}

export class MatSelectStateMatcher {
  private readonly _valid: boolean | null;
  constructor(isValid: boolean | null) {
    this._valid = isValid;
  }
  isErrorState(): boolean {
    return !this._valid;
  }
}