import { Injectable } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class UrlTrackingService {
	private nextUrlSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
	nextUrl$: Observable<string | null> = this.nextUrlSubject.asObservable();

	constructor(private router: Router) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				this.nextUrlSubject.next(event.url);
			}
		});
	}
}
