import { Action, Selector, State, StateContext } from "@ngxs/store";
import { CommonStateModel } from "../models/common-state.model";
import { Injectable } from "@angular/core";
import { NoteImportance } from "../../models/note/note-importance";
import { CommonActions } from "./common.actions";
import { AppointmentTypeService, AttendanceService, BusinessAccountService, NoteService, PaymentService, PriceListService, UserService, VoucherService } from "../../providers/services";
import { Observable, tap } from "rxjs";
import { ResponseModel } from "../../models/base/response-model";
import { DropdownResponseBaseModel } from "../../models/base/dropdown-response.model";
import { RegionService } from "../../providers/services/region.service";
import { AppointmentTypeSummary } from "../../models/appointment-type/appointment-types.model";
import { BusinessAccountAppointmentTypeModel } from "../../models/business-accounts/business-account-appointment-type.model";
import { BusinessAccountUserModel } from "../../models/business-accounts/business-account-user.model";
import { BusinessAccountReferralCodesModel } from "../../models/business-accounts/business-account-referral-codes.model";
import { BusinessAccountVoucherCodeModel } from "../../models/business-accounts/business-account-voucher-code.model";
import { PractitionerService } from "../../providers/services/practitioner.service";

@State<CommonStateModel>({
    name: 'common',
    defaults: {
        noteImportanceList: null,
        attendanceStatuses: null,
        regions: null,
        businessTypes: null,
        bookingMethods: null,
        businessAccountClosureReasons: null,
        businessAccountPackageTypes: null,
        creditLimits: null,
        companyIntroSources: null,
        numberOfDriverTypes: null,
        paymentTerms: null,
        voucherTypes: null,
        businessAccounts: null,
        appointmentTypes: null,
        businessAccountAppointmentTypes: null,
        businessAccountUsers: null,
        businessAccountReferralCodes: null,
        businessAccountVouchers: null,
        userRoles: null,
        practitionerLevels: null,
        priceLists: null
    }
})

@Injectable()
export class CommonState {
    constructor(
        private readonly _noteService: NoteService,
        private readonly _attendanceService: AttendanceService,
        private readonly _regionsService: RegionService,
        private readonly _businessAccountService: BusinessAccountService,
        private readonly _paymentService: PaymentService,
        private readonly _voucherService: VoucherService,
        private readonly _appointmentTypeService: AppointmentTypeService,
        private readonly _userService: UserService,
        private readonly _practitionerService: PractitionerService,
        private readonly _priceListsService: PriceListService
    ) {
    }

    @Selector()
    static getNoteImportanceList(state: CommonStateModel): NoteImportance[] | null {
        return state.noteImportanceList
    }

    @Selector()
    static getAttendanceStatuses(state: CommonStateModel): DropdownResponseBaseModel[] | null {
        return state.attendanceStatuses
    }
    @Selector()
    static getRegions(state: CommonStateModel): DropdownResponseBaseModel[] | null {
        return state.regions;
    }

    @Selector()
    static getBookingMethods(state: CommonStateModel): DropdownResponseBaseModel[] | null {
        return state.bookingMethods;
    }

    @Selector()
    static getBusinessTypes(state: CommonStateModel): DropdownResponseBaseModel[] | null {
        return state.businessTypes;
    }

    @Selector()
    static getBusinessAccountClosureReasons(state: CommonStateModel): DropdownResponseBaseModel[] | null {
        return state.businessAccountClosureReasons;
    }

    @Selector()
    static getBusinessAccountPackageTypes(state: CommonStateModel): DropdownResponseBaseModel[] | null {
        return state.businessAccountPackageTypes;
    }

    @Selector()
    static getNumberOfDriverTypes(state: CommonStateModel): DropdownResponseBaseModel[] | null {
        return state.numberOfDriverTypes;
    }

    @Selector()
    static getPaymentTerms(state: CommonStateModel): DropdownResponseBaseModel[] | null {
        return state.paymentTerms;
    }

    @Selector()
    static getCompanyIntroSources(state: CommonStateModel): DropdownResponseBaseModel[] | null {
        return state.companyIntroSources;
    }

    @Selector()
    static getCreditLimits(state: CommonStateModel): DropdownResponseBaseModel[] | null {
        return state.creditLimits;
    }

    @Selector()
    static getVoucherTypes(state: CommonStateModel): DropdownResponseBaseModel[] | null {
        return state.voucherTypes;
    }

    @Selector()
    static getBusinessAccounts(state: CommonStateModel): DropdownResponseBaseModel[] | null {
        return state.businessAccounts;
    }

    @Selector()
    static getAppointmentTypes(state: CommonStateModel): AppointmentTypeSummary[] | null {
        return state.appointmentTypes;
    }

    @Selector()
    static getBusinessAccountAppointmentTypes(state: CommonStateModel): BusinessAccountAppointmentTypeModel[] | null {
        return state.businessAccountAppointmentTypes;
    }

    @Selector()
    static getBusinessAccountAccountUsers(state: CommonStateModel): BusinessAccountUserModel[] | null {
        return state.businessAccountUsers;
    }

    @Selector()
    static businessAccountReferralCodes(state: CommonStateModel): BusinessAccountReferralCodesModel | null {
        return state.businessAccountReferralCodes;
    }

    @Selector()
    static getBusinessAccountVouchers(state: CommonStateModel): BusinessAccountVoucherCodeModel[] | null {
        return state.businessAccountVouchers;
    }

    @Selector()
    static getUserRoles(state: CommonStateModel): DropdownResponseBaseModel[] | null {
        return state.userRoles;
    }

    @Selector()
    static getPractitionerLevels(state: CommonStateModel): DropdownResponseBaseModel[] | null {
        return state.practitionerLevels;
    }

    @Selector()
    static getPriceLists(state: CommonStateModel): DropdownResponseBaseModel[] | null {
        return state.priceLists;
    }

    @Action(CommonActions.GetNoteImportanceList)
    getNoteImportanceList(ctx: StateContext<CommonStateModel>, action: CommonActions.GetNoteImportanceList): Observable<ResponseModel<NoteImportance[]>> {
        return this._noteService.getNoteImportance()
            .pipe(
                tap((response: ResponseModel<NoteImportance[]>) => {
                    ctx.patchState({
                        noteImportanceList: response.content
                    })
                })
            )
    }

    @Action(CommonActions.GetAttendedStatuses)
    getAttendedStatuses(ctx: StateContext<CommonStateModel>, action: CommonActions.GetAttendedStatuses): Observable<ResponseModel<NoteImportance[]>> {
        return this._attendanceService.getAttendances()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        attendanceStatuses: response.content
                    })
                })
            )
    }

    @Action(CommonActions.GetRegions)
    getRegions(ctx: StateContext<CommonStateModel>, action: CommonActions.GetRegions): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._regionsService.getRegions()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        regions: response.content
                    })
                })
            );
    }

    @Action(CommonActions.GetBusinessTypes)
    getBusinessTypes(ctx: StateContext<CommonStateModel>, action: CommonActions.GetBusinessTypes): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._businessAccountService.getBusinessTypes()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        businessTypes: response.content
                    })
                })
            );
    }

    @Action(CommonActions.GetBookingMethods)
    getBookingMethods(ctx: StateContext<CommonStateModel>, action: CommonActions.GetBookingMethods): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._businessAccountService.getBookingMethods()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        bookingMethods: response.content
                    })
                })
            );
    }


    @Action(CommonActions.GetBusinessAccountClosureReasons)
    getBusinessAccountClosureReasons(ctx: StateContext<CommonStateModel>, action: CommonActions.GetBusinessAccountClosureReasons): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._businessAccountService.getClosureReasons()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        businessAccountClosureReasons: response.content
                    })
                })
            );
    }

    @Action(CommonActions.GetBusinessAccountPackageTypes)
    getBusinessAccountPackageTypes(ctx: StateContext<CommonStateModel>, action: CommonActions.GetBusinessAccountPackageTypes): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._businessAccountService.getAccountPackageTypes()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        businessAccountPackageTypes: response.content
                    })
                })
            );
    }

    @Action(CommonActions.GetCompanyIntroSources)
    getCompanyIntroSources(ctx: StateContext<CommonStateModel>, action: CommonActions.GetCompanyIntroSources): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._businessAccountService.getCompanyIntroSources()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        companyIntroSources: response.content
                    })
                })
            );
    }

    @Action(CommonActions.GetCreditLimits)
    getCreditLimits(ctx: StateContext<CommonStateModel>, action: CommonActions.GetCreditLimits): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._businessAccountService.getCreditLimits()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        creditLimits: response.content
                    })
                })
            );
    }

    @Action(CommonActions.GetPaymentTerms)
    getGetPaymentTerms(ctx: StateContext<CommonStateModel>, action: CommonActions.GetCreditLimits): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._paymentService.getPaymentTerms()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        paymentTerms: response.content
                    })
                })
            );
    }

    @Action(CommonActions.GetVoucherTypes)
    getVoucherTypes(ctx: StateContext<CommonStateModel>, action: CommonActions.GetVoucherTypes): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._voucherService.getVoucherTypes()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        voucherTypes: response.content
                    })
                })
            );
    }

    @Action(CommonActions.GetNumberOfDriverTypes)
    getNumberOfDriverTypes(ctx: StateContext<CommonStateModel>, action: CommonActions.GetNumberOfDriverTypes): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._businessAccountService.getNumberOfDriverTypes()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        numberOfDriverTypes: response.content
                    })
                })
            );
    }

    @Action(CommonActions.GetBusinessAccounts)
    getBusinessAccounts(ctx: StateContext<CommonStateModel>, action: CommonActions.GetBusinessAccounts): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._businessAccountService.getBusinessAccounts(action.filter)
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        businessAccounts: response.content
                    })
                })
            )
    }

    @Action(CommonActions.GetAppointmentTypesByFilter)
    getAppointmentTypesByFilter(ctx: StateContext<CommonStateModel>, action: CommonActions.GetAppointmentTypesByFilter): Observable<ResponseModel<AppointmentTypeSummary[]>> {
        return this._appointmentTypeService.getAppointmentTypesByFilter(action.filter, null, null)
            .pipe(
                tap((response: ResponseModel<AppointmentTypeSummary[]>) => {
                    ctx.patchState({
                        appointmentTypes: response.content
                    })
                })
            )
    }

    @Action(CommonActions.GetBusinessAccountAppointmentTypes)
    getBusinessAccountAppointmentTypes(ctx: StateContext<CommonStateModel>, action: CommonActions.GetBusinessAccountAppointmentTypes): Observable<ResponseModel<BusinessAccountAppointmentTypeModel[]>> {
        return this._businessAccountService.getBusinessAccountAppointmentTypes(action.id)
            .pipe(
                tap((response: ResponseModel<BusinessAccountAppointmentTypeModel[]>) => {
                    ctx.patchState({
                        businessAccountAppointmentTypes: response.content
                    })
                })
            )
    }

    @Action(CommonActions.GetBusinessAccountAccountUsers)
    getBusinessAccountAccountUsers(ctx: StateContext<CommonStateModel>, action: CommonActions.GetBusinessAccountAccountUsers): Observable<ResponseModel<BusinessAccountUserModel[]>> {
        return this._businessAccountService.getBusinessAccountUsers(action.name)
            .pipe(
                tap((response: ResponseModel<BusinessAccountUserModel[]>) => {
                    ctx.patchState({
                        businessAccountUsers: response.content
                    })
                })
            )
    }

    @Action(CommonActions.GetBusinessAccountReferralCodes)
    getBusinessAccountReferralCodes(ctx: StateContext<CommonStateModel>, action: CommonActions.GetBusinessAccountReferralCodes): Observable<ResponseModel<BusinessAccountReferralCodesModel>> {
        return this._businessAccountService.getBusinessAccountReferralCodes(action.id)
            .pipe(
                tap((response: ResponseModel<BusinessAccountReferralCodesModel>) => {
                    ctx.patchState({
                        businessAccountReferralCodes: response.content
                    })
                })
            )
    }

    @Action(CommonActions.GetBusinessAccountVouchers)
    getBusinessAccountVouchers(ctx: StateContext<CommonStateModel>, action: CommonActions.GetBusinessAccountVouchers): Observable<ResponseModel<BusinessAccountVoucherCodeModel[]>> {
        return this._businessAccountService.getBusinessAccountVouchers(action.id)
            .pipe(
                tap((response: ResponseModel<BusinessAccountVoucherCodeModel[]>) => {
                    ctx.patchState({
                        businessAccountVouchers: response.content
                    })
                })
            )
    }

    @Action(CommonActions.GetUserRoles)
    getUserRoles(ctx: StateContext<CommonStateModel>, action: CommonActions.GetUserRoles): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._userService.getRoles()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        userRoles: response.content
                    })
                })
            )
    }
    
    @Action(CommonActions.GetPractitionerLevels)
    getPractitionerLevels(ctx: StateContext<CommonStateModel>, action: CommonActions.GetPractitionerLevels): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._practitionerService.getLevels()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        practitionerLevels: response.content
                    })
                })
            )
    }

    @Action(CommonActions.AddBusinessAccountAppointmentTypes)
    addBusinessAccountAppointmentTypes(ctx: StateContext<CommonStateModel>, action: CommonActions.AddBusinessAccountAppointmentTypes): Observable<ResponseModel<void>> {
        return this._businessAccountService.addBusinessAccountAppointmentType(action.model);
    }

    @Action(CommonActions.AddBusinessAccountVoucher)
    addBusinessAccountVoucher(ctx: StateContext<CommonStateModel>, action: CommonActions.AddBusinessAccountVoucher): Observable<ResponseModel<void>> {
        return this._businessAccountService.addBusinessAccountVoucher(action.model);
    }

    @Action(CommonActions.GenerateBusinessAccountReferralCodes)
    generateBusinessAccountReferralCodes(ctx: StateContext<CommonStateModel>, action: CommonActions.GenerateBusinessAccountReferralCodes): Observable<ResponseModel<void>> {
        return this._businessAccountService.generateBusinessAccountReferralCodes(action.id, action.count, action.expireDate, action.validFromDate);
    }

    @Action(CommonActions.AddBusinessAccountUser)
    addBusinessAccountUser(ctx: StateContext<CommonStateModel>, action: CommonActions.AddBusinessAccountUser): Observable<ResponseModel<void>> {
        return this._businessAccountService.addBusinessAccountUser(action.id, action.email, action.firstName, action.lastName);
    }

    @Action(CommonActions.EditBusinessAccountAppointmentTypes)
    editBusinessAccountAppointmentTypes(ctx: StateContext<CommonStateModel>, action: CommonActions.EditBusinessAccountAppointmentTypes): Observable<ResponseModel<void>> {
        return this._businessAccountService.editBusinessAccountAppointmentType(action.id, action.cost);
    }

    @Action(CommonActions.BlockBusinessAccountUser)
    blockBusinessAccountUser(ctx: StateContext<CommonStateModel>, action: CommonActions.BlockBusinessAccountUser): Observable<ResponseModel<void>> {
        return this._businessAccountService.blockBusinessAccountUser(action.id);
    }

    @Action(CommonActions.UpdateBusinessAccountUser)
    updateBusinessAccountUser(ctx: StateContext<CommonStateModel>, action: CommonActions.UpdateBusinessAccountUser): Observable<ResponseModel<void>> {
        return this._businessAccountService.updateBusinessAccountUser(action.id, action.firstName, action.lastName);
    }

    @Action(CommonActions.UpdateBusinessAccountVoucher)
    updateBusinessAccountVoucher(ctx: StateContext<CommonStateModel>, action: CommonActions.UpdateBusinessAccountVoucher): Observable<ResponseModel<void>> {
        return this._businessAccountService.editBusinessAccountVoucher(action.voucher);
    }

    @Action(CommonActions.RevertBusinessAccountAppointmentTypes)
    revertBusinessAccountAppointmentTypes(ctx: StateContext<CommonStateModel>, action: CommonActions.RevertBusinessAccountAppointmentTypes): Observable<ResponseModel<void>> {
        return this._businessAccountService.revertBusinessAccountAppointmentType(action.id);
    }

    @Action(CommonActions.VoidBusinessAccountReferralCodes)
    voidBusinessAccountReferralCodes(ctx: StateContext<CommonStateModel>, action: CommonActions.VoidBusinessAccountReferralCodes): Observable<ResponseModel<void>> {
        return this._businessAccountService.voidBusinessAccountReferralCodes(action.id, action.model);
    }

    @Action(CommonActions.VoidBusinessAccountVoucher)
    voidBusinessAccountVoucher(ctx: StateContext<CommonStateModel>, action: CommonActions.VoidBusinessAccountVoucher): Observable<ResponseModel<void>> {
        return this._businessAccountService.voidBusinessAccountVoucher(action.id);
    }
    @Action(CommonActions.DeleteBusinessAccountAppointmentTypes)
    deleteBusinessAccountAppointmentTypes(ctx: StateContext<CommonStateModel>, action: CommonActions.DeleteBusinessAccountAppointmentTypes): Observable<ResponseModel<void>> {
        return this._businessAccountService.deleteBusinessAccountAppointmentType(action.id);
    }

    @Action(CommonActions.ClearBusinessAccounts)
    clearBusinessAccounts(ctx: StateContext<CommonStateModel>, action: CommonActions.ClearBusinessAccounts): void {
        ctx.patchState({
            businessAccounts: null
        })
    }

    @Action(CommonActions.ClearReferralsUsersAndVouchers)
    clearReferralsUsersAndVouchers(ctx: StateContext<CommonStateModel>, action: CommonActions.ClearReferralsUsersAndVouchers): void {
        ctx.patchState({
            businessAccountReferralCodes: null,
            businessAccountUsers: null,
            businessAccountVouchers: null
        })
    }

    @Action(CommonActions.GetPriceLists)
    getPriceLists(ctx: StateContext<CommonStateModel>, action: CommonActions.GetPriceLists): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._priceListsService.getPriceLists()
            .pipe(
                tap((response: ResponseModel<DropdownResponseBaseModel[]>) => {
                    ctx.patchState({
                        priceLists: response.content
                    })
                })
            );
    }
}