import { GlobalConfig, ToastrService, ActiveToast } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastrMessagesService {
  private _options: GlobalConfig;
  private _title: string = '';
  constructor(private readonly toastr: ToastrService) {
    this._options = this.toastr.toastrConfig;
    this._options.progressBar = true;
    this._options.closeButton = true;
    this._options.preventDuplicates = true;
    this._options.countDuplicates = true;
  }
  public error(message: string): ActiveToast<any> {
    return this.toastr.error(message, this._title);
  }
  public info(message: string): ActiveToast<any> {
    return this.toastr.info(message, this._title);
  }
  public warning(message: string): ActiveToast<any> {
    return this.toastr.warning(message, this._title);
  }
  public success(message: string): ActiveToast<any> {
    return this.toastr.success(message, this._title);
  }
}