import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseModel } from '../../models/base/response-model';
import { Observable } from 'rxjs';
import { DropdownResponseBaseModel } from '../../models/base/dropdown-response.model';
import { environment } from 'src/environments/environment';
import { ProtectedEndpointsConstant } from '../../constants/protected-endpoints.constant';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(private readonly _http: HttpClient) { }
  getRegions(): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
    return this._http.get<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.REGIONS}`);
  }

  getLocations(): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
    return this._http.get<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.REGIONS}/locations`);
  }
}
