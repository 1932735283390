import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { ToastrMessagesService } from '../services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private readonly _router: Router, private readonly _toastr: ToastrMessagesService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(catchError(err => {
                const errorMessage = err.error?.ErrorMessage || err.message ||  err.statusText;
                
                if(err.status === 401){
                    this._toastr.info(errorMessage);
                    this._router.navigate(['insufficient-permissions']);
                    return throwError(() => new Error(errorMessage))
                }

                if(err.status === 0 || (err.status === 404)) {
                    this._toastr.error(errorMessage);
                    return throwError(() => new Error(errorMessage))
                }

                if(err.status === 400 || err.error && !err.error.IsSuccess || err.error && err.error.ResponseCode === 400){
                    this._toastr.info(errorMessage);
                    return throwError(() => new Error(errorMessage))
                }

                this._toastr.error(errorMessage);
                return throwError(() => new Error(errorMessage));
            }))
    }
}