import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ProtectedEndpointsConstant } from "../../constants/protected-endpoints.constant";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { ResponseModel } from "../../models/base/response-model";
import { PriceListModel } from "../../models/price-list/price-list.model";
import { PublicIdResponseModel } from "../../models/base/public-id-response-model";
import { DropdownResponseBaseModel } from "../../models/base/dropdown-response.model";
import { PriceListAdminModel } from "../../models/price-list/price-list-admin.model";
import {PriceListEditModel} from "../../models/price-list/price-list-edit.model";
import { PriceListChargeModel } from "../../models/price-list/price-list-charge.model";
import { PriceListChargeEditModel } from "../../models/price-list/price-list-charge-edit.model";

@Injectable({
    providedIn: 'root'
})
export class PriceListService {
    constructor(private readonly _http: HttpClient) { }

    getPriceListsAdmin(isActive: boolean): Observable<ResponseModel<PriceListAdminModel[]>> {
        return this._http.get<ResponseModel<PriceListAdminModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.PRICE_LISTS_ADMIN}?isActive=${isActive}`);
    }

    getByPublicId(publicId: string): Observable<ResponseModel<PriceListAdminModel>> {
        return this._http.get<ResponseModel<PriceListAdminModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.PRICE_LISTS_ADMIN}/${publicId}`);
    }

    addPriceList(model: PriceListEditModel): Observable<ResponseModel<PublicIdResponseModel>> {
        return this._http.post<ResponseModel<PublicIdResponseModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.PRICE_LISTS_ADMIN}`, model);
    }

    editPriceList(publicId: string, model: PriceListEditModel): Observable<ResponseModel<PublicIdResponseModel>> {
        return this._http.put<ResponseModel<PublicIdResponseModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.PRICE_LISTS_ADMIN}/${publicId}`, model);
    }

    getPriceLists(): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
        return this._http.get<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.PRICE_LISTS}`);
    }

    getPriceListCharges(priceListPublicId: string): Observable<ResponseModel<PriceListChargeModel[]>> {
        return this._http.get<ResponseModel<PriceListChargeModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.PRICE_LISTS}/${priceListPublicId}/charge`);
      }
    
    addPriceListCharge(priceListPublicId: string, model: PriceListChargeEditModel): Observable<ResponseModel<PublicIdResponseModel>> {
        return this._http.post<ResponseModel<PublicIdResponseModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.PRICE_LISTS}/${priceListPublicId}/charge`, model);
    }
    
    editPriceListCharge(priceListPublicId: string, priceListChargePublicId: string, model: PriceListChargeEditModel): Observable<ResponseModel<PublicIdResponseModel>> {
        return this._http.put<ResponseModel<PublicIdResponseModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.PRICE_LISTS}/${priceListPublicId}/charge/${priceListChargePublicId}`, model);
    }
    
    deletePriceListCharge(priceListPublicId: string, priceListChargePublicId: string): Observable<void> {
        return this._http.delete<void>(`${environment.baseUrl}${ProtectedEndpointsConstant.PRICE_LISTS}/${priceListPublicId}/charge/${priceListChargePublicId}`);
    }    
}