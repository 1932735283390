<div *ngIf="notes !== null" class="note-with-crud-container">
    <div class="note-with-crud-header">
        <div>
            <span *ngIf="showLabel"> Notes </span>
        </div>
        <div>
            <button (click)="onAddNoteClick()" class="note-with-crud-button-add" mat-stroked-button>Add Note</button>
        </div>
    </div>
    <div class="note-with-crud-body-base" [ngClass]="bodyClass">
        <div *ngFor="let noteItem of notes; let index = index;" class="note-with-crud-message">
            <div class="note-with-crud-message-text">
                <span *ngIf="!noteItem.isEdit else noteEdit">{{noteItem.noteText}}</span>
                    <ng-template #noteEdit>
                        <mat-form-field appearance="outline" class="material-form-field-input-outline full-width">
                            <textarea [(ngModel)]="noteItem.noteEdit" [rows]="5" matInput></textarea>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="material-form-field-input-outline material-form-field-select-outline">
                            <mat-label>Note Importance</mat-label>
                            <mat-select [(ngModel)]="noteItem.noteImportanceId">
                                <mat-option *ngFor="let item of noteImportanceList$ | async" [value]="item.id"
                                    class="mat-form-option-select-outline">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-template>
            </div>
            <div class="note-with-crud-message-footer">
                <div>
                    <span class="note-with-crud-message-date">{{getFormattedUkDate(noteItem.createdAt, true)}} by {{noteItem.createByUser}}</span>
                </div>
                <div class="note-with-crud-message-footer-buttons">
                    <button *ngIf="noteItem.id && !noteItem.isEdit" (click)="onDeleteNoteClick(noteItem.id)" class="note-with-crud-button-edit" mat-button> <mat-icon>delete</mat-icon> Delete</button> 
                    <button *ngIf="!noteItem.isEdit" (click)="onEditNoteClick(noteItem)" class="note-with-crud-button-delete" mat-button><mat-icon>edit</mat-icon> Edit</button>
                    <button  *ngIf="noteItem.isEdit" (click)="onEditCancelClick(noteItem, index)" class="note-with-crud-button-delete" mat-button><mat-icon>cancel</mat-icon> Cancel</button> 
                    <button  *ngIf="noteItem.isEdit" [disabled]="!(noteItem.noteEdit.length > 0)" (click)="onEditNoteSaveClick(noteItem)" class="note-with-crud-button-delete" mat-button><mat-icon>save</mat-icon> Save</button>  
                </div>
            </div>
        </div>
    </div>
</div>