import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProtectedEndpointsConstant } from '../../constants/protected-endpoints.constant';
import { ResponseModel } from '../../models/base/response-model';
import { Observable } from 'rxjs';
import { SimilarCustomerModel } from '../../models/customers/similar-customer.model';
import { CustomerSummaryModel } from '../../models/customers/customer-summary.model';
import { ExistingCustomerModel } from '../../models/customers/existing-customers.model';
import { ExistingCustomerFilterModel } from '../../models/customers/existing-customers-filter.model';
import { UpdateCustomerNoteModel } from '../../models/customers/update-customer-note.model';
import { AddCustomerNoteModel } from '../../models/customers/add-customer-note.model';
import { CustomerNoteModel } from '../../models/customers/customer-note.model';
import { CustomerFile } from '../../models/customers/customer-file.model';
import { UpdateCustomerDetailsModel } from '../../models/customers/update-customer-details.model';
import { BlockCustomerModel } from '../../models/customers/block-customer.model';
import { CustomerAppointmentModel } from '../../models/customers/customer-appointment.model';
import { DropdownResponseBaseModel } from '../../models/base/dropdown-response.model';
import { CustomerAllAppointmentModel } from '../../models/customers/customer-all-appointment.model';
import { CustomerHistoryModel } from '../../models/customers/customer-history.model';
import { UnblockCustomerModel } from '../../models/customers/unblock-customer.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private readonly _http: HttpClient) { }

  getSimilarCustomers(firstName: string, lastName: string, dateOfBirth: string | null): Observable<ResponseModel<SimilarCustomerModel[]>> {
    return this._http.get<ResponseModel<SimilarCustomerModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CUSTOMERS}/similar-customers?firstName=${firstName}&lastName=${lastName}&dateOfBirth=${dateOfBirth}`);
  }

  getCustomerDetails(id: number): Observable<ResponseModel<CustomerSummaryModel>> {
    return this._http.get<ResponseModel<CustomerSummaryModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CUSTOMERS}/${id}`);
  }

  getCustomerSummary(bookingId: number): Observable<ResponseModel<CustomerSummaryModel>> {
    return this._http.get<ResponseModel<CustomerSummaryModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CUSTOMERS}/${bookingId}/summary`);
  }

  getCustomerFiles(id: number): Observable<ResponseModel<CustomerFile[]>> {
    return this._http.get<ResponseModel<CustomerFile[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CUSTOMERS}/${id}/files`);
  }

  getNearestAppointment(id: number): Observable<ResponseModel<CustomerAppointmentModel>> {
    return this._http.get<ResponseModel<CustomerAppointmentModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CUSTOMERS}/${id}/nearest-appointment`);
  }

  getAppointments(id: number, count: number | null): Observable<ResponseModel<CustomerAllAppointmentModel[]>> {
    return this._http.get<ResponseModel<CustomerAllAppointmentModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CUSTOMERS}/${id}/appointments?count=${count}`);
  }

  getAppointment(customerId: number, appointmentId: number): Observable<ResponseModel<CustomerAppointmentModel>> {
    return this._http.get<ResponseModel<CustomerAppointmentModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CUSTOMERS}/${customerId}/${appointmentId}`);
  }

  getHistory(id: number, count: number | null): Observable<ResponseModel<CustomerHistoryModel[]>> {
    return this._http.get<ResponseModel<CustomerHistoryModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CUSTOMERS}/${id}/history`);
  }

  getCustomersBySearchFilter(model: ExistingCustomerFilterModel): Observable<ResponseModel<ExistingCustomerModel[]>> {
    let params: HttpParams = new HttpParams();
    Object.keys(model)
      .forEach((key: string) => {
        const obj: {
          [k: string]: any
        } = model;
        if (obj[key]) {
          params = params.append(key, obj[key])
        }
      });

    return this._http.get<ResponseModel<ExistingCustomerModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CUSTOMERS}/search-filters`, { params });
  }

  getCustomerNotes(customerId: number): Observable<ResponseModel<CustomerNoteModel[]>> {
    return this._http.get<ResponseModel<CustomerNoteModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CUSTOMERS}/${customerId}/notes`);
  }

  getCustomerNoteTypes(): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
    return this._http.get<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CUSTOMERS}/note-types`);
  }


  addCustomerNotes(customerId: number, model: AddCustomerNoteModel): Observable<ResponseModel<void>> {
    return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CUSTOMERS}/${customerId}/notes`, model);
  }
  addCustomerFile(id: number, data: FormData): Observable<ResponseModel<void>> {
    return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CUSTOMERS}/${id}/file`, data);
  }

  blockCustomerNotes(customerId: number, model: BlockCustomerModel): Observable<ResponseModel<void>> {
    return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CUSTOMERS}/${customerId}/block`, model);
  }

  unblockCustomerNotes(customerId: number, model: UnblockCustomerModel): Observable<ResponseModel<void>> {
    return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CUSTOMERS}/${customerId}/unblock`, model);
  }

  updateCustomerNotes(noteId: number, model: UpdateCustomerNoteModel): Observable<ResponseModel<void>> {
    return this._http.put<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CUSTOMERS}/notes/${noteId}`, model);
  }

  updateCustomerDetails(id: number, model: UpdateCustomerDetailsModel): Observable<ResponseModel<void>> {
    return this._http.put<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CUSTOMERS}/${id}`, model);
  }

  deleteCustomerNotes(noteId: number): Observable<ResponseModel<void>> {
    return this._http.delete<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.CUSTOMERS}/notes/${noteId}`);
  }
}