<div class="generic-edit-mat-table-container">
    <table mat-table [dataSource]="dataSourceProps" aria-describedby="Generic admin table">
        <ng-container [matColumnDef]="col.key" *ngFor="let col of columnsSchemaProps">
            <th mat-header-cell *matHeaderCellDef [ngSwitch]="col.key">
                <span *ngSwitchDefault>{{ col.label }}</span>
            </th>
            <td mat-cell *matCellDef="let element">
                <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
                    <div class="btn-edit" *ngSwitchCase="'isEdit'">
                        <button mat-button (click)="element.isEdit = !element.isEdit">
                            Edit
                        </button>
                        <button *ngIf="shouldShowDelete" mat-button (click)="onDeleteClick(element)">
                            Delete
                        </button>
                    </div>
                    <span *ngSwitchCase="'date'">
                        {{ element[col.key] | date: 'mediumDate' }}
                    </span>
                    <ng-container *ngSwitchCase="'isActive'">
                        <mat-checkbox class="material-form-field-check-box" (change)="element.isActive = $event.checked"
                            [checked]="element.isActive" disabled></mat-checkbox>
                    </ng-container>
                    <span *ngSwitchCase="'autocomplete'">
                        {{ element[col.key]?.name  }}
                    </span>
                    <span *ngSwitchCase="'select'">
                        {{ element[col.key]?.name  }}
                    </span>
                    <span *ngSwitchCase="'multiSelect'">
                        {{ displayNames(element[col.key])  }}
                    </span>
                    <span *ngSwitchDefault>
                        {{ element[col.key] }}
                    </span>
                </div>
                <div [ngSwitch]="col.type" *ngIf="element.isEdit" class="generic-edit-mat-table-input-container">
                    <div class="btn-edit" *ngSwitchCase="'isEdit'">
                        <button mat-button (click)="editRow(element)" [disabled]="disableSubmit(element.id)">
                            Done
                        </button>
                    </div>
                    <ng-container *ngSwitchCase="'date'">
                        <mat-form-field class="material-form-field-input-fill"  appearance="fill">
                            <mat-label>Choose a date</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="element[col.key]" [required]="col.required" />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-hint *ngIf="col?.example"> {{col?.example}}</mat-hint>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngSwitchCase="'boolean'">
                        <mat-checkbox class="material-form-field-check-box" (change)="element[col.key] = $event.checked"
                            [checked]="element[col.key]"></mat-checkbox>
                    </ng-container>
                    <ng-container *ngSwitchCase="'isActive'">
                        <mat-checkbox class="material-form-field-check-box" (change)="element.isActive = $event.checked"
                            [checked]="element.isActive"></mat-checkbox>
                    </ng-container>
                    <ng-container  *ngSwitchCase="'autocomplete'">
                        <mat-form-field class="material-form-field-input-fill">
                            <input type="text" matInput [(ngModel)]="element[col.key]" (keyup)="onAutocompleteKeyUp($event, col.key)"
                                [matAutocomplete]="auto" [required]="col.required">
                            <mat-autocomplete #auto="matAutocomplete"  [displayWith]="displayNameFn" (closed)="onClosedAutocompleteMenu(col.key)">
                                <mat-option *ngFor="let type of col.array | async" [value]="type">
                                    {{type.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngSwitchCase="'select'">
                        <mat-form-field class="material-form-field-input-fill">
                            <mat-select [(ngModel)]="element[col.key]" [compareWith]="compareById" [required]="col.required">
                                <mat-option
                                    *ngFor="let type of col.array | async" [value]="type">
                                    {{type.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngSwitchCase="'multiSelect'">
                        <mat-form-field class="material-form-field-input-fill">
                            <mat-select [(ngModel)]="element[col.key]" [compareWith]="compareById" multiple [required]="col.required">
                                <mat-option
                                    *ngFor="let type of col.array | async" [value]="type">
                                    {{type.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <mat-form-field class="material-form-field-input-fill field-example" *ngSwitchDefault>
                        <input matInput [required]="col.required" [pattern]="col.pattern" [type]="col.type"
                            [(ngModel)]="element[col.key]" (change)="inputHandler($event, element.id, col.key)" [type]="col.key" [disabled]="col.editDisabled && element.id !== 0 " />
                            <mat-hint *ngIf="col?.example"> {{col?.example}}</mat-hint>
                    </mat-form-field>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
