import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ProtectedEndpointsConstant } from "../../constants/protected-endpoints.constant";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { ResponseModel } from "../../models/base/response-model";
import { MarketingSurveyOptionModel } from "../../models/marketing-survey-option/marketing-survey-option.model";
import { IdResponseModel } from "../../models/base/id-response-model";
import { AddMarketingSurveyOptionModel } from "../../models/marketing-survey-option/add-marketing-survey-option.model";
import { EditMarketingSurveyOptionModel } from "../../models/marketing-survey-option/edit-marketing-survey-option.model";

@Injectable({
    providedIn: 'root'
})
export class MarketingSurveyOptionService {
    constructor(private readonly _http: HttpClient) { }

    getMarketingSurveyOptions(isFullNeeded: boolean): Observable<ResponseModel<MarketingSurveyOptionModel[]>> {
        return this._http.get<ResponseModel<MarketingSurveyOptionModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.MARKETING_SURVEY_OPTIONS}?isFullNeeded=${isFullNeeded}`);
    }

    addMarketingSurveyOption(model: AddMarketingSurveyOptionModel): Observable<ResponseModel<IdResponseModel>> {
        return this._http.post<ResponseModel<IdResponseModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.MARKETING_SURVEY_OPTIONS}`, model);
    }

    editMarketingSurveyOption(id: number, model: EditMarketingSurveyOptionModel): Observable<ResponseModel<IdResponseModel>> {
        return this._http.put<ResponseModel<IdResponseModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.MARKETING_SURVEY_OPTIONS}/${id}`, model);
    }
}