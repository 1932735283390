<div class="confirm-dialog-container">
  <div class="confirm-dialog-header">
    <div>
        <span>Confirmation</span>
    </div>
    <div class="confirm-dialog-body">
      <div>          
          <div class="confirm-dialog-summary-text">
            {{ data.message }}
          </div>
      </div>
      <div class="confirm-dialog-footer">
        <div>
            <button mat-button class="confirm-dialog-form-cancel"
                (click)="onCancelClick()">Cancel</button>
        </div>
        <div>
            <button mat-raised-button class="confirm-dialog-form-save"
                (click)="onConfirmClick()">Confirm</button>
        </div>
      </div>
    </div>
</div>