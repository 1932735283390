import { AppointmentStateModel } from "src/app/home/pages/booking/pages/appointment/appointment.component";
import { AppointmentStatusType } from "../../enums/appointment-status.type";
import { BookingStatusType } from "../../enums/booking-status.type";
import { AppointmentTypeLocalAuthority } from "../../models/appointment-type/appointment-type-local-authorities.model";
import { AppointmentTypeSummary } from "../../models/appointment-type/appointment-types.model";
import { AbandonBookingModel } from "../../models/booking/abandon-booking.model";
import { AddNoteAbandonBookingModel } from "../../models/booking/add-note-abandon-booking.model";
import { BusinessAccountModel } from "../../models/business-accounts/business-account.model";
import { ClinicScheduleModel } from "../../models/clinic-schedule/clinic-schedule.model";
import { AddressInfo } from "../../directives/google-address.directive";
import { AppointmentSummaryModel } from "../../models/appointment/appointment-summary.model";

export namespace BookingActions {
  export class SetBusinessAccount {
    static readonly type = '[Booking] SetBusinessAccount';
    constructor(public model: BusinessAccountModel) { }
  }

  export class SetReArrangedBookingId {
    static readonly type = '[Booking] SetReArrangedBookingId';
    constructor(public id: number | null) {}
  }

  export class GetVoucher {
    static readonly type = '[Booking] GetVoucher';
    constructor(public code: string | null, public businessAccountId: number | null, public appointmentTypeId: number | null) { }
  }

  export class GetBusinessAccounts {
    static readonly type = '[Booking] GetBusinessAccounts';
    constructor(public filter: string) { }
  }

  export class GetBusinessCompanyNotes {
    static readonly type = '[Booking] GetBusinessCompanyNotes';
    constructor(public id: number) { }
  }

  export class GetBusinessAccountAdditionalDetails {
    static readonly type = '[Booking] GetBusinessAccountAdditionalDetails';
  }

  export class CreateBooking {
    static readonly type = '[Booking] CreateBooking';
    constructor(public voucherId: number | null ) { }
  }

  export class ConfirmBooking {
    static readonly type = '[Booking] ConfirmBooking';
    constructor(public bookingId: number | null ) { }
  }

  export class ClearAll {
    static readonly type = '[Booking] ClearAll';
  }

  export class GetAppointmentTypesByFilter {
    static readonly type = '[Booking] GetAppointmentTypesByFilter';
    constructor(public filter: string) { }
  }

  export class GetFavouriteAppointmentTypes {
    static readonly type = '[Booking] GetFavouriteAppointmentTypes';
  }

  export class SetAppointmentType {
    static readonly type = '[Booking] SetAppointmentType';
    constructor(public model: AppointmentTypeSummary) { }
  }

  export class SetAppointmentCustomerId {
    static readonly type = '[Booking] SetAppointmentCustomerId';
    constructor(public id: number) { }
  }
  
  export class SetAppointmentSearchDate {
    static readonly type = '[Booking] SetAppointmentSearchDate';
    constructor(public date: string | null) { }
  }


  export class AbandonBooking {
    static readonly type = '[Booking] AbandonBooking';
    constructor(public model: AbandonBookingModel) { }
  }

  export class AddNoteAbandonBooking {
    static readonly type = '[Booking] AddNoteAbandonBooking';
    constructor(public model: AddNoteAbandonBookingModel) { }
  }

  export class GetAbandonBookingReasons {
    static readonly type = '[Booking] GetAbandonBookingReasons';
    constructor(public isFullNeeded: boolean) { }
  }

  export class GetAppointmentTypeLocalAuthority {
    static readonly type = '[Booking] GetAppointmentTypeLocalAuthority';
  }

  export class GetMarketingSurveyOptions {
    static readonly type = '[Booking] GetMarketingSurveyOptions';
    constructor(public isFullNeeded: boolean) { }
  }

  export class SetAppointmentTypeLocalAuthority {
    static readonly type = '[Booking] SetAppointmentTypeLocalAuthority';
    constructor(public model: AppointmentTypeLocalAuthority | null) { }
  }


  export class GetAppointmentSlots {
    static readonly type = '[Booking] GetAppointmentSlots';

    constructor(public venueId: number, public appointmentTypeId: number, public isBack: boolean, public isForward: boolean) { }
  }

  export class SetAppointmentSlot {
    static readonly type = '[Booking] SetAppointmentSlot';
    constructor(public venueId: number, public dayIndex: number, public slotIndex: number) { }
  }

  export class SetAppointmentSlotIsSelected {
    static readonly type = '[Booking] SetAppointmentSlotIsSelected';
    constructor(public venueId: number, public isSelected: boolean, public selectFirst: boolean | undefined) { }
  }
  export class SetAllAppointmentSlotUnselected {
    static readonly type = '[Booking] SetAllAppointmentSlotUnselected';
  }

  export class SetAppointmentSlotIsLoading {
    static readonly type = '[Booking] SetAppointmentSlotIsLoading';
    constructor(public venueId: number, public isLoading: boolean) { }
  }

  export class SetBookingStepUrl {
    static readonly type = '[Booking] SetBookingStepUrl';
    constructor(public url: string) { }
  }

  export class SetClinicSchedules {
    static readonly type = '[Booking] SetClinicSchedules';
    constructor(public schedules: ClinicScheduleModel[]) { }
  }

  export class SetAppointmentPageState {
    static readonly type = '[Booking] SetAppointmentPageState';
    constructor(public state: AppointmentStateModel | null) { }
  }

  export class ClearClinicSchedules {
    static readonly type = '[Booking] ClearClinicSchedules';
  }

  export class CreateAppointment {
    static readonly type = '[Booking] CreateAppointment';

    constructor(public fullTime: string, public clinicScheduleId: number) { }
  }
  export class UpdateAppointmentStatus {
    static readonly type = '[Booking] UpdateAppointmentStatus';

    constructor(public status: AppointmentStatusType) { }
  }

  export class UpdateBookingStatus {
    static readonly type = '[Booking] UpdateBookingStatus';

    constructor(public status: BookingStatusType) { }
  }

  export class GetBookingStatus {
    static readonly type = '[Booking] GetBookingStatus';
  }
  export class SetBookingStatus {
    static readonly type = '[Booking] SetBookingStatus';

    constructor(public status: BookingStatusType) { }
  }
  export class GetAppointmentTypeTCs {
    static readonly type = '[Booking] GetAppointmentTypeTCs';
  }

  export class SetAgreeTerms {
    static readonly type = '[Booking] SetAgreeTerms';

    constructor(public hasAgreedTermsAndConditions: boolean ) { }
  }

  export class SetAddressInfo {
    static readonly type = '[Booking] SetAddressInfo';

    constructor(public addressInfo: AddressInfo | null) { }
  }

  export class SetSimiliarCustomerID {
    static readonly type = '[Booking] SetSimilarCustomerId';

    constructor(public id: number | null) { }
  }

  export class UpdateBookingAppointmentFormDetails {
    static readonly type = '[Booking] UpdateBookingAppointmentFormDetails';

    constructor(public formValues: any) {}
  }

  export class SetAppointmentSummary {
    static readonly type = '[Booking] SetAppointmentSummary';

    constructor(public summary: AppointmentSummaryModel) {}
  }
}
