import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { AccountService } from 'src/app/shared/providers/services';
import { AccountActions } from 'src/app/shared/store/account/account.actions';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'desktop-nav-bar',
  templateUrl: './desktop-nav-bar.component.html',
  styleUrls: ['./desktop-nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesktopNavBarComponent extends BaseComponent {
  constructor(protected override readonly _injector: Injector, private readonly _accountService: AccountService) {
    super(_injector);
  }

  collapseNavBar(): void {
    this._store.dispatch(AccountActions.ChangeNavBarExpandState);
  }

  navigateTo(to: string): void {
    this._router.navigate([to]);
  }

  onSignOutClick(): void {
    if (this.bookingId) {
      this.onAbandonClick();
      return;
    }

    this._accountService.logout();
  }
}
