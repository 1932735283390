import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProtectedEndpointsConstant } from '../../constants/protected-endpoints.constant';
import { ResponseModel } from '../../models/base/response-model';
import { AppointmentCategoryWithTypes, AppointmentTypeSummary } from '../../models/appointment-type/appointment-types.model';
import { Observable } from 'rxjs';
import { AppointmentTypeLocalAuthority } from '../../models/appointment-type/appointment-type-local-authorities.model';
import { AppointmentTypeDefaultNotes } from '../../models/appointment-type/appointment-type-default-notes.mode';
import { AppointmentTypeTCModel } from '../../models/appointment-type/appointment-type-tc.model';

@Injectable({
  providedIn: 'root'
})
export class AppointmentTypeService {

  constructor(private readonly _http: HttpClient) { }

  getAppointmentTypesByFilter(filter: string, code: string | null, businessAccountId: number | null): Observable<ResponseModel<AppointmentTypeSummary[]>> {
    return this._http.get<ResponseModel<AppointmentTypeSummary[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT_TYPE}?filter=${filter}&code=${code}&businessAccountId=${businessAccountId}`);
  }

  getFavouriteAppointmentTypes(code: string | null, businessAccountId: number | null): Observable<ResponseModel<AppointmentCategoryWithTypes[]>> {
    return this._http.get<ResponseModel<AppointmentCategoryWithTypes[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT_TYPE}/favourite?code=${code}&businessAccountId=${businessAccountId}`);
  }

  getLocalAuthority(): Observable<ResponseModel<AppointmentTypeLocalAuthority[]>> {
    return this._http.get<ResponseModel<AppointmentTypeLocalAuthority[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT_TYPE}/local-authority`);
  }

  getDefaultNotes(id: number, localAuthorityId: number | null): Observable<ResponseModel<AppointmentTypeDefaultNotes>> {
    return this._http.get<ResponseModel<AppointmentTypeDefaultNotes>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT_TYPE}/${id}/default-notes?localAuthorityId=${localAuthorityId}`);
  }

  getTermsAndConditions(id: number, localAuthorityId: number | null): Observable<ResponseModel<AppointmentTypeTCModel[]>> {
    return this._http.get<ResponseModel<AppointmentTypeTCModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT_TYPE}/${id}/terms-conditions?localAuthorityId=${localAuthorityId}`);
  }

  setTermsAndConditions(bookingId: number, hasAgreedTermsAndConditions: boolean): Observable<ResponseModel<void>> {
    return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.APPOINTMENT_TYPE}/terms-conditions/agree`, {bookingId, hasAgreedTermsAndConditions});
  }
}
