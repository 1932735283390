<div class="nav-bar-container">
    <div class="nav-bar-header">
        <img src="/assets/images/nav-bar-logo.svg" alt="nav-logo">
    </div>
    <div *ngIf="(role$ | async)" class="nav-bar-main">
        <span class="nav-bar-list-header">Navigation</span>
        <button mat-button (click)="navigateTo('home')"><mat-icon>home</mat-icon> Home</button>
        <button mat-button (click)="navigateTo('home/existing-customers/customers')"><mat-icon>person</mat-icon> Customers</button>
        <button mat-button *ngIf="!isInRole(getRoleValues.BUSINESS_ACCOUNT_USER)" (click)="navigateTo('admin/business-account/accounts')"><mat-icon>group</mat-icon> Business Accounts</button>
        <button
            *ngIf="isInRoles([getRoleValues.MANAGER, getRoleValues.TEAM_LEADER_SUPERVISOR, getRoleValues.ADMINISTRATIVE_STAFF])"
            mat-button (click)="navigateTo('clinics/clinics-booking-status')"><mat-icon> table_chart </mat-icon>
            Clinics</button>
        
        <button
            *ngIf="isInRoles([getRoleValues.MANAGER, getRoleValues.TEAM_LEADER_SUPERVISOR, getRoleValues.ADMINISTRATIVE_STAFF])"
            mat-button (click)="navigateTo('admin/practitioner/practitioners')"><mat-icon> masks </mat-icon>
            Practitioners</button>
            
        <button mat-button *ngIf="!isInRole(getRoleValues.BUSINESS_ACCOUNT_USER)"
            (click)="navigateTo('admin')"><mat-icon>settings</mat-icon> Admin</button>
    </div>
    <div class="nav-bar-footer">
        <button mat-button (click)="collapseNavBar()"><mat-icon>arrow_back_ios</mat-icon> Collapse sidebar</button>
        <button mat-button (click)="onSignOutClick()"><mat-icon>logout</mat-icon> Logout</button>
    </div>
</div>