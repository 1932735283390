import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { FailedComponent } from './shared/components/failed/failed.component';
import { InsufficientPermissionsComponent } from './shared/components/insufficient-permissions/insufficient-permissions.component';
import { ConfirmDialogComponent } from './shared/components/dialogs/confirm-dialog/confirm-dialog.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'clinics',
    loadChildren: () => import('./clinics/clinics.module').then(m => m.ClinicsModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'login-failed',
    component: FailedComponent
  },
  {
    path: 'insufficient-permissions',
    component: InsufficientPermissionsComponent
  },
  {
    path: '',
    redirectTo: '/home/main',
    pathMatch: 'full'
  },
  {
    path: 'confirm-dialog',
    component: ConfirmDialogComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
