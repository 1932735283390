import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProtectedEndpointsConstant } from '../../constants/protected-endpoints.constant';
import { DropdownResponseBaseDividedNameModel, DropdownResponseBaseModel } from '../../models/base/dropdown-response.model';
import { ResponseModel } from '../../models/base/response-model';
import { PaginationModel } from '../../models/base/pagination.model';
import { PaginationResponse } from '../../models/base/pagination-response.model';
import { PractitionerModel } from '../../models/practitioner/practitioner.model';
import { PractitionerAdminModel } from '../../models/practitioner/practitioner-admin.model';
import { PractitionerEditModel } from '../../models/practitioner/practitioner-edit.model';

@Injectable({
  providedIn: 'root'
})
export class PractitionerService {

  constructor(private readonly _http: HttpClient) { }

  getPractitioners(searchText: string): Observable<DropdownResponseBaseModel[]> {
    return this._http.get<ResponseModel<DropdownResponseBaseDividedNameModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.PRACTITIONER}?name=${searchText}&isActiveOnly=${true}`)
      .pipe(
        map((response: ResponseModel<DropdownResponseBaseDividedNameModel[]>) => response.content
          .map(item => ({ id: item.id, name: `${item.firstName} ${item.lastName}` })))
      );
  }

  get(pagination: PaginationModel, isActive: boolean | null, email: string | null): Observable<ResponseModel<PaginationResponse<PractitionerModel>>> {
    let params = new HttpParams();

    params = params.append("isActive", isActive ?? 'null');

    if (email) {
      params = params.append("email", email);
    }

    if (pagination) {
      params = params.append("pagination.pageNumber", pagination.pageNumber.toString());
      params = params.append("pagination.pageSize", pagination.pageSize.toString());
    }

    return this._http.get<ResponseModel<PaginationResponse<PractitionerModel>>>(`${environment.baseUrl}${ProtectedEndpointsConstant.PRACTITIONER}/admin`, { params: params });
  }

  getLevels(): Observable<ResponseModel<DropdownResponseBaseModel[]>> {
    return this._http.get<ResponseModel<DropdownResponseBaseModel[]>>(`${environment.baseUrl}${ProtectedEndpointsConstant.PRACTITIONER}/practitioner-level`);
  }

  getById(id: number): Observable<ResponseModel<PractitionerAdminModel>> {
    return this._http.get<ResponseModel<PractitionerAdminModel>>(`${environment.baseUrl}${ProtectedEndpointsConstant.PRACTITIONER}/${id}`);
  }

  add(model: PractitionerEditModel): Observable<ResponseModel<void>> {
    return this._http.post<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.PRACTITIONER}`, model);
  }

  update(model: PractitionerEditModel): Observable<ResponseModel<void>> {
    return this._http.put<ResponseModel<void>>(`${environment.baseUrl}${ProtectedEndpointsConstant.PRACTITIONER}/${model.id}`, model);
  }
}
