import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { GenericAdminTableBaseModel } from '../../models/base/generic-admin-table-base.model';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, filter, tap } from 'rxjs';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { CUSTOM_DATE_LINE_FORMATS } from '../../configs/date-picker.config';
import { GenericQuestionBooleanResponseDialogComponent, GenericQuestionBooleanInputDataModel } from '../dialogs/generic-question-boolean-response-dialog/generic-question-boolean-response-dialog.component';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'generic-edit-mat-table',
  styleUrls: ['./generic-edit-mat-table.component.scss'],
  templateUrl: './generic-edit-mat-table.component.html',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_LINE_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericEditMatTableComponent<T extends GenericAdminTableBaseModel> extends BaseComponent implements OnInit {
  @Input() columnsSchemaProps!: any;
  @Input() dataSourceProps!: MatTableDataSource<T>;
  @Input() shouldRenderProps$!: Observable<boolean | null>;
  @Input() shouldShowDelete: boolean = false;

  @Output() editRowEvent = new EventEmitter<T>();
  @Output() deleteRowEvent = new EventEmitter<T>();
  @Output() onAutoCompleteEvent = new EventEmitter<[string | null, string]>();
  @Output() onAutoCompleteCloseEvent = new EventEmitter<string>();

  displayedColumns!: string[];
  valid: any = {}

  constructor(protected override readonly _injector: Injector) {
    super(_injector)
  }


  ngOnInit(): void {
    this.displayedColumns = this.columnsSchemaProps.map((col: any) => col.key);
    this._cdr.detectChanges();
    this.shouldRenderProps$.pipe(
      tap(() => {
        this._cdr.detectChanges();
      })
    )
      .subscribe();
  }

  editRow(row: T): void {
    this.editRowEvent.emit(row);
  }

  onAutocompleteKeyUp(event: KeyboardEvent, key: string): void {
    this.onAutoCompleteEvent.emit([(event.currentTarget as HTMLInputElement).value, key]);
  }
  onClosedAutocompleteMenu(key: string): void {
    this.onAutoCompleteCloseEvent.emit(key);
  }

  onDeleteClick(row: T): void {
    this._dialog.open<GenericQuestionBooleanResponseDialogComponent, GenericQuestionBooleanInputDataModel, boolean>(GenericQuestionBooleanResponseDialogComponent, {
      width: 'calc(100vw /3)',
      panelClass: 'mat-dialog-border-lg-radius',
      autoFocus: false,
      position: {
        top: '100px'
      },
      disableClose: true,
      data: {
        title: 'Warning',
        text: 'Are you sure that you want to delete this row?',
        buttonCancelText: 'Cancel',
        buttonConfirmText: 'Confirm'
      }
    }).afterClosed()
      .pipe(
        filter((response: boolean | undefined) => !!response),
        tap(() => this.deleteRowEvent.emit(row))
      ).subscribe();

  }

  inputHandler(e: any, id: number, key: string): void {
    if (!this.valid[id]) {
      this.valid[id] = {}
    }
    this.valid[id][key] = e.target.validity.valid
  }

  disableSubmit(id: number): boolean {
    if (this.valid[id]) {
      return Object.values(this.valid[id]).some((item) => item === false)
    }
    return false
  }

}
