import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { AccountService } from '../../providers/services';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'insufficient-permissions',
  templateUrl: './insufficient-permissions.component.html',
  styleUrls: ['./insufficient-permissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsufficientPermissionsComponent extends BaseComponent {
  constructor(protected override readonly _injector: Injector, private readonly _accountService: AccountService) {
    super(_injector);
  }
  
  onLogOutClick():void{
    if (this.bookingId) {
      this.onAbandonClick();
      return;
    }

    this._accountService.logout();
  }
}
